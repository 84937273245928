import React from "react";
import "./SmallCircleButton.css"; 

const SmallCircleButton = ({ onClick, label }) => {
  return (
    <button className="small-circle-button" onClick={onClick}>
      {label}
    </button>
  );
};

export default SmallCircleButton;
