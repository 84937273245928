import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../constant/APIConstants";
export const UserData = createAsyncThunk(
  "v1/account",
  async (AuthToken , { rejectWithValue }) => {
    const url = `${API_URL}/account`;
    const data = fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        data["usernameVinArray"] = [];
        localStorage.setItem("ApiKey", data.api_key);
        if (data?.owned_vins) {
          const owned_vin = data.owned_vins;
          const vehicle_accounts = data.vehicle_accounts;
          const UID_map = {};
          Object.keys(owned_vin).forEach((item) => {
            const { uid } = owned_vin[item];
            if (!UID_map[uid]) {
              UID_map[uid] = [item];
            } else {
              UID_map[uid].push(item);
            }
          });
          const UsernameVinHashMap = Object.fromEntries(
            Object.keys(data.vehicle_accounts).map((key) => [key, []])
          );
          Object.keys(vehicle_accounts).forEach((item) => {

            vehicle_accounts[item].uids.forEach((element) => {
              if (element === "ADMIN OVERRIDE") {
                UsernameVinHashMap["ADMIN OVERRIDE"] =
                  UID_map[element] == undefined
                    ? ["No Vin Attached"]
                    : UID_map[element];
              } else {
                UsernameVinHashMap[item] =
                  UID_map[element] == undefined
                    ? ["No Vin Attached"]
                    : UID_map[element];
              }
            });
          });
          const UID_MAP2ARRAY = Object.entries(UsernameVinHashMap).flatMap(
            ([key, values]) =>
              values.map((value) => [key, value !== undefined ? value : null])
          );
          data["usernameVinArray"] = UID_MAP2ARRAY;
        }
        return data;
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      });
    return data;
  }
);
