import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { ApprovePageCallback, ApproveList } from './ApprovePageCallback';
import { useEffect, useState } from 'react';
import {Dialog, DialogContent} from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";

const DEFAULT_DATE = "01/01/01";
const getComparator = (
  order,
  orderBy
) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const descendingComparator = (a, b, orderBy) => {
  const dateA = new Date(a[orderBy]).getTime();
  const dateB = new Date(b[orderBy]).getTime();
  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

const headCells= [
  {
    id: "request_date",
    numeric: false,
    disablePadding: false,
    label: "Request Date",
  },
  {
    id: "api_account_owner",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "usage_description",
    numeric: false,
    disablePadding: false,
    label: "Usage Description",
  },
  {
    id: "approve",
    numeric: false,
    disablePadding: false,
    label: "Approve",
  }
];

const ApprovePage = () => {
  const handleList = async () => {
    var arr = [];
    try {
      const res = await ApproveList();
      if (!res.status) {
        if ('message' in res.response) {
          console.log(`Error: ${res.response.message.capitalize()}`)
          return arr
        } else {
          console.log(`Error: message key not present.`)
          return arr
        }
      } 
      else {
        return res.response.unapproved_accounts
      }
    } catch(error) {
      console.error('Error:', error)
      return arr
    }
  }
  var unapprovedAccounts = [];

  useEffect(() => {
    setShowSpinner(true);
      const getUnapproved = async () => {
        try {
          unapprovedAccounts = await handleList();
            let unapprovedAccountsWithDates = unapprovedAccounts.map((item, index) => {
              if(item.request_date == null) {
                return {
                  ...item,
                  request_date: DEFAULT_DATE
                }
              }
              else {
                return item;
              }
            })
          if (unapprovedAccounts.length > 0) {
            setData(unapprovedAccountsWithDates);
          }
          setShowSpinner(false);
          
        } catch(error) {
            setData([]);
            setShowSpinner(false);
        }
      }
      getUnapproved();
  },[]);

  const [data, setData] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setdialogContent] = useState('')
  const [loading, setLoading] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);



  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleOpen = () => {
    setOpenDialog(true);
  }

  const handleLoading = async (api_account_owner) => {
    try {
      setLoading(api_account_owner);
      const res = await ApprovePageCallback(api_account_owner);
      handleOpen();
      if (!res.status) {
        if ('message' in res.response) {
          setdialogContent(`Error: ${String(res.response.message)[0].toUpperCase() + String(res.response.message).slice(1)}`)
        } else {
          setdialogContent(`Error: Something went wrong`)
        }
        
      } else {
        setdialogContent(`${String(res.response.message)[0].toUpperCase() + String(res.response.message).slice(1)}`);
        let newData = [...data];
        setData(newData.filter((item) => item.api_account_owner !== api_account_owner));
        if (filteredData.length > 0) {
          let newFilteredData = [...filteredData];
          setFilteredData(newFilteredData.filter((item) => item.api_account_owner !== api_account_owner));
        }
      }

    } catch(error) {
      setdialogContent(`Error: Something went wrong`)
      console.error('Error:', error)
    } finally {
      setLoading("");
    }
  };

  const appliedData = [...(isSearching ? filteredData : data)];

  const search = (text) => {
    if (text && text !== "") {
      function compareObjects(o1, o2) {
        var k = "";
        for (k in o1) if (o1[k] !== o2[k]) return false;
        for (k in o2) if (o1[k] !== o2[k]) return false;
        return true;
      }

      function itemExists(haystack, needle) {
        for (var i = 0; i < haystack.length; i++)
          if (compareObjects(haystack[i], needle)) return true;
        return false;
      }

      const objects = data;
      var results = [];

      const keysToSearch = [
        "api_account_owner",
        "first_name",
        "last_name"
      ];
      const toSearch = text.trim();
      for (var i = 0; i < objects.length; i++) {
        for (var key in objects[i]) {
          if (keysToSearch.includes(key)) {
            if (
              objects[i][key] &&
              objects[i][key].toLowerCase().indexOf(toSearch.toLowerCase()) != -1
            ) {
              if (!itemExists(results, objects[i])) {
                results.push(objects[i]);
                break;
              }
            }
          }
        }
      }
      setIsSearching(true);
      setFilteredData(results);
    } else {
      setIsSearching(true);
      setFilteredData(data);
    }
  };

  return (
    <>
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogContent>{dialogContent}</DialogContent>
    </Dialog>
    <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "2rem",
        }}
      >
        {showSpinner ? (
          <CircularProgress />
        ) : (
          <EnhancedTable
            tableRows={appliedData}
            search={search}
            handleLoading={handleLoading}
            loading={loading}
          />
        )}
      </Box>
    </>
  );
};

export default ApprovePage;

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {["request_date"].includes(headCell.id) ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography>{headCell.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { handleSearch } = props;
  return (
    <Toolbar
      sx={{ paddingLeft: "0 !important"}}
      className="table-toolbar"
    >
      <Box>
        <h2>Unapproved Accounts</h2>
        <TextField
          sx={{ paddingBottom: "2rem", marginTop: "1rem", width: "200%"}}
          id="outlined-search"
          label="Search"
          type="search"
          placeholder="Search by email address, first name, or last name"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
    </Toolbar>
  );
}

function EnhancedTable(props) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("request_date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { tableRows, search, handleLoadingItemsProcess, handleLoading, loading } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleLoadingProcess = (api_account_owner) => {
    handleLoading(api_account_owner);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(tableRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, tableRows]
  );

  const handleSearch = (text) => {
    setPage(0);
    search(text);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <EnhancedTableToolbar handleSearch={handleSearch} />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          {tableRows.length > 0 ? (
            <TableBody>
              {visibleRows.map((row, index) => {
                const api_account_owner = row.api_account_owner;
                return (
                  <TableRow
                    tabIndex={-1}
                    key={api_account_owner}
                    id={api_account_owner}
                  >
                    <TableCell scope="row" align="left">
                      {row.request_date === DEFAULT_DATE ? "N/A" : row.request_date}
                    </TableCell>
                    <TableCell align="left">
                      {api_account_owner}
                    </TableCell>
                    <TableCell align="left">
                      {row.first_name}
                    </TableCell>
                    <TableCell align="left">
                      {row.last_name}
                    </TableCell>
                    <TableCell align="left">
                      {row.usage_description}
                    </TableCell>
                    <TableCell align="left">
                      <LoadingButton className="btn btn-primary btn-block" size="small" onClick={() => handleLoadingProcess(api_account_owner)} loading={loading == api_account_owner} disabled={loading == api_account_owner} variant="contained">Approve</LoadingButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <>No unapproved accounts found</>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={tableRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}