import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    DeleteGeofenceCollection,
    BatchPutGeofence,
    BatchDeleteGeofence
} from "./GeofencingAPI";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { Breadcrumbs, Input } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ReactJson from "react-json-view";
import Snackbar from "@mui/material/Snackbar";
import { UserData } from "../../Actions/UsersData";
import { getUserToken, getApiKey } from "../../utils/utility";

interface Data {
    fence: string;
    status: string;
    geometry: string;
    creationTime: string;
    updateTime: string;
}



interface EnhancedTableToolbarProps {
    locationCollectionName: string;
    searchText: (event: any) => void;
    downloadJSON: () => void;
    handleAddFenceOpen: () => void;
    handleFencesDialogOpen: () => void;
}



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}


type Order = "asc" | "desc";


function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {

    return order === "desc"

        ? (a, b) => descendingComparator(a, b, orderBy)

        : (a, b) => -descendingComparator(a, b, orderBy);

}



// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().

// stableSort() brings sort stability to non-modern browsers (notably IE11). If you

// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)

// with exampleArray.slice().sort(exampleComparator)

function stableSort<Data>(array: readonly any[], comparator: (a: any, b: any) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [Data, number]);
    stabilizedThis.sort((a, b) => {

        const order = comparator(a[0], b[0]);

        if (order !== 0) {

            return order;

        }

        return a[1] - b[1];

    });

    return stabilizedThis.map((el) => el[0]);
}



interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}



const headCells: readonly HeadCell[] = [
    {
        id: "fence",
        numeric: false,
        disablePadding: false,
        label: "Fence",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
    {
        id: "geometry",
        numeric: false,
        disablePadding: false,
        label: "Geometry",
    },
    {
        id: "creationTime",
        numeric: false,
        disablePadding: false,
        label: "Creation Time",
    },
    {
        id: "updateTime",
        numeric: false,
        disablePadding: false,
        label: "Update Time",
    },
];


interface accountDetail {
    GeofenceId: string;
    Status: string;
    Geometry: {};
    CreateTime: string;
    UpdateTime: string;
}


interface geofenceCollectionDetail {
    collection_name: string | undefined;
    entries: accountDetail[];
}



interface EnhancedTableProps {
    locationCollectionName: string;
    allFences: { collection_name: string; entries: [] };
    tableRows: Data[];
    handleFenceDeleteProcess: (fence: string) => void;
    searchText: (event: any) => void;
    downloadJSON: () => void;
    handleAddFenceOpen: () => void;
    handleFencesDialogOpen: () => void;
}



interface EnhancedTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}




const GeofenceCollection = () => {
    const [refresh, setRefresh] = useState(1);

    const API_KEY = getApiKey();
    const TOKEN = getUserToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [deleteCollectionDialogOpen, setDeleteCollectionDialogOpen] = useState(false);
    const [deleteCollectionError, setDeleteCollectionError] = useState(null);
    const [addFenceError, setAddFenceError] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [fenceToDelete, setFenceToDelete] = useState<string[]>([""]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [deleteFenceError, setDeleteFenceError] = useState(null);
    const [deleteFenceDialogOpen, setDeleteFenceDialogOpen] = useState(false);
    const [accountDetails, setAccountDetails] = useState<Data[]>([]);
    const [filteredAccountDetails, setFilteredAccountDetails] = useState<Data[]>([]);
    const [locationCollectionName, setLocationCollectionName] = useState("");
    const [fenceDetails, setFenceDetails] = useState<{ collection_name: string; entries: [] }>({
        collection_name: "",
        entries: [],
    });
    const [isSearching, setIsSearching] = useState(false);
    const [value, setValue] = React.useState("1");
    const [viewState, setViewState] = React.useState({ longitude: -100, latitude: 40, zoom: 6 });
    const [geofenceCollections, setGeofenceCollections] = useState([]);
    const [addFenceDialogOpen, setAddFenceDialogOpen] = useState(false);
    const [files, setFiles] = useState<string[]>([""]);
    const [fencesDialogOpen, setFencesDialogOpen] = useState(false);

    const data = useSelector(
        (state: any) => state.UserData
    );


    useEffect(() => {
        setShowSpinner(true);
        setGeofenceCollections(data.UserData.geofence_collections ? data.UserData.geofence_collections : []);
        setShowSpinner(false);
        const locationCollectionName = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        setLocationCollectionName(locationCollectionName as string);

        let collection: any = geofenceCollections.find(
            (i: geofenceCollectionDetail) => i.collection_name === locationCollectionName
        )!;

        if (collection) {
            setFenceDetails(collection);
            const polygonFences = collection?.entries.filter((entry: accountDetail) =>
                entry.Geometry.hasOwnProperty("Polygon")
            );

            let rowsData: Data[] = [];

            rowsData = rowsData.concat(
                collection.entries.map((detail: accountDetail) =>
                    Object({
                        fence: detail["GeofenceId"],
                        status: detail["Status"],
                        geometry: Object.keys(detail["Geometry"])[0],
                        creationTime: detail["CreateTime"],
                        updateTime: detail["UpdateTime"],
                    })
                )
            );

            setAccountDetails(rowsData);
            setShowSpinner(false);
        }

    }, [data, refresh]);


    const handleFencesDialogOpen = () => {

        setFencesDialogOpen(true);

    };


    const handleFencesDialogClose = () => {

        setFencesDialogOpen(false);

    };


    const handleDeleteCollectionDialogOpen = () => {

        setDeleteCollectionError(null);

        setDeleteCollectionDialogOpen(true);

    };


    const handleDeleteCollectionDialogClose = () => {

        setDeleteCollectionError(null);

        setDeleteCollectionDialogOpen(false);

    };


    const handleDeleteFenceDialogOpen = (fence: string) => {

        setDeleteFenceError(null);

        setDeleteFenceDialogOpen(true);

        setFenceToDelete([fence]);

    };


    const handleDeleteFenceDialogClose: any = () => {

        setDeleteFenceError(null);

        setDeleteFenceDialogOpen(false);

        setFenceToDelete([""]);

    };


    const appliedAccountDetails = () => {

        return isSearching ? filteredAccountDetails : accountDetails;

    };


    const searchText = (event: any) => {
        const searchString = event.target.value;
        if (searchString !== "") {
            setIsSearching(true);
        }

        const accountMatchSearch = accountDetails.filter((obj) => {
            return (
                obj.fence.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 ||
                obj.status.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 ||
                obj.geometry.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 ||
                obj.creationTime.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 ||
                obj.updateTime.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
            );
        });
        setFilteredAccountDetails(accountMatchSearch);
    };

    const deleteGeofenceCollection = () => {
        setShowSpinner(true);
        DeleteGeofenceCollection(locationCollectionName, TOKEN, API_KEY)
            .then((resp) => {
                if (resp.data) {
                    navigate('/services/geofencing/geofence-collections')
                }
                else {
                    setShowSpinner(false);
                    setDeleteCollectionError(resp.response?.data);
                }

            })
            .catch((err) => {
                setShowSpinner(false);
                setDeleteCollectionError(err.response?.data);
            });
    };


    const downloadJSON = () => {
        const dataStr =
            "data:application/json;charset=utf-8," + encodeURIComponent(JSON.stringify(fenceDetails));
        const download = document.createElement("a");
        download.setAttribute("href", dataStr);
        download.setAttribute("download", "LocationCollection.json");
        document.body.appendChild(download);
        download.click();
        download.remove();
    };



    const handleAddFenceOpen = () => {
        setAddFenceError(null);
        setAddFenceDialogOpen(true);
    };



    const handleAddFenceDialogClose: any = () => {
        setAddFenceError(null);
        setAddFenceDialogOpen(false);
    };



    const handleFileUpload = (e: any) => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = (e) => {
            setFiles(JSON.parse(e.target?.result! as string));
        };
    };



    const addFence = () => {
        setShowSpinner(true);
        BatchPutGeofence(locationCollectionName, files, TOKEN, API_KEY)
            .then((resp: any) => {
                if (resp.data) {
                    dispatch(UserData(getUserToken()))
                    setTimeout(() => {
                        handleAddFenceDialogClose();
                        setShowSpinner(false);
                        setRefresh(prevState => -1 * prevState)
                    }, 2000)
                }
                else {
                    setShowSpinner(false);
                    setAddFenceError(resp.response?.data);
                    setSnackbarOpen(true);
                    setSnackbarMessage(

                        resp.response.data ? resp.response.data : `There was error while performing this action. Please try again later.`

                    );
                }
            })
            .catch((err) => {
                setShowSpinner(false);
                setAddFenceError(err.response?.data)
                console.log(err);
            });
    };



    const deleteFence = () => {
        setShowSpinner(true);
        BatchDeleteGeofence(locationCollectionName, fenceToDelete, TOKEN, API_KEY)
            .then((resp: any) => {
                if (resp.data) {
                    console.log("success in delete fence", resp)
                    dispatch(UserData(getUserToken()))
                    setTimeout(() => {
                        handleDeleteFenceDialogClose();
                        setShowSpinner(false);
                        setRefresh(prevState => -1 * prevState)
                    }, 2000)
                }
                else {
                    console.log("error in success", resp)
                    setShowSpinner(false);
                    setDeleteFenceError(resp.response?.data);
                    setSnackbarOpen(true);
                    setSnackbarMessage(
                        resp.response.data ? resp.response.data : `There was error while performing this action. Please try again later.`
                    );
                }
            })

            .catch((err) => {
                setShowSpinner(false);
                setDeleteFenceError(err.response?.data)
                console.log(err);
            });
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                onClose={() => handleSnackbarClose()}
                message={snackbarMessage}
                key={"snackbar"}
            />

            <Dialog open={fencesDialogOpen} onClose={handleFencesDialogClose}>
                <DialogTitle>{locationCollectionName}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ReactJson
                            src={fenceDetails}
                            enableClipboard={(e) => navigator.clipboard.writeText(JSON.stringify(e.src))}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleFencesDialogClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteCollectionDialogOpen} onClose={handleDeleteCollectionDialogClose}>
                <DialogTitle>Delete Collection</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {locationCollectionName}?.
                    </DialogContentText>
                    {deleteCollectionError ? (
                        <Typography variant="body1">{deleteCollectionError}</Typography>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCollectionDialogClose}>Cancel</Button>
                    {showSpinner ? (
                        <CircularProgress size="2rem" />
                    ) : (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={deleteGeofenceCollection}
                            disabled={showSpinner}
                        >
                            Delete
                        </Button>
                    )}

                </DialogActions>
            </Dialog>
            <Dialog open={deleteFenceDialogOpen} onClose={handleDeleteFenceDialogClose}>
                <DialogTitle>Delete Fence</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {fenceToDelete[0]} from {locationCollectionName}?
                    </DialogContentText>
                    {deleteFenceError ? <Typography variant="body1">{deleteFenceError}</Typography> : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteFenceDialogClose}>Cancel</Button>
                    {showSpinner ? (
                        <CircularProgress size="2rem" />
                    ) : (
                        <Button variant="contained" color="error" onClick={deleteFence} disabled={showSpinner}>
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={addFenceDialogOpen} onClose={handleAddFenceDialogClose}>
                <DialogTitle>Add Fence (By Uploading)</DialogTitle>
                <DialogContent>
                    <DialogContentText>Upload geojson file</DialogContentText>
                    <Input type="file" onChange={(e) => handleFileUpload(e)} />
                    {addFenceError ? <Typography variant="body1">{addFenceError}</Typography> : null}
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleAddFenceDialogClose} disabled={showSpinner}>
                        Cancel
                    </Button>

                    {showSpinner ? (
                        <CircularProgress size="2rem" />
                    ) : (

                        <Button className="btn btn-primary" variant="contained" onClick={addFence} disabled={showSpinner}>
                            Submit
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: "1.5rem",
                    marginLeft: "1.5rem",
                }}
            >
                <Box sx={{ display: "flex", paddingTop: "1rem" }}>
                    <Breadcrumbs sx={{ fontSize: "2.125rem" }} aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            color="inherit"
                            onClick={() => navigate(`/services/geofencing/geofence-collections`)}
                        >
                            Location Collections
                        </Link>

                        <Typography variant="h4" color="text.primary">
                            {locationCollectionName}
                        </Typography>
                    </Breadcrumbs>
                </Box>

                <Button sx={{ maxWidth: 'fit-content' }} variant="contained" color="error" onClick={handleDeleteCollectionDialogOpen}>
                    Delete Collection
                </Button>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2rem",
                }}
            >
                <Paper
                    sx={{ width: "90%", paddingTop: "1rem", paddingBottom: "1rem", paddingLeft: "2rem" }}
                    elevation={5}
                >
                    {showSpinner ? (
                        <CircularProgress />
                    ) : (
                        <EnhancedTable
                            locationCollectionName={locationCollectionName}
                            allFences={fenceDetails}
                            tableRows={appliedAccountDetails()}
                            handleFenceDeleteProcess={(fence: string) => handleDeleteFenceDialogOpen(fence)}
                            searchText={searchText}
                            downloadJSON={downloadJSON}
                            handleAddFenceOpen={handleAddFenceOpen}
                            handleFencesDialogOpen={handleFencesDialogOpen}
                        />
                    )}
                </Paper>
            </Box>
        </>
    );
};




function EnhancedTableHead(props: EnhancedTableHeadProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {["fence", "status", "geometry", "creationTime", "updateTime"].includes(headCell.id) ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <Typography>{headCell.label}</Typography>
                        )}
                    </TableCell>
                ))}
                <TableCell key="delete" align="left" padding="normal">
                    Delete
                </TableCell>
            </TableRow>
        </TableHead>
    );

}



function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const {
        locationCollectionName,
        searchText,
        downloadJSON,
        handleAddFenceOpen,
        handleFencesDialogOpen,
    } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Box sx={{ width: "100%" }}>
                <h2>Fences</h2>
                <Typography variant="body1" sx={{ paddingBottom: "1rem" }}>
                    Fences within{" "}
                    <Link underline="none" onClick={handleFencesDialogOpen} sx={{ cursor: "pointer", color: "#1976d2 !important" }}>
                        {locationCollectionName}
                    </Link>
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "1.5rem",
                        marginBottom: "1rem",
                    }}
                >

                    <TextField
                        sx={{
                            paddingBottom: 0,
                            marginTop: "1rem",
                            display: "flex",
                            width: "40%",
                            maxWidth: "400px",
                        }}

                        id="outlined-search"
                        label="Search"
                        type="search"
                        placeholder="Fence, Status, etc."
                        onChange={searchText}
                    />

                    <Box sx={{ display: "flex" }}>
                        <DownloadIcon
                            sx={{ alignSelf: "center", marginRight: "1rem" }}
                            onClick={downloadJSON}
                        />
                        <Button variant="contained" onClick={handleAddFenceOpen} className="btn btn-primary">
                            Add Fence (By Uploading)
                        </Button>
                    </Box>
                </Box>
            </Box>

        </Toolbar>

    );

}



function EnhancedTable(props: EnhancedTableProps) {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("fence");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {
        locationCollectionName,
        allFences,
        handleFenceDeleteProcess,
        tableRows,
        searchText,
        downloadJSON,
        handleAddFenceOpen,
        handleFencesDialogOpen,
    } = props;

    const [fenceDialogOpen, setFenceDialogOpen] = useState(false);
    const [fenceDialogTitle, setFenceDialogTitle] = useState("");

    const handleFenceDialogOpen = (fence: string) => {
        setFenceDialogOpen(true);
        setFenceDialogTitle(fence);
    };

    const handleFenceDialogClose = () => {
        setFenceDialogOpen(false);
    };

    const handleFenceDelete = (fence: string) => {
        handleFenceDeleteProcess(fence);
    };


    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };



    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };



    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableRows.length) : 0;


    const visibleRows: any[] = React.useMemo(
        () =>
            stableSort(tableRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [order, orderBy, page, rowsPerPage, tableRows]
    );



    return (
        <Box sx={{ width: "100%" }}>
            <Dialog open={fenceDialogOpen} onClose={handleFenceDialogClose}>
                <DialogTitle>{fenceDialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ReactJson
                            src={allFences.entries.find((i: accountDetail) => i.GeofenceId === fenceDialogTitle)!}
                            enableClipboard={(e) => navigator.clipboard.writeText(JSON.stringify(e.src))}
                        />
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={handleFenceDialogClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <EnhancedTableToolbar
                locationCollectionName={locationCollectionName}
                searchText={searchText}
                downloadJSON={downloadJSON}
                handleAddFenceOpen={handleAddFenceOpen}
                handleFencesDialogOpen={handleFencesDialogOpen}
            />
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    {tableRows.length > 0 ? (
                        <TableBody>
                            {visibleRows.map((row: Data, index) => {
                                return (
                                    <TableRow
                                        hover={row.fence ? true : false}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.fence}
                                    >
                                        <TableCell align="left">
                                            {" "}
                                            <Link
                                                onClick={() => {
                                                    handleFenceDialogOpen(row.fence);
                                                }}
                                                sx={{ cursor: "pointer", color: "#1976d2 !important" }}
                                            >
                                                {row.fence}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="left">{row.status}</TableCell>
                                        <TableCell align="left">{row.geometry}</TableCell>
                                        <TableCell align="left">{row.creationTime}</TableCell>
                                        <TableCell align="left">{row.updateTime}</TableCell>
                                        <TableCell align="left">
                                            {
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        handleFenceDelete(row.fence);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={headCells.length} />
                                </TableRow>
                            )}
                        </TableBody>
                    ) : (
                        <Typography component={"p"}>There are no fences available.</Typography>
                    )}
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={tableRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

export default GeofenceCollection;