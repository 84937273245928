import React from 'react'
import { useState, useEffect } from 'react';
import BrandCard from '../../components/BrandCards/BrandCard';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import SwaggerComp from '../../components/swagger/SwaggerComp';


import './style.css'
import SwaggerComp from '../../components/swagger/SwaggerComp';
import { Link } from 'react-router-dom';

const Remote_ops = () => {
    const theme = useTheme();

  const [value, setValue] = useState('1')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])


  return (
    <>
      <div>
        <div className="banner-remote-ops">
          <img
            src="./images/banner2.png"
            className="img-fluid"
            alt="Banner Image"
          />
          <div className="align-items-center d-flex text-white">
            <h1>Remote Operations</h1>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container-fluid feature-content">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    allowScrollButtonsMobile
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    textColor="#A2202C"
                    indicatorColor="#A2202C"
                    TabIndicatorProps={{
                      style: {
                        background: "#A2202C",
                        fontSize: "27px",
                        color: "#A2202C",
                        height: "10px",
                        bottom: 0,
                      },
                    }}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="About Remote Operations  "
                      value="1"
                    />
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="API Specification"
                      value="2"
                    />
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="Documentation"
                      value="3"
                    />
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  sx={{
                    padding: 0,
                  }}
                >
                  <br />
                  <br />

                  <div className="row firstContainer">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-sm-9">
                          <h2>Map generation of the trips</h2>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. electronic typesetting, remaining
                            essentially unchanged.{" "}
                          </p>
                        </div>
                        <div class="col-sm-3">
                          <img
                            src="./images/map-color.png"
                            className="img-fluid float-right discription-image"
                            alt="Image in Div 3"
                          />
                        </div>
                      </div>
                      <br />
                      <br />

                      <div class="row">
                        <div class="col-sm-5 d-flex align-items-center">
                          <img
                            src="./images/trip-report-data.png"
                            className="img-fluid float-left discription-image"
                            alt="Image in Div 3"
                          />
                        </div>
                        <div class="col-sm-7">
                          <h2>Map generation of the trips</h2>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. electronic typesetting, remaining
                            essentially unchanged.{" "}
                          </p>
                        </div>
                      </div>
                      <br />
                      <br />

                      <div class="row d-flex align-items-center">
                        <div class="col-sm-7">
                          <h2>Map generation of the trips</h2>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. electronic typesetting, remaining
                            essentially unchanged.{" "}
                          </p>
                        </div>
                        <br />
                        <br />

                        <div class="col-sm-5">
                          <img
                            src="./images/economy-trip.png"
                            className="img-fluid float-right discription-image"
                            alt="Image in Div 3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  value="2"
                  sx={{
                    padding: 0,
                  }}
                >
                  <br />
                  <div className="row pt-5 api-spec-container">
                    <div class="container-fluid">
                      <div class="row">
                        <h1>API</h1>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                      </div>
                      <br />

                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        {" "}
                        <SwaggerComp tag={"Remotes"} />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  value="3"
                  sx={{
                    padding: 0,
                  }}
                >
                  <div className="row pt-5 api-spec-container">
                    <div class="container-fluid">
                      <div class="row">
                        <h1>How does it works?</h1>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                      </div>
                      <br />
                      <div className="text-center">
                        {" "}
                        <img
                          className="text-center"
                          alt="Image"
                          src="./images/trip-function.png"
                        />
                      </div>
                      <br />
                      <div class="row pt-5">
                        <h1 className="pb-3">More Documentation</h1>
                        <hr size="3" />
                        <br />
                        <p>
                          Here you can download more file related to this
                          functionality
                        </p>
                        <div className="p-5">
                          <div class="row">
                            <div class="col-6">
                              <p>
                                <u>Document 1 architecture </u>
                              </p>
                            </div>

                            <div class="col-6 text-right">
                              <a href="#">
                                <i className="fa fa-download"></i>
                              </a>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <p>
                                <u>Document 2 unit of measure used</u>
                              </p>
                            </div>

                            <div class="col-6 text-right">
                              <a href="#">
                                <i className="fa fa-download"></i>
                              </a>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <p>
                                <u>Document 3</u>
                              </p>
                            </div>

                            <div class="col-6 text-right">
                              <a href="#">
                                <i className="fa fa-download"></i>
                              </a>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <p>
                                <u> Document 4 UI/UX</u>
                              </p>
                            </div>

                            <div class="col-6 text-right">
                              <a href="#">
                                <i className="fa fa-download"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <div className="row p-5 try-all-api-section">
                  {" "}
                  <Link
               className="btn btn-block"
               to='/plans'
             >
              Try All Api's
             </Link>
                  {/* <button className="btn btn-block "></button> */}
                </div>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default Remote_ops