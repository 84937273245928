export const REMOTE_COMMANDS = `
<h2><strong>Remote Operations</strong></h2>
<p><strong><img src="/assets/mobilisight/remotecommands.png" alt="" /></strong></p>
<h3><strong>High-Level Process Flow</strong></h3>
<ol>
<li>The Operator inputs a Remote Command / Activation Request. Whatever the touchpoint, it shall be processed by FCL CustomLayer API Gateway</li>
<li>FCL Custom Layer calls the corresponding API, exposed by the Service Platform (Targa SP for MC, Ignite for TBM)</li>
<li>The Service Platform synchronously responds about the command reception, then in case of success, it sends the command to the specified VIN&rsquo;s onboard Black Box</li>
<li>Black Box processes the command and sends it to BCM - Body Control Monitor, to have it executed</li>
<li>BCM grants its execution feedback to Black Box</li>
<li>Black Box sends the operation result to the Service Platform</li>
<li>The Service Platform asynchronously invokes the Callback API exposed by FCL CustomLayer</li>
<li>If the first input touchpoint implies it (ie. Mobile Applications), a result notification is sent</li>
</ol>
<p>&nbsp;</p>
<p><strong><em><u>IMPORTANT! Please ALWAYS check the Callback event payloads, and ALWAYS wait for their reception BEFORE inputting any other Remote Command!</u></em></strong></p>
`