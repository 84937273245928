import React from 'react'
import Banner from '../../components/Banner/Banner'
import { useForm } from 'react-hook-form';
import "./style.css"
import RequestApiCredentials from '../RequestApiCredentials/RequestApiCredentials';
import { contactUsApi } from './contactusapi';
const ContactUS = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    return (
        <>
            <div>
                <Banner image={"/images/contact_us_and_faq_pages_banner.png"} heading={"Contact us"} />


            </div>
            
            <div className='container-fluid contactUs' style={{paddingLeft:"8%", paddingRight:"8%", paddingTop:"1%"}}>

                <h2>Contact Form</h2>

                <p>Fill the form with your ideas of project to start a partnership with Stellantis vehicles data</p>

                <form onSubmit={handleSubmit(contactUsApi)}>
                    <div class="row m-0">
                        <div class="form-group col-6">
                            <input
                                type="text"
                                required
                                className="form-control roundInput"
                                id="First"
                                placeholder="First Name"
                                {...register("First", {
                                    required: "First name is required",
                                })}
                            />
                            {errors?.First && (
                                <p>{errors.First.message}</p>
                            )}
                        </div>
                        <div class="form-group col-6">
                            <input
                                type="text"
                                required
                                className="form-control roundInput"
                                id="Last"
                                placeholder="Last Name"
                                {...register("Last", {
                                    required: "Last name is required",
                                })}
                            />
                            {errors?.Last && (
                                <p>{errors.Last.message}</p>
                            )}
                        </div>
                        {/* <div class="form-group col-6">
                <label for="password">
                  API Account Password <span class="required-star">*</span>
                </label>
                <input
                  required
                  type="password"
                  className="form-control small-input"
                  id="password"
                  placeholder="Enter a Password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                />
                {errors?.password && <p>{errors.password.message}</p>}
              </div> */}
                    </div>
                    <div class="row m-0">
                        <div class="form-group col-6">
                            <input
                                type="number"
                                className="form-control roundInput"
                                id="Phone"
                                placeholder="Phone"
                                {...register("Phone")}
                            />
                        </div>
                        <div class="form-group col-6">
                            <input
                                type="email"
                                required
                                className="form-control roundInput"
                                id="email"
                                placeholder="Email"
                                {...register("Email", {
                                    required: "Email is required",
                                })}
                            />
                            {errors?.Last && (
                                <p>{errors.Last.message}</p>
                            )}
                        </div>

                    </div>
                    <div className="row m-0">
                        <div class="form-group col">
                            <input
                                type="Company"
                                className="form-control roundInput"
                                id="Company"
                                placeholder="Company"
                                {...register("Company")}
                            />
                        </div>
                    </div>
                    <div className="row m-0">
                        <div class="form-group col">
                            <input
                                type="text"
                                className="form-control roundInput"
                                id="Region"
                                placeholder="Region"
                                {...register("Region")}
                            />
                        </div>
                    </div>
                    <div className="row m-0">
                        <div class="form-group col">
                            <input
                                type="text"
                                className="form-control roundInput"
                                id="Title"
                                placeholder="Title"
                                {...register("Title")}
                            />
                            
                        </div>
                    </div>


                    <div class="row m-0">
                        <div
                            class="form-group col"
                            style={{ "margin-bottom": "1rem" }}
                        >
                            <textarea
                                type="text"
                                required
                                className="form-control roundDescription"
                                id="Description"
                                placeholder="Enter Usage Description"
                                {...register("Description", {
                                    required: "Description is required",
                                })}
                            ></textarea>
                            {errors?.Description && (
                                <p>{errors.Description.message}</p>
                            )}
                        </div>
                    </div>
                    <div style={{"display": "flex", "justify-content": "center"
                    }}>
                    <button
                        id="submit-button"
                        type="submit"
                        class="btn btn-primary mt-2"
                    >
                        Submit
                    </button>
                    </div>
                </form>

            </div>

        </>
    )
}

export default ContactUS
