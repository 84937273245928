import React,{useState} from 'react'
import { LastKnownDataAPI } from './LastKnownDataAPI'
import { Loader } from '../../components/Loader/Loader'
import DownloadJsonObject from '../../utils/DownloadJsonObject';
import { JSONTree } from 'react-json-tree';

const LastKnownDataVIN = () => {
    const [VIN, setVIN] = useState("")
  const [LastKnowData, setLastKnowData] = useState(null)



 
    
  const LastKnowCallback = async (event) => {
      
    event.preventDefault();
    const data = await LastKnownDataAPI(VIN);
    await setLastKnowData(data)
    }

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
            <h2 style={{ color: "rgb(50, 50, 159);" }}>
              LastKnown Sensor Values
            </h2>
            <h5>Retrieves last known data sensor values for a vehicle</h5>
            <form onSubmit={LastKnowCallback}>
              <div class="form-group">
                <label for="vin">
                  VIN Number<span class="required-star">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  value={VIN}
                  id="vin"
                  onChange={(e) => setVIN(e.target.value)}
                  placeholder="Enter Vehicle Identification Number"
                />
              </div>

              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
            {LastKnowData ? (
              <button
                class="btn btn-warning btn-block"
                onClick={(e) => DownloadJsonObject(LastKnowData, `${VIN}_Last_Known_Data`)}
              >
                Download Data
              </button>
            ) : (
              <h3>No Data Found</h3>
            )}
          </div>
        </div>
      </div>
    </>
   
  );
}

export default LastKnownDataVIN