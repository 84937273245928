export const REAL_TIME_DATA_FEED_CALLBACK_API = `
<h1><strong><span>OUTBOUND Kinesis Stream Details</span></strong></h1>
<h2>Enrollment / Unenrollment Callback</h2>
<br>
<br>
<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th class="empty-cell"></th>
            <th class="bright-blue">USE CASE</th>
            <th class="bright-blue"></th>
        </tr>
        <tr class="dark-grey-row">
            <td>Trigger</td>
            <td>New Couple</td>
            <td>Existing Couple</td>
        </tr>
        <tr>
            <td>Enrollment</td>
            <td>SUCCESS</td>
            <td>
                <span>If the last saved expirationTimestamp is equal to the new one inputted, FAILURE</span>
                <br>
                <br>
                <span>Otherwise, SUCCESS + “the Enrollment Expiration Date has been updated”</span>
            </td>
        </tr>
        <tr class="light-grey-row">
            <td>Unenrollment</td>
            <td>
                FAILURE
            </td>
            <td>
                <span>SUCCESS</span>
            </td>
        </tr>
    </table>
</div>
`