import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
import { getUserToken, getApiKey } from "../../utils/utility";
import { checkUserToken } from "../../utils/CheckUserToken";

export const ResetAccountApiPassword = (data, navigate) => {
  const endpoint = API_URL + "/account/resetpassword";
 const loadingSwal = Swal.fire({
   title: "Loading...",
   text: "Linking Account",
   allowOutsideClick: false,
   showConfirmButton: false,
   backdrop: "rgba(0, 0, 0, 0.85)",
   willOpen: () => {
     Swal.showLoading();
   },
   customClass: {
     title: "sweetalert-color",
     content: "sweetalert-color",
   },
 });
  const data1 = fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      "x-api-key": getApiKey(),
    },
    body: JSON.stringify({
      api_account_owner: data.Email,
    }),
  })
    .then((response) => {
      
      if (!response.ok) {
        return response.json().then((errorData) => {
         throw (errorData.message);
       });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();

      Swal.fire({
        text: data?.result
          ? data.result
          : "" + data?.message
          ? data.message
          : "",
        icon: "success",
        backdrop: "rgba(0, 0, 0, 0.85)",
        customClass: {
          title: "sweetalert-color", // Apply custom styles to the title
          content: "sweetalert-color", // Apply custom styles to the content (text)
        },
      }); 
      if(checkUserToken){
        navigate("/login")
      }
      return data;
    })
    .catch((error) => {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
      console.error("Error fetching data:", error);
      return error;
    });

  return data1;
};
