import { API_URL } from "../../constant/APIConstants";
import { getUserToken, getApiKey } from '../../utils/utility';


export const ApprovePageCallback = async (payload) => {
  const endpoint = API_URL + "/account/admin/approve";
  const res = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", 
      "Authorization": `Bearer ${getUserToken()}`,
      "x-api-key": getApiKey(),
    },
    body: JSON.stringify({
        api_account_owner: payload,
      })
    
  }).then((res) => {
    if (!res.ok){
      return res.json().then((errorData) => {
        return {"status":0, response:errorData}
      })
    }
    return res.json()
    }).then((data) => {
      return {"status":1, response:data}
    }).catch((error) => {
      console.log("Error:",error)
      return {status:0, response:{"message":error}};
  });
  var resData = await res;
  return resData
};

export const ApproveList = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${getUserToken()}`);
  myHeaders.append("x-api-key", getApiKey());
  myHeaders.append("Content-Type", "application/json");
var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const res = await fetch(API_URL +"/account/admin/unapprovedaccounts", requestOptions).catch((error) => {
  return {status:0, response:{"message":error}};
});
var resData = await res.json();
if (!res.ok) {
  return {status:0, response:resData}
} 
return {status:1, response:resData};


};
