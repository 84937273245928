import React from 'react'
import Banner from '../../components/Banner/Banner'

const PageNotFound = () => {
  return (
    <div className="container-fluid" style={{minHeight:"80vh"}}>
    
    <div>
    <Banner image={"/images/onboardBanner.png"} heading={'pageNotFound'} />


</div>
<div style={{paddingLeft:"8%"}}><h1>Page Not Found</h1></div>

</div>
  )
}

export default PageNotFound