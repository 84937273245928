import React,{useState} from "react";
import { RecentSensorValueAPI } from "./RecentSensorValueAPI";
import DownloadJsonObject from "../../utils/DownloadJsonObject";

const RecentSensorDataValues = () => {
  const [VIN, setVIN] = useState("");
  const [RecentSensorValue, setRecentSensorValue] = useState(null);
 const theme = {
   scheme: "monokai", // You can choose different color schemes
   author: "wimer hazenberg (http://www.monokai.nl/)",
   base00: "#FFFFFF", // Background color
   base01: "#808080", // Tree lines color
   base02: "#808080", // Collapsed tree items color
   base03: "#000000", // Collapsed tree items text color
   base04: "#000000", // Arrow color
   base05: "#000000", // Key color
   base06: "#000000", // String color
   base07: "#000000", // Value color
   base08: "#808080", // Null, undefined, NaN color
   base09: "#808080", // Boolean color
   base0A: "#808080", // Numbers color
   base0B: "#a6e22e",
   base0C: "#a1efe4",
   base0D: "#66d9ef",
   base0E: "#ae81ff",
   base0F: "#cc6633",
 };
  const GetRecentSensorValues = async (e) => {
    e.preventDefault()
    const data = await RecentSensorValueAPI(VIN);
    await setRecentSensorValue(data)
   
  }
  
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
            <h2 style={{ color: "rgb(50, 50, 159);" }}>
              Retrieves Sensor Values
            </h2>
            <h5>Retrieves Sensor Values</h5>
            <form onSubmit={GetRecentSensorValues}>
              <div class="form-group">
                <label for="vin">
                  VIN Number<span class="required-star">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  value={VIN}
                  id="vin"
                  onChange={(e) => setVIN(e.target.value)}
                  placeholder="Enter Vehicle Identification Number"
                />
              </div>

              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
            {RecentSensorValue ? (
              <button
                class="btn btn-warning btn-block"
                onClick={(e) => DownloadJsonObject(RecentSensorValue, `${VIN}_Recent_sensor_data`)}
              >
                Download Data
              </button>
            ) : (
              <h3>No Data Found</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentSensorDataValues;
