export const ENV =  process.env.REACT_APP_ENV
export const API_URL = process.env.REACT_APP_API_URL
export const CAPTCHA_TOKEN = process.env.REACT_APP_CAPTCHA_TOKEN
export const CAPTCHA_URL = process.env.REACT_APP_CAPTCHA_URL
export const WEB_URL = process.env.REACT_APP_WEB_URL
export const ADMIN_EMAIL =  process.env.REACT_APP_ADMIN_EMAIL
export const NA_URL =  process.env.REACT_APP_NA_URL
export const EU_URL =  process.env.REACT_APP_EU_URL




