export const swaggerspec = {
  "openapi": "3.0.0",
  "info": {
    "title": "Stellantis Connected Vehicle API",
    "description": "Build Applications with Stellantis North America connected Vehicles.\n\nFor former PSA groupe vehicles, please visit https://developer.groupe-psa.io/\n\nTo view these docs SwaggerUI- https://developers.stellantis.com/apidocs.html\n\nTo view these docs ReDoc- https://developers.stellantis.com/docs.html \n\nSign up for the Stellantis 2022 Hackathon- https://developers.stellantis.com\n\nFind code samples -https://github.com/StellantisSWx/Stellantis-Hackathon\n\n",
    "contact": {
      "email": "fca-cloud-api@developers.stellantis.com"
    },
    "license": {
      "name": "Apache 2.0",
      "url": "http://www.apache.org/licenses/LICENSE-2.0.html"
    },
    "version": "1.0.8"
  },
  "servers": [
    {
      "url": "https://api.stellantis-developers.com",
      "description": "Stellantis Connected Vehicle API"
    }
  ],
  "paths": {
    "/v1/account": {
      "get": {
        "tags": ["Account"],
        "summary": "Get details of account, showing all linked accounts",
        "description": "Get details of account, showing all linked accounts",
        "operationId": "getAccount",
        "responses": {
          "200": {
            "description": "Account Details",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/GetAccountResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/account/register": {
      "post": {
        "tags": ["Account"],
        "summary": "Request API Credentials",
        "description": "Request API Account Credentials for Stellantis Cloud API \n",
        "operationId": "account/register",
        "requestBody": {
          "description": "Register",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/Register"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "Credential Request Received",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/RegisterResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          }
        }
      }
    },
    "/v1/account/link": {
      "post": {
        "tags": ["Account"],
        "summary": "Link an API account to you Connected Vehicle account",
        "description": "Associate a Stellantis API Account to an existing Jeep, Maserati, Ram, Chrysler, Dodge Connect, Sirius XM Guardian, or Mopar.com account. One API account can be linked to many connected vehicle accounts. Connected vehicle account credentials must be valid and cannot be managed with this API. Your connected vehicle account must be enrolled and have eligible VINs. If subscriptions on an existing VIN are added or removed to a connected vehicle account, call this API to refresh the linked account",
        "operationId": "Link",
        "requestBody": {
          "description": "AccountLink",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountLink"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "AccountLinkResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountLinkResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/account/unlink": {
      "post": {
        "tags": ["Account"],
        "summary": "Unlink / Remove Connected Vehicle account from API account",
        "description": "This API reverses the action of /link",
        "operationId": "unlink",
        "requestBody": {
          "description": "Unlink",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/Unlink"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "UnlinkResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UnlinkResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/account/setpassword": {
      "post": {
        "tags": ["Account"],
        "summary": "Update API account password",
        "description": "Update API account password - this API must be called to set a permanent password after registration or a forgotten password reset using /resetpassword",
        "operationId": "setPassword",
        "requestBody": {
          "description": "SetPassword",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SetPassword"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "SetPasswordResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SetPasswordResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": []
          }
        ]
      }
    },
    "/v1/account/resetpassword": {
      "post": {
        "tags": ["Account"],
        "summary": "Reset password via email",
        "description": "Reset account password/forgotten password. This API will send a temporary to account email. Note, you must use /setpassword to set a permanent password after using this API",
        "operationId": "resetpassword",
        "requestBody": {
          "description": "ResetPassword",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ResetPassword"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "ResetPasswordResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ResetPasswordResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": []
          }
        ]
      }
    },
    "/v1/account/delete": {
      "post": {
        "tags": ["Account"],
        "summary": "Permanently Delete API account",
        "description": "This API permanently deletes the API acount. Note that this request cannot be undone. To create a new account use /v1/account/register",
        "operationId": "accountdelete",
        "responses": {
          "200": {
            "description": "AccountDeleteResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountDeleteResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/auth/token": {
      "post": {
        "tags": ["Authorization"],
        "summary": "retrieve a bearer token with API credentials",
        "description": "retrieve a bearer token with API credentials\n",
        "operationId": "token",
        "parameters": [
          {
            "name": "user",
            "in": "header",
            "description": "user name provided in api credentials",
            "required": false,
            "style": "simple",
            "explode": false,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "password",
            "in": "header",
            "description": "password provided in api credentials",
            "required": false,
            "style": "simple",
            "explode": false,
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "Bearer Token",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthTokenResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "Invalid username or password"
          }
        }
      }
    },
    "/v1/{vin}/remote": {
      "post": {
        "tags": ["Remote Commands"],
        "summary": "sends remote commands to vehicle",
        "description": "Remote commands for vehicle. Available commands are start, remote start cancel, unlock, lock, or horns & lights\n",
        "operationId": "remote",
        "parameters": [
          {
            "name": "vin",
            "in": "path",
            "description": "Vehicle Identification Number",
            "required": true,
            "style": "simple",
            "explode": false,
            "schema": {
              "type": "string"
            }
          }
        ],
        "requestBody": {
          "description": "Command",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/RemoteCommand"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "Command successfully sent to service delivery platform",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/RemoteCommandResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/{vin}/data/": {
      "get": {
        "tags": ["Vehicle Data"],
        "summary": "retrieves array of recent data sensor values",
        "description": "Gets vehicle data as an array. Timestamps are based on time collected on vehicle. Sensor array provided for up to last 8 hrs.",
        "operationId": "data",
        "parameters": [
          {
            "name": "vin",
            "in": "path",
            "description": "Vehicle Identification Number",
            "required": true,
            "style": "simple",
            "explode": false,
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "data successfully retrieved",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DataResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/{vin}/data/lastknown": {
      "get": {
        "tags": ["Vehicle Data"],
        "summary": "retrieves last known data sensor values for a vehicle",
        "description": "Gets vehicle data for a provided sensor. Provides most recent sensor value. For a list of sensors please visit https://url.com",
        "operationId": "data/lastknown",
        "parameters": [
          {
            "name": "vin",
            "in": "path",
            "description": "Vehicle Identification Number",
            "required": true,
            "style": "simple",
            "explode": false,
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "data successfully retrieved",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DataLastKnownResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        },
        "security": [
          {
            "ApiKeyAuth": [],
            "BearerAuth": []
          }
        ]
      }
    },
    "/v1/feedback": {
      "post": {
        "tags": ["Feedback"],
        "summary": "Submit general feedback, questions, comments to Stellantis.",
        "description": "Submit general feedback, questions, comments to Stellantis. For bug report, use /bugs",
        "operationId": "feedback",
        "requestBody": {
          "description": "Feedback",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/Feedback"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "FeedbackResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FeedbackResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        }
      }
    },
    "/v1/bugs": {
      "post": {
        "tags": ["Feedback"],
        "summary": "Submit Bug Report",
        "description": "Submit Bug Report. For General feedback or questions, use /feedback instead",
        "operationId": "bugs",
        "requestBody": {
          "description": "Bugs",
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/Bugs"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "BugsResponse",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/BugsResponse"
                }
              }
            }
          },
          "400": {
            "description": "bad input parameter"
          },
          "403": {
            "description": "not authorized"
          }
        }
      }
    }
  },
  "components": {
    "schemas": {
      "AuthTokenResponse": {
        "type": "object",
        "properties": {
          "access_token": {
            "type": "string",
            "example": "eyJraWQiOiJyc2ExIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJhY2JjY2Q..."
          },
          "token_type": {
            "type": "string",
            "example": "bearer"
          },
          "expires_in": {
            "type": "integer",
            "example": 3600
          }
        }
      },
      "RemoteCommand": {
        "required": ["command", "pin"],
        "type": "object",
        "properties": {
          "command": {
            "type": "string",
            "example": "START"
          },
          "pin": {
            "type": "integer",
            "example": 1234
          }
        }
      },
      "RemoteCommandResponse": {
        "type": "object",
        "properties": {
          "vin": {
            "type": "string",
            "example": "3D7UT2CL0BG625027"
          },
          "command": {
            "type": "string",
            "example": "START"
          },
          "result": {
            "type": "string",
            "example": "COMMAND SENT"
          }
        }
      },
      "DataResponse": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/DataResponseinner"
        }
      },
      "DataLastKnownResponse": {
        "type": "object",
        "properties": {
          "vin": {
            "type": "string",
            "example": "3D7UT2CL0BG625027"
          },
          "data": {
            "type": "object",
            "example": "{\"vin\": \"3D7UT2CL0BG625027\", \"value\": \"12532\", \"signal\": \"odometer\", \"timestamp\": \"2020-02-22 19:53:41.071\"}"
          }
        }
      },
      "GetAccountResponse": {
        "type": "array",
        "items": {
          "$ref": "#/components/schemas/GetAccountResponseinner"
        }
      },
      "Register": {
        "required": [
          "address",
          "password",
          "api_account_owner",
          "first_name",
          "last_name",
          "phone_number",
          "usage_description"
        ],
        "type": "object",
        "properties": {
          "api_account_owner": {
            "type": "string",
            "example": "your_email@domain.com"
          },
          "password": {
            "type": "string",
            "example": "Your_password!1"
          },
          "first_name": {
            "type": "string",
            "example": "First"
          },
          "last_name": {
            "type": "string",
            "example": "Last"
          },
          "phone_number": {
            "type": "string",
            "example": "+12484250000"
          },
          "address": {
            "type": "string",
            "example": "1000 Chrysler Drive, Auburn Hills, MI, 48306"
          },
          "usage_description": {
            "type": "string",
            "example": "Briefly describe your use case, purpose for requesting access to the API"
          }
        }
      },
      "RegisterResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Your account request has been received. You will receive a response via email to the address specified."
          }
        }
      },
      "AccountLink": {
        "required": ["connected_account_email", "connected_account_password"],
        "type": "object",
        "properties": {
          "connected_account_email": {
            "type": "string",
            "example": "your_connected_account_email@domain.com"
          },
          "connected_account_password": {
            "type": "string",
            "example": "your_password"
          }
        }
      },
      "AccountLinkResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Account Link Success"
          },
          "connected_account_email": {
            "type": "string",
            "example": "your_connected_account_email@domain.com"
          },
          "vins": {
            "type": "array",
            "items": {
              "type": "string",
              "properties": {
                "vin": {
                  "type": "string",
                  "example": "3D7UT2CL0BG625027"
                }
              }
            }
          }
        }
      },
      "Unlink": {
        "required": ["connected_account_email"],
        "type": "object",
        "properties": {
          "connected_account_email": {
            "type": "string",
            "example": "your_connected_account_email@domain.com"
          }
        }
      },
      "UnlinkResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Account Removal Success"
          },
          "connected_account_email": {
            "type": "string",
            "example": "your_connected_account_email@domain.com"
          }
        }
      },
      "SetPassword": {
        "required": ["api_account_owner", "current-password", "password"],
        "type": "object",
        "properties": {
          "api_account_owner": {
            "type": "string",
            "example": "your_api_account@email.com"
          },
          "current-password": {
            "type": "string",
            "example": "Your_Current_PW!1"
          },
          "password": {
            "type": "string",
            "example": "Your_New_PW!1"
          }
        }
      },
      "SetPasswordResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Successfully set permanent password"
          }
        }
      },
      "ResetPassword": {
        "required": ["api_account_owner"],
        "type": "object",
        "properties": {
          "api_account_owner": {
            "type": "string",
            "example": "your_api_account_email@domain.com"
          }
        }
      },
      "ResetPasswordResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Your Password was Reset - Information sent to your_connected_account_email@domain.com"
          }
        }
      },
      "AccountDeleteResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Account was permanently deleted. To request a new account, use /account/register"
          }
        }
      },
      "Feedback": {
        "type": "object",
        "properties": {
          "feedback-content": {
            "type": "string",
            "example": "Enter your feedback here."
          }
        }
      },
      "FeedbackResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Feedback received. Thank you."
          }
        }
      },
      "Bugs": {
        "type": "object",
        "properties": {
          "feedback-content": {
            "type": "string",
            "example": "Enter a detailed description of the issue."
          }
        }
      },
      "BugsResponse": {
        "type": "object",
        "properties": {
          "result": {
            "type": "string",
            "example": "Bug report received. Thank you."
          }
        }
      },
      "DataResponseinner": {
        "type": "object",
        "properties": {
          "app_timestamp": {
            "type": "string",
            "example": "2021-10-31 21:59:06.000"
          },
          "data": {
            "type": "object",
            "example": "{\"Speedometer\": \"15.0\", \"PowerMode\": \"4\", \"PresentGear\": \"2\",\"SteeringWheelAngle\": \"-6.0\"}"
          }
        }
      },
      "GetAccountResponseinner": {
        "type": "object",
        "properties": {
          "connected_account_email": {
            "type": "string",
            "example": "your_connected_account_email@domain.com"
          },
          "vins": {
            "type": "array",
            "items": {
              "type": "string",
              "example": "3D7UT2CL0BG625027"
            }
          }
        }
      }
    },
    "securitySchemes": {
      "BearerAuth": {
        "type": "apiKey",
        "name": "Authorization",
        "in": "header"
      },
      "ApiKeyAuth": {
        "type": "apiKey",
        "name": "x-api-key",
        "in": "header"
      }
    }
  },
  "x-logo": {
    "url": "https://developers.stellantis.com/stellantis.png",
    "backgroundColor": "#000",
    "altText": "Stellantis Developers logo"
  }
}
