import React, { useEffect, useState } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SwaggerComp from '../swagger/SwaggerComp';
import SwaggerWithTabs from '../SwaggerMiddleTemplate/SwaggerWithTabs';
import SwaggerTemplate from '../swaggerTemplate/SwaggerTemplate';

const SwaggerPageGenerator = (props) => {

    const theme = useTheme();

    const swagger = props.swagger;
    const defaultView = props.defaultView;
    const docs = props.docs;
    const callbackAPIs = props.callbackAPIs;

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const boldOnHover = {
        '&:hover': {
            fontWeight: 'bold',
        }
    };
    const hideIndicator = {
        display: 'none',
    };

    const [value, setValue] = React.useState(1);
    const [SwaggerTagInfo, setSwaggerTagInfo] = React.useState(swagger.tags[0]);
    const handleChange = (event, newValue) => {
        setSwaggerTagInfo(swagger.tags[newValue - 1]);
        setValue(newValue);
    };


    return (
        <div>
            <Box sx={{ display: "flex" }}>
                <TabContext value={value}>
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'contents' }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 4, borderColor: 'divider', justifyContent: 'flex-start', paddingTop: "50px", display: "flex", textAlign: "start", minWidth: "fit-content" }}
                            TabIndicatorProps={{ style: hideIndicator }}
                        >

                            {swagger.tags.map((tab, index) => (
                                <Tab
                                    key={index}
                                    label={tab.name}
                                    value={index + 1}
                                    sx={{
                                        ...boldOnHover,
                                        display: "flex",
                                        textAlign: 'left',
                                        maxWidth: "180px",
                                        alignItems: "flex-start",
                                        fontFamily: "Montserrat",
                                        fontSize: "20px",
                                        fontWeight: "200",
                                        width: "auto",
                                        "&.Mui-selected": {
                                          color: 'black',
                                        }
                                      }}
                                     />
                            ))}
                        </Tabs>
                    </Box>
                    <div className="pl-5 pt-3" style={{width:"75%"}}>
                        {defaultView &&
                            props?.attr?.image &&

                            (<TabPanel value={value} sx={{ padding: 0 }}>
                                <div className="row justify-content-center p-3">
                                    <img
                                        src={props.attr.image}
                                        style={{ objectFit: "contain", height: "320px" }}
                                        alt="Vehicle Capabilities Img"
                                    />
                                </div>
                            </TabPanel>)}

                        <TabPanel value={value} sx={{ padding: 0 }}>
                            <div className="">
                                {
                                    defaultView ?
                                        <SwaggerTemplate SwaggerSelectedTag={SwaggerTagInfo} swagger={swagger} beInspiredTags={props?.beInspiredTags ? props.beInspiredTags[SwaggerTagInfo.name] : false}/>
                                        : <SwaggerWithTabs swagger={swagger} SwaggerSelectedTag={SwaggerTagInfo} docs={docs[SwaggerTagInfo.name]} env_info={docs["environment"]}
                                            documentation_files={docs?.documentation_files ? docs.documentation_files : false} callbackAPIs={callbackAPIs[SwaggerTagInfo.name]} />

                                }

                            </div>
                        </TabPanel>

                    </div>
                </TabContext></Box>

        </div>
    );
};

export default SwaggerPageGenerator
