export const REMOTE_COMMANDS_CALLBACK_API = `
<h1><strong><span>OUTBOUND Kinesis Stream Details</span></strong></h1>
<h3>Privacy Mode Management - Onboard Mode</h3>
<br>
<div class="json-content">
<div class="ua-chrome ProseMirror pm-table-resizing-plugin ProseMirror-hideselection" aria-label="Main content area, start typing to enter text." aria-multiline="true" role="textbox" id="ak-editor-textarea" data-gramm="false" data-has-collab-initialised="true" translate="no"><div class="code-block ak-editor-selected-node ProseMirror-selectednode" data-drag-handler-anchor-name="--node-anchor-codeBlock-0" data-drag-handler-node-type="codeBlock" data-drag-handler-anchor-depth="0" style="--lineNumberGutterWidth: 2ch; anchor-name: --node-anchor-codeBlock-0; margin-top: 0px; position: relative; z-index: 1;" draggable="true"><div class="code-block--start" contenteditable="false"></div><div class="code-block-content-wrapper code-block-content-wrapper--fg"><div class="line-number-gutter--fg" contenteditable="false"></div><div class="code-content--fg"><code data-language="json" spellcheck="false" data-testid="code-block--code"><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">1</span><span data-testid="selection-marker-selection">{
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">2</span><span data-testid="selection-marker-selection">     "EventID": “String,PrivacyModeResponse,Required”,
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">3</span><span data-testid="selection-marker-selection">     "Version": "String",
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">4</span><span data-testid="selection-marker-selection">      "Timestamp": “Long”,
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">5</span><span data-testid="selection-marker-selection">      “Data": {
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">6</span><span data-testid="selection-marker-selection">                      "response" :"String”,"SUCCESS"|”FAIL”|&lt;GSDP Generated Negative Acknowledgement&gt;|”CUSTOM_EXTENSION”,
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">7</span><span data-testid="selection-marker-selection">    “customExtension”: {
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">8</span><span data-testid="selection-marker-selection">
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">9</span><span data-testid="selection-marker-selection">           "serviceRequestId": "",
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">10</span><span data-testid="selection-marker-selection">"response": “String,PrivacyModeResponse.ResponseEnum”,
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">11</span><span data-testid="selection-marker-selection">           “isTransient”: “Boolean”,
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">12</span><span data-testid="selection-marker-selection">           “failureReasonCode” : “Integer”
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">13</span><span data-testid="selection-marker-selection">
</span><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">14</span><span data-testid="selection-marker-selection"">}</span></code></div></div><div class="code-block--end" contenteditable="false"></div></div><p data-drag-handler-anchor-name="--node-anchor-paragraph-1" data-drag-handler-node-type="paragraph" data-drag-handler-anchor-depth="0" style="anchor-name: --node-anchor-paragraph-1; position: relative; z-index: 1;"><br class="ProseMirror-trailingBreak"></p></div>
</div>


<h3>Privacy Mode Management - Offboard Mode</h3>
<div class="json-content">
<div class="ua-chrome ProseMirror pm-table-resizing-plugin" aria-label="Main content area, start typing to enter text." aria-multiline="true" role="textbox" id="ak-editor-textarea" data-gramm="false" data-has-collab-initialised="true" translate="no">
<div class="code-block" data-drag-handler-anchor-name="--node-anchor-codeBlock-1" data-drag-handler-node-type="codeBlock" data-drag-handler-anchor-depth="0" style="--lineNumberGutterWidth: 2ch; anchor-name: --node-anchor-codeBlock-1; position: relative; z-index: 1;"><div class="code-block--start" contenteditable="false"></div><div class="code-block-content-wrapper code-block-content-wrapper--fg"><div class="line-number-gutter--fg" contenteditable="false"></div><div class="code-content--fg"><code data-language="json" spellcheck="false" data-testid="code-block--code"><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">1</span>{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">2</span>     "EventID": “String,PrivacyModeNotification”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">3</span>     "Version": "String, 1.0",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">4</span>      "Timestamp": “Long”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">5</span>      “Data": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">6</span>
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">7</span>                      "privacyModeStatus" :"String, ACTIVE | INACTIVE”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">8</span>    “customExtension”: {<span contenteditable="false" style="position: relative;" class="ProseMirror-widget"><span class="css-149r82n" contenteditable="false" data-testid="selection-marker-anchor-cursor"></span></span>
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">9</span>
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">10</span>"PrivacyModeStatus": “String, PrivacyModeNotification.PrivacyModeStatusEnum”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">11</span>
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">12</span>}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">13</span>}</code></div></div><div class="code-block--end" contenteditable="false"></div></div>
<br>
</div>
</div>

<h3>Vehicle Location</h3>
<ul>
    <li><span class="bold">positionLatitude</span>: Position Longitude in degrees. Use NaN if not populated, during Privacy Mode;</li>
    <li><span class="bold">EstimatedPositionError</span>: Estimated error in Meters in a radius around the lat/lon position. If GPS fix is not current, then estimated error should be an error value (e.g., -1 or 0x80000000);</li>
    <li><span class="bold">PositionAltitude</span>: Position Altitude;</li>
    <li><span class="bold">PositionLongitude</span>: Longitude of the vehicle;</li>
    <li><span class="bold">PositionDirection</span>: Direction of  the vehicle when travelling in degrees with respect to nord;</li>
    <li><span class="bold">EstimatedAltitudeError</span>: Estimated error in Meters above or below altitude if GPS Fix is current;</li>
    <li><span class="bold">GpsFixTypeEnum</span>: Gps fix type. Possible values are: ID_FIX_NO_POS (The GNSS fix position is not fixed.), ID_FIX_3D (3-Dimensional position fix.), ID_FIX_2D (2-Dimensional position fix),  ID_FIX_ESTIMATED (The GNSS fix position is not fixed.)</li>
    <li><span class="bold">IsGPSFixNotAvailable</span>: Data complementary to “gpsFixTypeEnum": True when the FixType is ID_FIX_NO_POS[0] or ID_FIX_ESTIMATED[3]</li>
</ul>
<div class="json-content">
<div class="ua-chrome ProseMirror pm-table-resizing-plugin" aria-label="Main content area, start typing to enter text." aria-multiline="true" role="textbox" id="ak-editor-textarea" data-gramm="false" data-has-collab-initialised="true" translate="no">
<div class="code-block" data-drag-handler-anchor-name="--node-anchor-codeBlock-1" data-drag-handler-node-type="codeBlock" data-drag-handler-anchor-depth="0" style="--lineNumberGutterWidth: 2ch; anchor-name: --node-anchor-codeBlock-1; position: relative; z-index: 1;"><div class="code-block--start" contenteditable="false"></div><div class="code-block-content-wrapper code-block-content-wrapper--fg"><div class="line-number-gutter--fg" contenteditable="false"></div><div class="code-content--fg"><code data-language="" spellcheck="false" data-testid="code-block--code"><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">1</span>{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">2</span>     "EventID": “Location”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">3</span>     "Version": "1.0",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">4</span>      "Timestamp": 1454674114815,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">5</span>      “Data": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">6</span>                      “origin”:”OnDemand”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">7</span>    "longitude": &lt;double&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">8</span>    "latitude": &lt;double&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">9</span>    “bearing”:&lt;integer&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">10</span>    “altitude”: &lt;double&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">11</span>   “horPosError”:&lt;integer&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">12</span>"response" :"SUCCESS"|”FAIL”|&lt;GSDP Generated Negative Acknowledgement&gt;|”CUSTOM_EXTENSION”,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">13</span>    “customExtension”: {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">14</span>    "vehicleLocation": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">15</span>"positionLatitude": &lt;double&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">16</span>"estimatedPositionError": &lt;integer&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">17</span>"positionAltitude": &lt;double&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">18</span>"positionLongitude": &lt;double&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">19</span>"positionDirection": &lt;String&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">20</span>"estimatedAltitudeError":  &lt;integer&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">21</span>"gpsFixTypeEnum": &lt;Location.GpsFixType&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">22</span>"isGPSFixNotAvailable": &lt;boolean&gt;
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">23</span>},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">24</span>"serviceRequestID": "",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">25</span>"response": &lt;VehicleLocationResponse.VehicleLocationResponseEnum&gt;,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">26</span>"timestamp":&lt;long&gt;
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">27</span>
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">28</span>}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">29</span>}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">30</span><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak"></code></div></div><div class="code-block--end" contenteditable="false"></div></div><p data-drag-handler-anchor-name="--node-anchor-paragraph-2" data-drag-handler-node-type="paragraph" data-drag-handler-anchor-depth="0" style="anchor-name: --node-anchor-paragraph-2; position: relative; z-index: 1;"><br class="ProseMirror-trailingBreak"></p><p data-drag-handler-anchor-name="--node-anchor-paragraph-3" data-drag-handler-node-type="paragraph" data-drag-handler-anchor-depth="0" style="anchor-name: --node-anchor-paragraph-3; position: relative; z-index: 1;"><span contenteditable="false" style="position: relative;" class="ProseMirror-widget"><span class="css-149r82n" contenteditable="false" data-testid="selection-marker-anchor-cursor"></span></span><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak"></p></div>
</div>
`