import React from "react";
import BackGroundVideoFrame from "../../components/BackgroundVideo/BackGroundVideoFrame";
import { useForm } from "react-hook-form";
import { ResetAccountApiPassword } from "../ResetAccountPassword/ResetAcountApiCallback";
import { Link, useNavigate } from "react-router-dom";
// import SLogo from "../../images/stellantis_image.png";
import '../Register/style.css'
import { Container } from "react-bootstrap";

const ForgotPassword = () => {
  const { register, handleSubmit, reset } = useForm();

  const navigate = useNavigate()
  const onSubmit = async (data) => {
    await ResetAccountApiPassword(data, navigate)    
    reset()
  }

  return (
    <>
      <div className="Register-page">
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <Container className="Register-container">
          <div style={{display: "flex", "flex-direction": "column","flex-wrap": "wrap"}}>

            <img src={'/images/stellantis_image.png'} alt="SLogo" className="img-fluid" style={{ objectFit: "contain" }} />
            </div>
            <h2 className="p-3" style={{ color: "white", textAlign: "center" }}>Set password</h2>
            <div class="form-group">
                  <label for="Email">
                    Username/Email <span class="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="Email"
                    {...register("Email", {
                      required: "Email is required",
                    })}
                    placeholder="Enter Stellantis Email"
                  />
                </div>

                <div class="form-group text-center">
                <button type="submit" className="signin-button" variant="light">
                    Submit
                  </button>
                </div>
        </Container>
      </form>
    </div >
    </>
  );
};

export default ForgotPassword;
