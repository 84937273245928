import axios from "axios";
import { API_URL } from "../../constant/APIConstants";


function CreateSDKInstance() {
    const defaultConfig = {
        baseURL: API_URL,
        params: {},
        headers: {
            "Content-Type": "application/json"
        },
        data: {}
    }

    return axios.create(defaultConfig);
}

async function SendPopupAPICall(vin, requestBody, token, apiKey) {
    const SDKInstance = CreateSDKInstance()
    try {
        const response = await SDKInstance.post(`/data/${vin}/popup`,
            requestBody, {
            headers: {
                "Authorization": `Bearer ` + token,
                "x-api-key": apiKey,
                "Content-Type": "application/json"
            }
        })

        return response;
    }
    catch (error) {
        console.log("error from send popup function", error)
        return error;
    }
}


export {
    SendPopupAPICall
};