import React, { useState } from 'react';
import { DeleteAcountApiCallback } from './DeleteAccountApiCallBack';
import { userLogout } from "../../Actions/LoginAction";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom'
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";


const PermanentlyDeleteAccount = () => {
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDialogClose = () => {
    setAccountEmail("");
    setDeleteAccountDialogOpen(false);
  };

  const data = useSelector(
    (state) => state.UserData
  );

  const handleDeleteAccount = () => {
    handleDialogClose();
    DeleteAcountApiCallback()
    setTimeout(() => {
      handleLogout();
    }, 5000)
  }

  const handleLogout = async () => {
    await dispatch(userLogout());
    navigate("/login");
  };

  const userEmail = data.UserData.api_account_owner
  return (
    <>
      <Dialog open={deleteAccountDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`If you want to delete this account permenantly, type "${userEmail}" in the box below. Please be aware that this action is not reversible.`}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="locationCollectionName"
            placeholder={userEmail}
            type="text"
            fullWidth
            variant="outlined"
            required
            value={accountEmail}
            onChange={(e) => setAccountEmail(e.target.value)}
            color="grey"
          />

        </DialogContent>
        <DialogActions>
          <Button disabled={accountEmail != userEmail} color="error" variant="contained" onClick={handleDeleteAccount} style={{ "width": "100%" }}>
            Delete permenantly
          </Button>
        </DialogActions>
      </Dialog>
      <div className="col-12 custom-md6-width  mt-2" style={{ "max-width": "50%" }}>
        <h2 style={{ color: "rgb(50, 50, 159);" }}>
          Delete Account
        </h2>
        <h5>Delete Account</h5>
        <button
          id="submit-button"
          type="submit"
          onClick={() => { setDeleteAccountDialogOpen(true) }}
          class="btn btn-primary"
          style={{ "margin-bottom": "1rem" }}
        >
          Delete API Account
        </button>
      </div>
    </>
  );
}

export default PermanentlyDeleteAccount