import React, { lazy, Suspense } from "react";
import "./Mobilisight_Home_Page.css";
import Banner from "../../../components/Banner/Banner";
import {useNavigate} from 'react-router-dom'

const Mobilisight_Home_Page = () => {
  const mobilisightCatalogues = [
    { icon: '/images/exP_exF_mobilisights.png', title: "exP Data Catalogue",style:{ height: "77px", width: "77px" },url:"/Explore/Mobilisight/exPDataCatalogue"},
    { icon: '/images/exP_exF_mobilisights.png', title: "exF Data Catalogue" ,style:{ height: "77px", width: "77px"},url:"/Explore/Mobilisight/exFDataCatalogue"},
    { icon: '/images/exFAPI.png', title: "Stellantis for Mobilisights exF APIs",style:{height: "70px", width: "70px", marginLeft: "9px"},url:"/Explore/Mobilisight/MobilisightexFAPIs"},
  ];
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Banner
          image={"/images/Mobilisights-button-data-stream.png"}
          heading={"Stellantis for Mobilisights"}
        />
      </div>
      <br />
      <div className="page_box">
        <div className="first_container">
          <img
            src={'/images/mobilisights_stellantis.png'}
            alt="Mobilisights Stellantis"
            style={{ height: "152px", width: "324px" }}
          />
          <img
            src={'/images/mobilisightsLogo.png'}
            alt="Mobilisights Stellantis"
            style={{ height: "152px", width: "271px" }}
          />
        </div>
        <div className="main_container">
          <div className="row pt-2 pb-2">
            {mobilisightCatalogues.map((ele)=>{ 
              return ( 
                <a
                className="btn btn-block btn-xl MobilisightNav"
                onClick={()=> navigate(ele.url)}
              >
                {/* {val} */}
                <span style={{ marginRight: "auto" }}>
                  <img
                    src={ele.icon}
                    alt="Mobilisights Stellantis"
                    style={ele.style}
                  />
                  <span className="title">{ele.title}</span>
                </span>
                <span>
                  {" "}
                  <img
                    src={'/images/mobilisightsArrow.png'}
                    alt="Mobilisights Stellantis"
                    style={{ height: "55px", width: "55px" }}
                  />
                </span>
              </a>
              )
            })}
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Mobilisight_Home_Page;
