import React from 'react'
import SwaggerComp from '../swagger/SwaggerComp'
import './styles.css'
import BrandCard from '../BrandCards/BrandCard'
const SwaggerTemplate = (props) => {
    console.log(props)
    const swagger = props.swagger
    const beInspiredTags = props.beInspiredTags
    const SwaggerSelectedTag = props.SwaggerSelectedTag
    const heading = props?.heading ? props.heading : SwaggerSelectedTag.name
    return (
        <div className="row swaggerTemplate" style={{ minHeight: "70vh", paddingBottom: "50px" }}>
            <div className="container">
                <div class="row pb-4">
                    <div class="col">
                        <h2>{heading}</h2>
                        <p>
                            {SwaggerSelectedTag.description}
                        </p>
                    </div>

                </div>
                <SwaggerComp tag={SwaggerSelectedTag.name} swaggerFile={swagger} style={{ "text-align": "justify" }} />


            </div>
            
            {beInspiredTags && (
                <div className="row mb-2 mt-3 swaggerTemplate" style={{paddingTop: "30px"}}>
                    

                    <h2>Be Inspired</h2>
                    <p>
                    Be inspired by our software product to improve your software ideas
                    </p>
                    {beInspiredTags.map((item, index) => (
                        <BrandCard
                            heading={item.heading}
                            text={item.text}
                            image={item.image}
                            link={item.link}
                        />
                    ))}


                </div>
            )}

        </div>
    )
}

export default SwaggerTemplate
