import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CreateGeofenceCollection, DeleteGeofenceCollection } from "./GeofencingAPI";
import { UserData } from "../../Actions/UsersData";
import { getUserToken, getApiKey } from "../../utils/utility";

const GeofenceCollections = () => {

    const API_KEY = getApiKey();
    const TOKEN = getUserToken();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [showSpinner, setShowSpinner] = useState(true);
    const [geofenceCollections, setGeofenceCollections] = useState([]);
    const [filteredGeofenceCollections, setFilteredGeofenceCollections] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [createCollectionDialogOpen, setCreateCollectionDialogOpen] = useState(false);
    const [geofenceCollectionName, setGeofenceCollectionName] = useState("");
    const [createGeofenceCollectionError, setcreateGeofenceCollectionError] = useState(null);
    const [deleteCollectionDialogOpen, setDeleteCollectionDialogOpen] = useState(false);
    const [geofenceCollectionNameToDelete, setGeofenceCollectionNameToDelete] = useState("");
    const [deleteGeofenceCollectionError, setdeleteGeofenceCollectionError] = useState(null);

    const data = useSelector(
        (state) => state.UserData
    );
    useEffect(() => {
        setGeofenceCollections(data.UserData.geofence_collections ? data.UserData.geofence_collections : []);
        setShowSpinner(false);
    }, [data]);




    const handleClickDialogOpen = () => {
        setCreateCollectionDialogOpen(true);
    };

    const handleDialogClose = () => {
        setCreateCollectionDialogOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const appliedGeofenceCollections = [
        ...(isSearching ? filteredGeofenceCollections : geofenceCollections),
    ];

    const search = (event) => {
        setIsSearching(true);
        console.log("search result", event.target.value);
        const searchValue = event.target.value;
        const geofenceCollectionMatchSearch = geofenceCollections.filter((gf_collection) =>
            gf_collection.collection_name.includes(searchValue)
        );

        if (searchValue !== "") {
            setFilteredGeofenceCollections(geofenceCollectionMatchSearch);
            if (geofenceCollectionMatchSearch.length === 0) {
                console.log("search was unsuccessful");
            }
        } else {
            setGeofenceCollections(
                data.UserData.geofence_collections ? data.UserData.geofence_collections : []
            );
            setIsSearching(false);
        }
    };

    const deleteGeofenceCollection = () => {
        setShowSpinner(true);
        DeleteGeofenceCollection(geofenceCollectionNameToDelete, TOKEN, API_KEY)
            .then((resp) => {
                if (resp.data) {
                    dispatch(UserData(getUserToken()))
                    handleDeleteGeofenceCollectionDialogClose();
                    console.log("response from deleting geofence collection", resp)
                    setShowSpinner(false);
                }
                else {
                    setShowSpinner(false);
                    setdeleteGeofenceCollectionError(resp.response?.data);
                }
            })
            .catch((err) => {
                showSpinner(false);
                console.log("err from delete geofence collection", err.response?.data)
                setdeleteGeofenceCollectionError(err.response?.data);
            });
    };

    const createGeofenceCollection = () => {
        setShowSpinner(true);
        CreateGeofenceCollection(
            { collection_name: geofenceCollectionName, entries: [] },
            TOKEN,
            API_KEY
        )
            .then((resp) => {
                if (resp.data) {
                    dispatch(UserData(getUserToken()))
                    console.log("response from creating geofence collection", resp)
                    handleCreateGeofenceCollectionDialogClose();
                    setShowSpinner(false);
                }
                else {
                    setShowSpinner(false);
                    setcreateGeofenceCollectionError(resp.response?.data);
                }

            })
            .catch((err) => {
                showSpinner(false);
                console.log("err from creating geofence collection", err.response?.data)
                setcreateGeofenceCollectionError(err.response?.data);
            });
    };

    const handleCreateGeofenceCollectionDialogOpen = () => {
        setcreateGeofenceCollectionError(null);
        setCreateCollectionDialogOpen(true);
    };

    const handleCreateGeofenceCollectionDialogClose = () => {
        setcreateGeofenceCollectionError(null);
        setCreateCollectionDialogOpen(false);
        setGeofenceCollectionName("");
    };

    const handleDeleteGeofenceCollectionDialogOpen = () => {
        setdeleteGeofenceCollectionError(null);
        setDeleteCollectionDialogOpen(true);
    };

    const handleDeleteGeofenceCollectionDialogClose = () => {
        setdeleteGeofenceCollectionError(null);
        setDeleteCollectionDialogOpen(false);
        setGeofenceCollectionNameToDelete("");
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                onClose={() => handleSnackbarClose()}
                message={snackbarMessage}
                key={"snackbar"}
            />
            <Dialog open={createCollectionDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Create a Geofence Collection</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create a geofence collection, please enter its name here.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="locationCollectionName"
                        label="Name"
                        placeholder="value"
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={geofenceCollectionName}
                        onChange={(e) => setGeofenceCollectionName(e.target.value)}
                    />
                    {createGeofenceCollectionError ? (
                        <Typography variant="body1">{createGeofenceCollectionError}</Typography>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateGeofenceCollectionDialogClose} disabled={showSpinner}>

                        Cancel
                    </Button>
                    {showSpinner ? (
                        <CircularProgress size="2rem" />
                    ) : (
                        <Button className="btn btn-primary" variant="contained" onClick={createGeofenceCollection}>
                            Create
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={deleteCollectionDialogOpen} onClose={handleDeleteGeofenceCollectionDialogClose}>
                <DialogTitle>Delete a Geofence Collection</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {geofenceCollectionNameToDelete}?
                    </DialogContentText>
                    {deleteGeofenceCollectionError ? (
                        <Typography variant="body1">{deleteGeofenceCollectionError}</Typography>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteGeofenceCollectionDialogClose} disabled={showSpinner}>
                        Cancel
                    </Button>
                    {showSpinner ? (
                        <CircularProgress size="2rem" color="error" />
                    ) : (
                        <Button variant="contained" color="error" onClick={deleteGeofenceCollection}>
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: "1.5rem",
                    paddingTop: "1rem"
                }}
            >
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5">Geofence Collections</Typography>
                </Box>
                <Button
                    className="btn btn-primary"
                    variant="contained"
                    onClick={handleCreateGeofenceCollectionDialogOpen}
                    disabled={geofenceCollections.length === 5}
                    sx={{ maxWidth: 'fit-content' }}
                >
                    Create Collection
                </Button>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem"
                }}
            >
                <Paper sx={{ paddingTop: "1rem", paddingBottom: "1rem" }} elevation={5}>
                    <Box sx={{ paddingLeft: "2rem" }}>
                        <Typography variant="body2" sx={{ paddingBottom: "1rem" }}>
                            Geofence collections that represent groups of fences. You may create up to 5 geofence
                            collections.
                        </Typography>
                        <TextField
                            sx={{ paddingBottom: "2rem" }}
                            id="outlined-search"
                            label="Search"
                            type="search"
                            placeholder="Geofence Collection Name"
                            onChange={(e) => search(e)}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}
                    >
                        {showSpinner ? (
                            <CircularProgress />
                        ) : appliedGeofenceCollections?.length > 0 ? (
                            appliedGeofenceCollections.map((gf_collection) => {
                                return (
                                    <Card
                                        sx={{
                                            maxWidth: 345,
                                            marginRight: "1rem",
                                            marginLeft: "1rem",
                                            marginBottom: "2rem",
                                            backgroundColor: "#f0f0f0",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                        key={gf_collection.collection_name}
                                    >
                                        <CardContent>
                                            <Typography gutterBottom variant="p" component="div">
                                                {gf_collection.collection_name}
                                            </Typography>
                                            <Typography variant="span" color="text.secondary">
                                                {gf_collection.entries?.length > 0 ? (
                                                    <List>
                                                        <Typography>Geofences:</Typography>
                                                        {gf_collection.entries.map((gf_enrty) => {
                                                            return (
                                                                <ListItem disablePadding key={gf_enrty.GeofenceId}>
                                                                    <ListItemText primary={gf_enrty.GeofenceId} />
                                                                </ListItem>
                                                            );
                                                        })}
                                                    </List>
                                                ) : (
                                                    <Typography>This collection does not have any fences.</Typography>
                                                )}
                                            </Typography>
                                        </CardContent>
                                        <CardActions sx={{ justifyContent: "space-around" }}>
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    navigate(`/services/geofencing/geofence-collections/${gf_collection.collection_name}`)
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    setGeofenceCollectionNameToDelete(
                                                        gf_collection.collection_name
                                                    );
                                                    setGeofenceCollectionNameToDelete(gf_collection.collection_name);
                                                    handleDeleteGeofenceCollectionDialogOpen();
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </CardActions>
                                    </Card>
                                );
                            })
                        ) : (
                            <Typography style={{ marginLeft: "1rem" }}>No geofence collections were found.</Typography>
                        )}
                    </Box>
                </Paper>
            </Box>
        </>
    );
};

export default GeofenceCollections;