import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
import { getApiKey } from "../../utils/utility";

export const UpdateAccountPasswordApiCallback = (data, navigate) => {
  const endpoint = API_URL + "/account/setpassword ";
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Updating Password",
    allowOutsideClick: false,
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  const data1 = fetch(endpoint, {
    method: "POST",
    headers: {
      "x-api-key": getApiKey(),
    },
    body: JSON.stringify({
      api_account_owner: data.Email,
      "current-password": data.CurrentPassword,
      password: data.password,
    }),
  })
    .then((response) => {
      
      if (!response.ok) {
        return response.json().then((errorData) => {
         throw errorData.message;
       });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();

     Swal.fire({
       text: data?.result
         ? data.result
         : "" + data?.message
         ? data.message
         : "",
       icon: "success",
       backdrop: "rgba(0, 0, 0, 0.85)",
       customClass: {
         title: "sweetalert-color", // Apply custom styles to the title
         content: "sweetalert-color", // Apply custom styles to the content (text)
       },
     });
     navigate("/login")
      return data;
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({
        title: "Error",
        text: error,
        icon: "Error",
        backdrop: "rgba(0, 0, 0, 0.85)",
        customClass: {
          title: "sweetalert-color", // Apply custom styles to the title
          content: "sweetalert-color", // Apply custom styles to the content (text)
        },
      }); 
      console.error("Error fetching data:", error);
      return error;
    });

  return data1;
};
