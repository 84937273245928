export const REAL_TIME_DATA_FEED = `
<h1><strong>GCV Data Stream</strong></h1>
<h2><strong>Opt-In</strong></h2>
<p>This is the second step any data receptor must first take when one or more new Vehicles need to be accessed, for any of the following purposes:</p>
<ul>
<li>UBI - Data Monetization packages requested in the Kinesis GCV Stream</li>
<li>FTD - Fleet Telematic Data packages requested in the Kinesis GCV Stream</li>
</ul>
<p>The above represented procedure needs to be executed according to the following workflow, independently from the VIN identity (Brand/Model/Series), mounted telematic box (TBM, MC), SalesCluster (B2B/B2C) or Region (NAFTA, EMEA):</p>
<ol>
<li>The Third Party requests an <strong>Eligibility Check - GCV (9.4.1)</strong> on the specific VIN. They shall receive a synchronous Response containing its readiness for UBI, FTD and RUA services start</li>
<li>The Third Party requests the <strong>Enrollment (9.4.2) </strong>to be performed on the VINs, associating it to the desired Partner Profile <em>(please check next chapter)</em></li>
<li>FCL associates the requested VIN to the specified Partner Profile in its Database, then sends a Data Flow Start request towards the correct Provider (Ignite for TBM VINs, Targa for MC VINs)</li>
<li>The Provider activates the desired Data Flows on the onboard black box. This step differs according to the box type. Independently from it, From this step on, the VIN&rsquo;s onboard CAN Network shall start sampling data.
<ol>
<li><em>MC - MoparConnect </em>: Targa sends a Start request towards the onboard. From now on, given the Third Party previously activated UBI/FTD/RUA services according to the dedicated procedure (please check chapter 8), the CAN Network starts sampling</li>
<li><em>TBM - TelematicBoxModule - MY21 and older</em> : Ignite sends a standard Policy File towards the onboard, according to the features associated with the Partner Profile the VIN is now paired to</li>
<li><em>TBM - TelematicBoxModule - MY22 and newer</em> : ADACloud sends a standard Policy File towards the onboard, according to the features associated with the Partner Profile the VIN is now paired to</li>
</ol>
</li>
<li>The Provider sends a Callback Event towards FCL, stating the results of the requested operation</li>
<li>FCL pushes the Callback Event towards the 3rd Party, via API Interface OR Kinesis On-Demand Stream</li>
<li>From now on, whenever an UBI/FTD/RUA data package is sampled, the black box shall send it to the Provider servers, which shall then reroute it to FCL (leaving it untouched, simply acting as pass-through), to have them normalized and finally dispatched via API interface or printed on the Kinesis GCV Stream</li>
</ol>
<p><strong>NOTES</strong></p>
<ul>
<li>The Third Party can request for an immediate interruption of all the continuous data flows at once (NOT BY FEATURE), by invoking the <strong>Unenrollment (9.4.3)</strong> API
<ul>
<li>Please note that this request does not generate a callback within the Kinesis On-Demand Stream its results shall be part of the synchronous API response</li>
</ul>
</li>
<li>The Third Party can modify a service&rsquo;s duration on any number of VINs anytime, by invoking a new <strong>Enrollment (9.4.2) </strong>request with a different duration. This command shall flow according to the main process, hence generating a new dedicated callback on the Kinesis On-Demand Stream
<ul>
<li>Please note that the start date SHALL be impacted by this call, so the new duration has to be calculated according to the LAST inputted</li>
</ul>
</li>
</ul>
<h2><strong>Partner Profiles</strong></h2>
<p>A Partner Profile is the virtual position of a Third Party within FCL Dispatcher.</p>
<p>For the GCV Data services only, a Third Party will have at least one Partner Profile.</p>
<p>Vehicles are associated with a Partner Profile via Enrollment requests.</p>
<p>A VIN could be associated with any number of profiles.</p>
<p>A Third Party will have the possibility to read, printed on the Kinesis GCV Stream, data generated by VINs associated with at least one of their profiles only.</p>
<p>Every Partner Profile is tied to one or more GCV Features: UBI, FTD, RUA, or any combination of those.</p>
<p>Whenever a VIN is associated with a Partner Profile, the data flow start is requested towards the black box for all the Features associated with the Partner Profile.</p>
<p>The onboard black box shall always sample and send all the signals associated with the activated and started GCV features.</p>
<p>In case the Third Party wishes to receive a subset of signals within a Feature&rsquo;s perimeter, they need to communicate the exact list during their Partner Profile creation step.</p>
<p>As stated above, any Third Party could have multiple Partner Profiles, each configured for a different business case: custom datasets are possible to have by configuring dedicated Partner Profiles.</p>
<h2><strong>UBI Autoenrollment (EMEA Only)</strong></h2>
<p>In EMEA Region only, UBI Feature supports the Autoenrollment functionality. A Partner Profile associated with UBI Feature can have this parameter set to TRUE. This can be decided during its creation, and can eventually be changed later. Usually, a Profile of this kind is not meant to receive manual Enrollment requests. It is meant to be used for B2C scenarios only.</p>
<p>Whenever a Partner Profiles has Autoenrollment parameter = TRUE, VINs are associated with it during the User&rsquo;s registration procedure in their dedicated touchpoins (either web portals or mobile apps): as soon as they grant their 4th Privacy Consent (representing their consent to third parties&rsquo; profiling), FCL is informed via internal processes, and directly proceeds associating the VIN to all the so configured Partner Profiles.</p>
<p>This does fire a dedicated callback event anyway, to be read in the Kinesis On-Demand Stream, identically to the manual procedure.</p>
<p>This type of association&rsquo;s standard duration is<strong> 100 Years</strong> (a Century, yes you read it correctly).</p>
` 
//`
// <h1><strong><span>GCV Data Stream</span></strong></h1>
// <p><span>&nbsp;</span></p>
// <h2><strong><span>Opt-In</span></strong></h2>
// <p><span>This is the second step any data receptor must first take when one or more new Vehicles need to be accessed, for any of the following purposes:</span></p>
// <ul>
//     <li><span>UBI - Data Monetization packages requested in the Kinesis GCV Stream</span></li>
//     <li><span>FTD - Fleet Telematic Data packages requested in the Kinesis GCV Stream</span></li>
// </ul>
// <p><img src="/assets/mobilisight/gcvdatastream1.png"/></p>
// <p>&nbsp;</p>
// <p><img src="/assets/mobilisight/gcvdatastream2.png"/></p>
// <p><span>&nbsp;</span></p>
// <p><span>The above represented procedure needs to be executed according to the following workflow, independently from the VIN identity (Brand/Model/Series), mounted telematic box (TBM, MC), SalesCluster (B2B/B2C) or Region (NAFTA, EMEA):</span></p>
// <p><span>&nbsp;</span></p>
// <ol>
//     <li><span>The Third Party requests an <strong>Eligibility Check - GCV (9.4.1)</strong> on the specific VIN. They shall receive a synchronous Response containing its readiness for UBI, FTD and RUA services start</span></li>
//     <li><span>The Third Party requests the <strong>Enrollment (9.4.2)&nbsp;</strong>to be performed on the VINs, associating it to the desired Partner Profile <em>(please check next chapter)</em></span></li>
//     <li><span>FCL associates the requested VIN to the specified Partner Profile in its Database, then sends a Data Flow Start request towards the correct Provider (Ignite for TBM VINs, Targa for MC VINs)</span></li>
//     <li><span>The Provider activates the desired Data Flows on the onboard black box. This step differs according to the box type. Independently from it, From this step on, the VIN’s onboard CAN Network shall start sampling data.</span>
//         <ol>
//             <li><em><span>MC - MoparConnect </span></em><span>: Targa sends a Start request towards the onboard. From now on, given the Third Party previously activated UBI/FTD/RUA services according to the dedicated procedure (please check chapter 8), the CAN Network starts sampling</span></li>
//             <li><em><span>TBM - TelematicBoxModule - MY21 and older</span></em><span>: Ignite sends a standard Policy File towards the onboard, according to the features associated with the Partner Profile the VIN is now paired to</span></li>
//             <li><em><span>TBM - TBM - TelematicBoxModule - MY22 and newer </span></em><span>&nbsp;: ADACloud sends a standard Policy File towards the onboard, according to the features associated with the Partner Profile the VIN is now paired to</span></li>
//         </ol>
//     </li>
//     <li><span>The Provider sends a Callback Event towards FCL, stating the results of the requested operation</span></li>
//     <li><span>FCL pushes the Callback Event towards the 3rd Party, via API Interface OR Kinesis On-Demand Stream</span></li>
//     <li><span>From now on, whenever an UBI/FTD/RUA data package is sampled, the black box shall send it to the Provider servers, which shall then reroute it to FCL (leaving it untouched, simply acting as pass-through), to have them normalized and finally dispatched via API interface or printed on the Kinesis GCV Stream</span></li>
// </ol>
// <p><span>&nbsp;</span></p>
// <p><strong><span>NOTES</span></strong></p>
// <ul>
//     <li><span>The Third Party *** ******* for an immediate ************ of all the continuous data flows at once (*** BY FEATURE), by invoking the <strong>Unenrollment (9.4.3)</strong> API</span>
//         <ol>
//             <li><span>Please **** that this ******* **** not generate a ******** within the ******* On-****** Stream *** results shall be part of *** *********** API response</span></li>
//         </ol>
//     </li>
//     <li><span>*** Third Party *** ****** a service&rsquo;s duration on *** number of **** anytime, ** invoking a *** <strong>Enrollment (9.4.2)&nbsp;</strong>request **** a ********* duration. **** ******* shall flow ********* ** the **** process, hence ********** a *** dedicated ******** on the Kinesis On-****** Stream</span>
//         <ol>
//             <li><span>Please **** **** *** start date &nbsp;SHALL ** ******** ** **** call, so the new duration *** to be calculated according to *** **** inputted</span></li>
//         </ol>
//     </li>
// </ul>
// <p><span>&nbsp;</span></p>
// <h2><strong><span>******* Profiles</span></strong></h2>
// <p><span>A ******* Profile is the virtual ******** of a Third Party within *** Dispatcher.</span></p>
// <p><span>For *** *** Data services only, a ***** ***** will have at ***** one Partner Profile.</span></p>
// <p><span>******** *** ********** **** a ******* Profile *** Enrollment requests.&nbsp;</span></p>
// <p><span>A VIN could ** associated with any ****** of profiles.</span></p>
// <p><span>A Third Party will **** the *********** to read, ******* ** *** Kinesis *** Stream, **** ********* by VINs ********** **** at least one of their profiles only.</span></p>
// <p><span>&nbsp;</span></p>
// <p><span>***** ******* Profile is tied ** one or more GCV Features: UBI, FTD, RUA, ** *** combination of those.</span></p>
// <p><span>Whenever a VIN is associated with a Partner Profile, the data **** start is requested ******* the black box for all *** Features associated with *** Partner Profile.</span></p>
// <p><span>&nbsp;</span></p>
// <p><span>*** onboard black box shall always sample *** send *** the signals associated with the activated and started *** features.</span></p>
// <p><span>In **** the Third ***** wishes ** ******* a ****** of signals within a Feature&rsquo;s perimeter, **** need to communicate the exact list during their Partner Profile ******** step.</span></p>
// <p><span>As ****** above, any ***** ***** could have ******** Partner Profiles, each configured for a ********* ******** case: custom ******** are possible to have ** configuring dedicated ******* Profiles.</span></p>
// <p><span>&nbsp;</span></p>
// <h2><strong><span>UBI Autoenrollment (EMEA Only)</span></strong></h2>
// <p><span>In EMEA ****** only, *** ******* supports *** Autoenrollment functionality. A ******* Profile ********** with *** Feature can **** this ********* set to TRUE. This *** ** decided during its creation, and *** ********** be changed later. Usually, a Profile of this kind ** *** meant ** ******* manual Enrollment requests. It is meant to be used for B2C scenarios only.</span></p>
// <p><span>&nbsp;</span></p>
// <p><span>******** a Partner Profiles *** Autoenrollment parameter = TRUE, VINs are associated with it during the User&rsquo;s registration ********* ** their ********* touchpoins (either web portals ** mobile apps): as soon as **** grant their 4th ******* Consent (representing their consent to third parties&rsquo; profiling), FCL ** ******** via internal processes, and ******** ******** associating the VIN to all *** ** ********** Partner Profiles.</span></p>
// <p><span>This does fire a dedicated ******** ***** anyway, to ** read in the Kinesis On-Demand Stream, identically ** the manual procedure.</span></p>
// <p><span>This type of association&rsquo;s ******** duration is<strong>&nbsp;*** Years</strong> (a Century, *** *** **** it correctly).</span></p>
// `