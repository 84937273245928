import React from 'react'
import { useForm } from "react-hook-form";
import { GeneralFeedbackAPI } from './GeneralFeedbackAPI';

const GeneralFeedback = () => {

    const { register, handleSubmit } = useForm();

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
            <h2 style={{ color: "rgb(50, 50, 159);" }}>General Feedback</h2>
            <h5>
              Submit general feedback, questions, comments to Stellantis. For
              bug report, use /bugs
            </h5>
            <form onSubmit={handleSubmit(GeneralFeedbackAPI)}>
              <div class="form-group">
                <label for="feedback-content">
                  Feedback<span className="required-star">*</span>
                </label>
                <textarea
                  required
                  className="form-control"
                  id="feedback-content"
                  {...register("feedback")}
                  placeholder="Enter Feedback content"
                ></textarea>
              </div>

              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}



export default GeneralFeedback