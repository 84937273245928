import axios from "axios";
import { API_URL } from "../../constant/APIConstants";

function CreateSDKInstance() {
    const defaultConfig = {
        baseURL: API_URL,
        params: {},
        headers: {
            "Content-Type": "application/json"
        },
        data: {}
    }

    return axios.create(defaultConfig);
}



async function CreateGeofenceCollection(geofenceCollectionJson, token, apiKey) {
    const SDKInstance = CreateSDKInstance()
    console.log("data", geofenceCollectionJson)
    try {
        const response = await SDKInstance.post("/data/geofence-collection", geofenceCollectionJson, {
            headers: {
                "Authorization": `Bearer ` + token,
                "x-api-key": apiKey
            }
        })

        return response;
    }
    catch (error) {
        console.log("error from create geofence collection", error)
        return error;
    }
}


async function DeleteGeofenceCollection(geofenceCollectionName, token, apiKey) {
    const SDKInstance = CreateSDKInstance()
    try {
        const response = await SDKInstance.post(`/data/geofence-collection/${geofenceCollectionName}/delete`, {}, {
            headers: {
                "Authorization": `Bearer ` + token,
                "x-api-key": apiKey
            }
        })

        return response;
    }
    catch (error) {
        console.log("error from delete geofence collection", error)
        return error;
    }
}

async function BatchPutGeofence(geofenceCollectionName, geofenceEntries, token, apiKey) {
    const SDKInstance = CreateSDKInstance()
    try {
        const response = await SDKInstance.post(`/data/geofence-collection/${geofenceCollectionName}/batch-put-geofence`, {
            entries: geofenceEntries
        }, {
            headers: {
                "Authorization": token,
                "x-api-key": apiKey
            }
        })

        return response;
    }
    catch (error) {
        console.log("error from batch put geofence", error)
        return error;
    }
}


async function BatchDeleteGeofence(geofenceCollectionName, geofencesToDelete, token, apiKey) {
    const SDKInstance = CreateSDKInstance()
    try {
        const response = await SDKInstance.post(`/data/geofence-collection/${geofenceCollectionName}/batch-delete-geofence`, {
            geofence_ids: geofencesToDelete
        }, {
            headers: {
                "Authorization": token,
                "x-api-key": apiKey
            }
        })

        return response;
    }
    catch (error) {
        console.log("error from batch delete geofence", error)
        return error;
    }
}


export {
    CreateGeofenceCollection,
    DeleteGeofenceCollection,
    BatchPutGeofence,
    BatchDeleteGeofence
};