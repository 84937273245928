import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
export const SubmitBugReportApi = (data, e) => {
  const endpoint = API_URL + "/feedback/bugs";
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Recording Feedback",
    allowOutsideClick: false,
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  const data1 = fetch(endpoint, {
    method: "POST",
    body: JSON.stringify({
      "feedback-content": data.Bug,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();

      Swal.fire({
        title: "Done",
        text: "Feedback Recorded",
        icon: "success",
      });
      return data;
    })
    .catch((error) => {
      Swal.fire({
        title: "Done",
        text: "Error Recording the Feedback",
        icon: "success",
      });
      console.error("Error fetching data:", error);
      return error;
    });

  return data1;
};
