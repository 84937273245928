export const ON_DEMAND_SERVICE_CALLBACK_API = `
<h1><strong><span>OUTBOUND Kinesis Stream Details</span></strong></h1>
<br>
<br>
<ul>
    <li><span class="bold">CustomExtension</span>: a complete replica of the standard payload, with added offboard-calculated details. It can be referenced as the main source.</li>
    <li><span class="bold">eventTrigger</span>: events triggering a spontaneous sent of data: They include: IGNITION_ON,  IGNITION_OFF ,  PLUGGED_IN , UNPLUGGED |  CHARGE_START |  CHARGE_COMPLETED |  CHARGE_INTERRUPTED|   CHARGE_FAULTED |  ON_DEMAND | CHARGE_SCHEDULE |POWER_PREFERENCE</li>
    <li><span class="bold">Plug Status</span>: boolean, true when Plugged;</li>
    <li><span class="bold">ChargeLevel</span>: always set on Level_2 for EMEA;</li>
    <li><span class="bold">Estimated time to charge</span>: according to level, time missing time to complete recharge;</li>
    <li><span class="bold">Range estimation</span>: according to battery level, range allowed by battery;
o	rangeEstimates.totalRange differs from rangeEstimates.estimatedElectRange only if the vehicle is hybrid, for it will report the sum between electric and fuel ranges. Otherwise, those will be identical.</li>
    <li><span class="bold">Charge status</span>: default is not charging; other possible values are “CHARGING, CHARGE_INTERRUPT , CHARGE_FAULT, CHARGE_COMPLETE, SNA";</li>
    <li><span class="bold">Battery Level</span>: State of charge of High voltage (traction) battery.</li>
    <li><span class="bold">ChargeSchedule version varies according to the Model</span>: newer ones might have V2 or V3, but its functions and generated data structures are the exact same.</li>
</ul>

<div class="json-content">
<div class="ua-chrome ProseMirror pm-table-resizing-plugin" aria-label="Main content area, start typing to enter text." aria-multiline="true" role="textbox" id="ak-editor-textarea" data-gramm="false" data-has-collab-initialised="true" translate="no">
<div class="code-block" data-drag-handler-anchor-name="--node-anchor-codeBlock-0" data-drag-handler-node-type="codeBlock" data-drag-handler-anchor-depth="0" style="--lineNumberGutterWidth: 3ch; anchor-name: --node-anchor-codeBlock-0; margin-top: 0px; position: relative; z-index: 1;"><div class="code-block--start" contenteditable="false"></div><div class="code-block-content-wrapper code-block-content-wrapper--fg"><div class="line-number-gutter--fg" contenteditable="false"></div><div class="code-content--fg"><code data-language="" spellcheck="false" data-testid="code-block--code"><span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">1</span>{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">2</span>  "EventID": " PHEVInfoResponse",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">3</span>  "Version": "2.0",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">4</span>  "Timestamp": 1454674114815,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">5</span>  "Data": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">6</span>"response": "SUCCESS | UNPLUGGED_FAILURE | FAILURE | VEHICLE_DATA_NOT_AVAILABLE",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">7</span>"eventTrigger": "IGNITION_ON | IGNITION_OFF |  PLUGGED_IN |  UNPLUGGED |  CHARGE_START |  CHARGE_COMPLETED |  CHARGE_INTERRUPTED|   CHARGE_FAULTED |  ON_DEMAND | CHARGE_SCHEDULE |POWER_PREFERENCE ",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">8</span>"plugStatus": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">9</span>  	"pluggedIn": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">10</span>  	"chargeLevel": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">11</span>    	"chargeLev<span contenteditable="false" style="position: relative;" class="ProseMirror-widget"><span class="css-149r82n" contenteditable="false" data-testid="selection-marker-anchor-cursor"></span></span>el": "DEFAULT  |  LEVEL_1  |  LEVEL_2",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">12</span>    	"estTimeToCharge": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">13</span>      	"LEVEL1": "&lt;int_value_in_sec&gt",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">14</span>      	"LEVEL2": "&lt;int_value_in_sec&gt"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">15</span>    	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">16</span>  	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">17</span>},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">18</span>"rangeEstimates": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">19</span>  	"estimatedElectRange": "&lt;int_electric_range&gt",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">20</span>  	"totalRange": "&lt;int_hybrid_range&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">21</span>},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">22</span>"chargeStatus": "NOT_CHARGING | CHARGING | CHARGE_INTERRUPT | CHARGE_FAULT | CHARGE_COMPLETE | SNA",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">23</span>"chargeSchedules": [
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">24</span>  	{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">25</span>    	"enableChargeSchedule": "DEFAULT |  ENABLE_SCHEDULE |  DISABLE_SCHEDULE",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">26</span>    	"scheduleType": " WEEKDAY |  WEEKEND",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">27</span>    	"chargeUntilFull": "&lt;boolean&gt",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">28</span>    	"scheduleStartTime": "&lt;int&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">29</span>    	"scheduleEndTime": "&lt;int&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">30</span>  	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">31</span>],
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">32</span>"chargeSchedulesV2": [
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">33</span>  	{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">34</span>    	"enableScheduleType": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">35</span>    	"scheduleType": "NONE |  CHARGE |  CLIMATE ",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">36</span>    	"startTime": "&lt;int&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">37</span>    	"endTime": "&lt;int&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">38</span>    	"chargeToFull": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">39</span>    	"cabinPriority": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">40</span>    	"scheduledDays": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">41</span>      	"sunday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">42</span>      	"monday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">43</span>      	"tuesday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">44</span>      	"wednesday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">45</span>      	"thursday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">46</span>      	"friday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">47</span>      	"saturday": "&lt;boolean&gt";
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">48</span>    	},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">49</span>    	"repeatSchedule": "&lt;boolean&gt";
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">50</span>  	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">51</span>],
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">52</span>"batteryLevel": "&lt;float_battery_level_in_percent&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">53</span>"customExtension": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">54</span>  	"response": "SUCCESS | UNPLUGGED_FAILURE | FAILURE | VEHICLE_DATA_NOT_AVAILABLE",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">55</span>  	"eventTrigger": "IGNITION_ON | IGNITION_OFF |  PLUGGED_IN |  UNPLUGGED |  CHARGE_START |  CHARGE_COMPLETED |  CHARGE_INTERRUPTED|   CHARGE_FAULTED |  ON_DEMAND | CHARGE_SCHEDULE |POWER_PREFERENCE",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">56</span>  	"plugStatus": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">57</span>    	"isPluggedIn": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">58</span>    	"chargeLevel": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">59</span>      	"chargeLevel": "DEFAULT  |  LEVEL_1  |  LEVEL_2",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">60</span>          "EstimatedTimeToCharge_LEV1": "50 minutes",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">61</span>          "EstimatedTimeToCharge_LEV2": "",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">62</span>      	"Int_EstimatedTimeToCharge_LEV1": "&lt;int_value_in_sec&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">63</span>          "Int_EstimatedTimeToCharge_LEV2": "&lt;int_value_in_sec&gt";
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">64</span>    	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">65</span>  	},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">66</span>  	"rangeEstimates": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">67</span>    	"estimatedRange": "&lt;int_electric_range&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">68</span>    	"totalRange": "&lt;int_hybrid_range&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">69</span>  	},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">70</span>  	"chargeStatus": "NOT_CHARGING | CHARGING | CHARGE_INTERRUPT | CHARGE_FAULT | CHARGE_COMPLETE | SNA",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">71</span>  	"chargeSchedules": [
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">72</span>    	{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">73</span>      	"enableChargeSchedule": "DEFAULT |  ENABLE_SCHEDULE |  DISABLE_SCHEDULE",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">74</span>      	"scheduleType": " WEEKDAY |  WEEKEND",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">75</span>      	"chargeUntilFull": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">76</span>      	"scheduleStartTime": "&lt;int&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">77</span>      	"scheduleEndTime": "&lt;int&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">78</span>    	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">79</span>  	],
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">80</span>  	"chargeSchedulesV2": [
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">81</span>    	{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">82</span>      	"EnableScheduleType": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">83</span>      	"scheduleType": "NONE |  CHARGE |  CLIMATE ",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">84</span>      	"StartTime": "&lt;int&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">85</span>      	"EndTime": "&lt;int&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">86</span>      	"ChargeToFull": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">87</span>      	"CabinPriority": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">88</span>      	"scheduledDays": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">89</span>        	"isSunday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">90</span>        	"isMonday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">91</span>        	"isTuesday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">92</span>        	"isWednesday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">93</span>        	"isThursday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">94</span>        	"isFriday": "&lt;boolean&gt;",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">95</span>        	"isSaturday": "&lt;boolean&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">96</span>      	},
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">97</span>      	"RepeatSchedule": "&lt;boolean&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">98</span>    	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">99</span>  	],
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">100</span>  	"BatteryLevel": "&lt;float_battery_level_in_percent&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">101</span>}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">102</span>  }
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">103</span>}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">104</span>or
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">105</span>{
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">106</span>   	"EventID": "PHEVResponse",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">107</span>   	"Timestamp": 1545126585878,
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">108</span>    	"Version" : "1.0",
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">109</span>   	"Data": {
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">110</span>          	"response": "&lt;GSDP Generated Negative Acknowledgement&gt;"
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">111</span>   	}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">112</span>}
<span class="code-content__line-number--wrapped ProseMirror-widget" contenteditable="false">113</span><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak"></code></div></div><div class="code-block--end" contenteditable="false"></div></div></div>
</div>
<br>
<br>
<br>
<br>
`