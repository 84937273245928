import React from 'react'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { swaggerjson } from "./swaggerjson";
import { swagger } from '../VehicleCapSidebar/swagger';
import './swagger.css'

const SwaggerComp = (props) => {
    const tag = props.tag
    const filterSpecByTag = (spec, tag) => {
      const filteredPaths = {};
      const x_path = {};
      Object.keys(spec.paths).forEach((path) => {
        let test = spec.paths[path];
        if (test.hasOwnProperty("$ref")) {
          let test1 = test["$ref"].replace("#/x-fragment/", "");
          test = spec["x-fragment"][test1];
        }

        spec.paths[path] = test;
      });

      Object.keys(spec.paths).forEach((path) => {
        const operations = spec.paths[path];
        const filteredOperations = {};
        Object.keys(operations).forEach((method) => {
          let operation = operations[method];
          if (operations.hasOwnProperty("$ref")) {
            let test1 = operation.replace("#/x-fragment/", "");
            filteredOperations[path] = operations;
            operation = spec["x-fragment"][test1];
          }
          if (operation.hasOwnProperty("tags")) {
            if (operation["tags"].includes(tag)) {
              filteredOperations[method] = operation;
            }
          }
        });

        filteredPaths[path] = filteredOperations;
      });

      return { ...spec, paths: filteredPaths, tags: [] };
    };

    const FilteredRedoc = () => {
      const filteredSpec = filterSpecByTag(props?.swaggerFile ? props.swaggerFile : swaggerjson, tag);
      return (
        <SwaggerUI spec={filteredSpec} style={{"display":"flex"}}/>
      );
    };
  return <FilteredRedoc />
}

export default SwaggerComp