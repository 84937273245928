import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
export const RequestApiCredentialsApi = (data, navigate_login) => {
  const endpoint = API_URL + "/account/register";
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Registering Account",
    // showCloseButton: true, // Hide the close button
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  const res = fetch(endpoint, {
    method: "POST",
    body: JSON.stringify({
      api_account_owner: data.apiAccountOwner,
      first_name: data.firstName,
      last_name: data.lastName,
      password: data.password,
      phone_number: data.phoneNumber,
      address: data.address,
      "usage_description": data.usageDescription,
    }),
  })
    .then((response) => {
      if (!response.ok) {
       return response.json().then((errorData) => {
        //  const icons = {403: "error", 409: "info"}
         let message
         if(typeof(errorData) != 'string'){
           message = errorData.message ? errorData.message : errorData.result
         }else{
           message = errorData
         }
         let error = new Error();
         error.text = message
         error.icon = response.status == 409 ? "info" : "error"
         error.title = response.status == 409 ? "" : "Error"
         throw error;
       });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();
      Swal.fire({
        title: "Done",
        text: data?.result ? data.result:"" + data?.message ? data.message:"",
        icon: "success",
        backdrop: "rgba(0, 0, 0, 0.85)",
      });
      navigate_login("/login")
      return data;
    })
    .catch((error) => {
      Swal.fire({
        title:error.title,
        text: error.text,
        backdrop: "rgba(0, 0, 0, 0.85)",
        icon: error.icon,
        customClass: {
          title: 'sweetalert-color',
          htmlContainer: 'sweetalert-color',
          content:'sweetalert-color'
        },
      });
      return error;
    });

  return res;
};
