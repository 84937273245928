export const getUserToken = () => {
    let token = null;
    if ("authToken" in localStorage) {
        token = localStorage.getItem("authToken")
    } else if ("authToken" in sessionStorage) {
        token = sessionStorage.getItem("authToken")
    }
    return token
}

export const getApiKey = () => {
    let apiKey = null;
    if ("ApiKey" in localStorage) {
        apiKey = localStorage.getItem("ApiKey")
    } else if ("ApiKey" in sessionStorage) {
        apiKey = sessionStorage.getItem("ApiKey")
    }
    return apiKey
}
