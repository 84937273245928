import React from "react";
import { useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import "./style.css";
import Banner from "../../components/Banner/Banner";



const VehicleCap_base = () => {
  const theme = useTheme();

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div>
        {/* <div className="banner-remote-ops">
          <img
            src="./images/vehicle_capability_banner.png"
            className="img-fluid"
            alt="Banner Image"
          />
          <div className="align-items-center d-flex text-white">
            <h1>Meet our vehicle capabilities</h1>
          </div>

         
        </div> */}
        <Banner image={"./images/vehicle_capability_banner.png"} heading={"Meet our vehicle capabilities"} />
        <div className="staticdiv">
          <iframe className="staticframe" src="./redoc-static.html"></iframe>
          </div>
      </div>
      <br />
    </>
  );
};

export default VehicleCap_base;
