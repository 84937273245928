import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
export const contactUsApi = (data) => {
  const endpoint = API_URL + "/feedback/contact-us";
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Submitting",
    // showCloseButton: true, // Hide the close button
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  let payload = {
    "first_name": data.First,
    "last_name": data.Last,
    "phone_number": data.Phone,
    "email": data.Email,
    "region": data.Region,
    "title": data.Title,
    "text": data.Description
  };

    // Remove keys with empty string values
    payload = Object.fromEntries(
            Object.entries(payload).filter(([key, value]) => value !== "")
    );

  const res = fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
       return response.json().then((errorData) => {
         let message
         if(typeof(errorData) != 'string'){
           message = errorData.message ? errorData.message : errorData.result
         }else{
           message = errorData
         }
         throw new Error(message);
       });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();
      console.log(data)

      Swal.fire({
        title: "Done",
        text: data?.result ? data.result:"" + data?.message ? data.message:"",
        icon: "success",
      });
      return data;
    })
    .catch((error) => {
      Swal.fire({
        title: "Error",
        text: error,
        backdrop: "rgba(0, 0, 0, 0.85)",
        icon: "error",
        customClass: {
          title: 'sweetalert-color',
          htmlContainer: 'sweetalert-color',
          content:'sweetalert-color'
        },
      });
      return error;
    });

  return res;
};
