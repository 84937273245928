import React from 'react'
import "./styles.css"
import { Link } from 'react-router-dom';

const processUrl = (url) => {
  const urls = url.split("/")
  var bcData = {}
  var final_url = ""
  urls.forEach(element => {
    if (element == "") {
      bcData["Home"] = "/"
    } else {
      final_url = final_url + "/" + element
      bcData[(element.charAt(0).toUpperCase()+ element.slice(1)).replaceAll("_", " ")] = final_url
    }
  });
  return bcData
}

const Banner = (props) => {
  const bannerProp = {
    ...props
  };
  if (!("subHeading" in bannerProp)) {
    bannerProp["subHeading"] = processUrl(window.location.pathname)
  }
  return (
    <div>
      <div className="banner-remote-ops">
        <img
          src={bannerProp.image}
          className="img-fluid"
          alt="Banner Image"
        />
        <div className="align-items-center text-white pt-2 banner-div">
          <h1>{bannerProp.heading}</h1>
          <p>
            {bannerProp?.subHeading && bannerProp.subHeading.constructor === Object ?
              Object.entries(bannerProp.subHeading).map(([name, url], index, array) => (
                
                <>
                  <Link to={index == (array.length - 1) ? null : url} style={{ color: 'white', textDecoration: 'none' }}><span class="bc-link">
                    {name.toLocaleLowerCase() == "faq" ? "FAQ" : name}
                  </span></Link>
                  {index < array.length - 1 && <span style={{ textDecoration: 'none' }}> / </span>}</>
              ))
              : <>{bannerProp?.subHeading ? <p>
                {bannerProp.subHeading} 
                </p> : <>

              </>}</>
            }
          </p>

        </div>


      </div>
    </div>
  )
}

export default Banner
