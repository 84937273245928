import React from "react";
// import "./register.css";
import BackGroundVideoFrame from "../../components/BackgroundVideo/BackGroundVideoFrame";
import { useForm } from "react-hook-form";
import { useState } from "react"
import './style.css'
import { RequestApiCredentialsApi } from "../RequestApiCredentials/RequestApiCedentialsApi";
import { Container } from "react-bootstrap";
// import SLogo from "../../images/stellantis_image.png";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useNavigate, useLocation } from "react-router-dom";
const Register = () => {
  const navigate_login = useNavigate()
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    usageDescription: Yup.string().required('Usage Description is required')
    .test('trim', 'Usage Description cannot be empty', value => value.trim() !== ''),
    apiAccountOwner: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required').test(
      'password-strength',
      'Password must be at least 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.',
      value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)
    ),
    
  });
  const [password, setPassword] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const validatePassword = (password) => {
    if (/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/.test(password)) {
      // if password does not meet criteria
      setError("password", {
        type: "custom",
        message:
          "Password is required and must contain at least 8 characters, including at least one of the following: uppercase letter, lowercase letter, number, and special character.",
      })
    }
    else {
      clearErrors(["password"])
    }
  }

  const evaluatePassword = (password) => {
    if (/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/.test(password)) {
      // if password meets criteria, remove error message
      setError("password", {
        type: "custom",
        message:
          "Password is required and must contain at least 8 characters, including at least one of the following: uppercase letter, lowercase letter, number, and special character.",
      })
      return false
    }else{
      clearErrors(["password"])
      return true
    }
  }

  const onSubmit = async (data) => {
    await RequestApiCredentialsApi(data, navigate_login)
    reset()
    
  }

  return (
    <>
      <div className="Register-page">
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <Container className="Register-container">
            <div style={{display: "flex", "flex-direction": "column","flex-wrap": "wrap"}}>
            <img src={'/images/stellantis_image.png'} alt="SLogo" className="img-fluid" style={{objectFit:"contain"}}/>
            </div>
            <h2 className="p-3" style={{color:"white", textAlign:"center"}}>Registration</h2>
            <div className="row">
              <div className="form-group col-lg-6 col-md-6">
                <label for="apiAccountOwner">
                  Stellantis Email{" "}
                  <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control small-input ${errors.apiAccountOwner ? 'is-invalid' : ''}`}
                  name="apiAccountOwner"
                  placeholder="Enter Stellantis Email "
                  {...register("apiAccountOwner")}
                />
                 <div className="invalid-feedback">{errors.apiAccountOwner?.message}</div>
                {/* {errors?.apiAccountOwner && (
                  <p className="error-message">{errors.apiAccountOwner.message}</p>
                )} */}
              </div>
              <div className="form-group col-lg-6 col-md-6">
              <label for="password">
                  Stellantis Password{" "}
                  <span className="required-star">*</span>
                </label>
              <input
                type="password"
                className={`form-control small-input ${errors.password ? 'is-invalid' : ''}`}
                name="password"
                placeholder="Enter a Password"
                {...register("password")}
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
            </div>
            <div className="row">
              <div className="form-group col-lg-6 col-md-6">
                <label for="firstName">
                  First Name <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control small-input ${errors.firstName ? 'is-invalid' : ''}`}
                  name="firstName"
                  placeholder="Enter First Name"
                  {...register("firstName")}
                />
               <div className="invalid-feedback">{errors.firstName?.message}</div>
              </div>
              <div
                className="form-group col-lg-6 col-md-6"
                style={{ "margin-bottom": "1rem" }}
              >
                <label for="lastName">
                  Last Name <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control small-input ${errors.lastName ? 'is-invalid' : ''}`}
                  name="lastName"
                  placeholder="Enter Last Name"
                  {...register("lastName")}
                />
                  <div className="invalid-feedback">{errors.lastName?.message}</div>
              </div>
            </div>

            <div className="row">
              <div
                className="form-group col-lg-12"
                style={{ "margin-bottom": "1rem" }}
              >
                <label for="usageDescription">
                  Usage Description <span className="required-star">*</span>
                </label>
                <textarea
                  type="text"
                  className={`form-control small-input ${errors.usageDescription ? 'is-invalid' : ''}`}
                  name="usageDescription"
                  placeholder="Enter Usage Description"
                  {...register("usageDescription")}
                ></textarea>
                  <div className="invalid-feedback">{errors.usageDescription?.message}</div>
              </div>
            </div>
            <button
              id="loading-button"
              className="btn btn-primary mt-2"
              type="button"
              disabled
              style={{ display: "none", "margin-left": " 0.5rem" }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
            <button type="submit" className="signin-button" variant="light" disabled={Object.keys(errors).length > 0}>
              Submit
            </button>
          </Container>
        </form>
      </div >


    </>
  );
};

export default Register;
