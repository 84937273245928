import React from 'react'
import { useForm } from "react-hook-form";
import { GeneralSupportAPI } from './GeneralSupportAPI';

const GeneralSupport = () => {

    const { register, handleSubmit } = useForm();

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
            <h2 style={{ color: "rgb(50, 50, 159);" }}>General Support</h2>
            <h5>
              Submit general support questions to Stellantis Developers
            </h5>
            <form onSubmit={handleSubmit(GeneralSupportAPI)}>
              <div class="form-group">
                <label for="support-content">
                  Support Request<span className="required-star">*</span>
                </label>
                <textarea
                  required
                  className="form-control"
                  id="support-content"
                  {...register("support")}
                  placeholder="Enter your query"
                ></textarea>
              </div>

              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}



export default GeneralSupport