import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
import { getUserToken, getApiKey } from "../../utils/utility";

export const UnlinkApiCallback = (data) => {
  const endpoint = API_URL + "/account/unlink";
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Unlinking Account",
    allowOutsideClick: false,
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  const data1 = fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUserToken()}`,
      "X-Api-Key": getApiKey(),
    },
    body: JSON.stringify({
      connected_account_email: data.Email,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          throw errorData.message;
        });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();

      Swal.fire({
        text: data?.result
          ? data.result
          : "" + data?.message
          ? data.message
          : "",
        icon: "success",
        backdrop: "rgba(0, 0, 0, 0.85)",
        customClass: {
          title: "sweetalert-color", // Apply custom styles to the title
          content: "sweetalert-color", // Apply custom styles to the content (text)
        },
      });
      return data;
    })
    .catch((error) => {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "Error",
        backdrop: "rgba(0, 0, 0, 0.85)",
        customClass: {
          title: "sweetalert-color", // Apply custom styles to the title
          content: "sweetalert-color", // Apply custom styles to the content (text)
        },
      });
      console.error("Error fetching data:", error);
      return error;
    });

  return data1;
};
