
import React, { Suspense } from 'react';
import Banner from '../../components/Banner/Banner'
import "./styles.css"
import { Backdrop, CircularProgress } from '@mui/material';
const EXPCatalogue = React.lazy(() => import('../../components/EXPCatalogue/EXPCatalogue'));
const EXPDATACATALOGUE = await fetch('/Exp_data_catalogue.json').then(res => res.json())
const EXP_DataCatalogue = () => {
    const [Search, setSearch] = React.useState("")
    const [dataCatalogueState, setDataCatalogueState] = React.useState(false)
    const [dataCatalogueData, setDataCatalogueData] = React.useState({})
    
    const ShowDataCatalogue = (data, signalName)=>{
        data["signalName"] = signalName
        setDataCatalogueState(true)
        setDataCatalogueData(data)

    }
    
    return (
        <div>
            <Banner image={"/images/Mobilisights-button-data-stream.png"} heading={"Stellantis for Mobilisights Data Catalogue"} />
            <br />
            <div className='page_box_exp'>
                <div className='box_container_exp'>
                <Suspense fallback={
              <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}>

                    <EXPCatalogue Catalogue={EXPDATACATALOGUE} />
                    </Suspense>
                </div>
            </div>

        </div>
    )
}

export default EXP_DataCatalogue;