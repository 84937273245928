import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Banner from '../../components/Banner/Banner';
import { FAQ } from '../../constant/FAQ';
 
 
const FAQPage  = () => {
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    return (
        <>
          <div>
            <Banner image={"/images/contact_us_and_faq_pages_banner.png"} heading={"FAQ"} />
          </div>
          <div className='page_box' style={{ marginLeft: "4%", paddingBottom: "1rem" }}>
            <div className='box_container'>
                {Object.keys(FAQ).map((section, sectionIndex) => {
                    return (
                        <div id={sectionIndex} style={{ marginBottom: "2.5rem" }}>
                            <h2 style={{ fontSize: "25px", marginBottom: "1.5rem", fontWeight: "200" }}>{section}</h2>
                            {FAQ[section].details.map((detail, index) => {
                                return (
                                  <div>
                                    <Accordion
                                      key={index}
                                      style={{
                                        backgroundColor: "#f2f2f2",
                                        margin: "8px",
                                        marginLeft: "0px",
                                        borderRadius: "25px",
                                        marginBottom:"23px",
                                        boxShadow: "#c3c3c3 0px 5px 2px -2px",
                                        '&:before': {
                                            display: 'none',
                                            height: '0px'
                                        }
                                      }}
                                      onChange={handleChange(`${section}-${index}`)}
                                      expanded={expanded === `${section}-${index}`}
                                    >
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          expandIcon={expanded === `${section}-${index}` ? <ChevronLeftIcon /> : <ExpandMore />}
                                        >
                                            <div style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: detail.question }} />
                                        </AccordionSummary>
                                        <AccordionDetails style={{ paddingTop: "0" }}>
                                            <div style={{ color: "#616161", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: detail.answer }} />
                                        </AccordionDetails>
                                    </Accordion>
                                  </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
          </div>
        </>
      );
}
export default FAQPage