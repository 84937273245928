import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Subscription from "./pages/Subscription/Subscription.jsx";
import LoginPage from "./pages/Login/Login.jsx";
import SubmitBugReport from "./pages/SubmitBugReport/SubmitBugReport";
import GetAccountDetails from "./pages/GetAccountDetails/GetAccountDetails";
import GeneralFeedback from "./pages/GeneralFeedback.jsx/GeneralFeedback";
import GeneralSupport from "./pages/GeneralSupport/GeneralSupport";
import SendRemoteCommand from "./pages/SendRemoteCommand/SendRemoteCommand";
import RequestApiCredentials from "./pages/RequestApiCredentials/RequestApiCredentials";
import LinkVehicleAccount from "./pages/LinkVehicleAccount/LinkVehicleAccount";
import UnlinkAccount from "./pages/UnlinkAccount/UnlinkAccount";
import UpdateAccountPassword from "./pages/UpdateAccountPassword/UpdateAccountPassword";
import ResetAccountPassword from "./pages/ResetAccountPassword/ResetAccountPassword";
import PermanentlyDeleteAccount from "./pages/PermanentlyDeleteAccount/PermanentlyDeleteAccount";
import LastKnownDataVIN from "./pages/LastKnownDataVIN/LastKnownDataVIN";
import RecentSensorDataValues from "./pages/RecentSensorValues/RecentSensorDataValues";
import React, { Suspense, useContext } from 'react';

import GeofenceCollections from "./pages/Geofencing/GeofenceCollections";
import GeofenceCollection from "./pages/Geofencing/GeofenceCollection.tsx";
import ApprovePage from "./pages/ApprovePage/ApprovePage";
import ProtectedRoute from "./utils/ProtectedRoute";
import Register from "./pages/Register/Register";
import ChangeTempPassword from "./pages/ChangeTempPassword/ChangeTempPassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import SendPopup from "./pages/SendPopup/SendPopup";
import DocsSwagger from "./pages/Docs/DocsSwagger.jsx";
import Firstpage from "./pages/brand_features/firstpage.jsx";
import Remote_ops from "./pages/Remote_ops/Remote_ops.jsx";
import Explore from "./pages/ExplorePage/ExplorePage.jsx";
import HomePage from "./pages/HomePage/HomePage.jsx"
import FAQPage from "./pages/FAQPage/FAQPage.jsx";


import "./App.css";
import Navigationbar from "./components/Navbar";
import VehicleCap_base from "./pages/VehicleCap/VehicleCap_base.jsx";
import VehicleSSDPCapabilities from "./pages/VehicleCapabilities/VehicleCapability.jsx";
import TripReport_Feature from "./pages/tripReport_feature/TripReport_Feature.jsx";
import Slimulator from "./pages/Slimulator/Slimulator.jsx";
import Mobilisight_Page from "./pages/Mobilisite_docs/Mobilisight_Page.jsx";
import Mobilisight_Home_Page from "./pages/Mobilisite_docs/Mobilisight_Home_Page/Mobilisight_Home_Page"
import DataCatalogue from "./pages/DataCatalogue_Mobilisight/DataCatalogue.jsx";
import FCA_API from "./pages/FCA_API/FCA_API.jsx";
import OnboardPage from "./pages/OnboardPage/OnboardPage.jsx";
import ExPDataCatalogue from "./pages/EXP_DataCatalogue_Mobilisight/EXP_DataCatalogue.jsx"

import Footer from "../src/components/Footer.jsx";
import Profile from "./pages/Profile/Profile.jsx";
import ContactUS from "./pages/ContactUS/ContactUS.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import PageNotFound from "./pages/PageNotFound/PageNotFound.jsx";
const MainTab = React.lazy(() => import('./components/CatalogueTabSection/MainTab.jsx'));


function App() {
 
  return (
    <Router>
      <Navigationbar />
      <Suspense fallback={
              <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}>
      <>
      
      <div style={{background:"white", zIndex:"2", position:"relative"}}>
      
      
        <Routes>
          <Route exact path="/docs" Component={DocsSwagger} />
          <Route exact path="/Login" Component={LoginPage} />
          <Route path="*" element={<PageNotFound />} />
          <Route exact path="/" Component={HomePage} />
          <Route exact path="/brand_features" Component={Firstpage} />
          <Route exact path="/Remote_ops" Component={Remote_ops} />
          <Route exact path="/TripReport" Component={TripReport_Feature} />

          <Route exact path="/register" Component={Register} />
          <Route exact path="/plans" Component={Subscription} />
          <Route exact path="/capabilities" Component={VehicleCap_base} />
          <Route exact path="/ssdp_capabilities" Component={VehicleSSDPCapabilities} />
          <Route exact path="/Explore" Component={Explore} />
          <Route exact path="/Contact-Us" Component={ContactUS} />
          <Route exact path="/faq" Component={FAQPage} />
          <Route
            exact
            path="/Explore/Mobilisight"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Explore/Mobilisight"} addHeaders={false}>
                <Mobilisight_Home_Page />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Explore/Mobilisight/MobilisightexFAPIs"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Explore/Mobilisight/MobilisightexFAPIs"} addHeaders={false}>
                <Mobilisight_Page />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Profile"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Profile"} addHeaders={false}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Explore/Mobilisight/exFDataCatalogue"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Explore/Mobilisight/exFDataCatalogue"} addHeaders={false}>
                <DataCatalogue />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Explore/Mobilisight/exPDataCatalogue"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Explore/Mobilisight/exPDataCatalogue"} addHeaders={false}>
                <ExPDataCatalogue />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Explore/OnboardCatalogue"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Explore/OnboardCatalogue"} addHeaders={false}>
                <OnboardPage />
              </ProtectedRoute>
            }
          />
         
          <Route
            exact
            path="/Explore/OnboardCatalogue/*"
            element={
              <ProtectedRoute redirectPath={`/login?redirect=${window.location.pathname}`} addHeaders={false}>
                <MainTab />
              </ProtectedRoute>
            }
          />
          <Route exact path="explore/FCA_Vehicle_API" Component={FCA_API} />
          <Route
            exact
            path="/ChangeTempPassword"
            Component={ChangeTempPassword}
          />
          <Route exact path="/ForgotPassword" Component={ForgotPassword} />


          <Route
            exact
            path="/services/account/accountDetails"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/services/account/accountDetails"}>
                <GetAccountDetails />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Slimulator"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Slimulator"}>
                <Slimulator />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/feedback/submit-bug-report"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/feedback/submit-bug-report"}>
                <SubmitBugReport />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/feedback/submit-general-feedback"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/feedback/submit-general-feedback"}>
                <GeneralFeedback />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/feedback/submit-support-request"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/feedback/submit-support-request"}>
                <GeneralSupport />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/remote-commands/send-remote-commands"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/remote-commands/send-remote-commands"}>
                <SendRemoteCommand />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/remote-commands/send-popup"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/remote-commands/send-popup"}>
                <SendPopup />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Services/account/Request-API-Credentials"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Services/account/Request-API-Credentials"}>
                <RequestApiCredentials />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Services/account/link-api-account"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Services/account/link-api-account"}>
                <LinkVehicleAccount />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Services/account/UnLink-api-account"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Services/account/UnLink-api-account"}>
                <UnlinkAccount />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Services/account/UpdateAccountPassword"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Services/account/UpdateAccountPassword"}>
                <UpdateAccountPassword />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Services/account/ResetPassword"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Services/account/ResetPassword"}>
                <ResetAccountPassword />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/Services/account/PermanentlyDeleteAccount"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/Services/account/PermanentlyDeleteAccount"}>
                <PermanentlyDeleteAccount />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/services/vehicle-data/LastKnownDataVIN"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/services/vehicle-data/LastKnownDataVIN"}>
                <LastKnownDataVIN />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/services/vehicle-data/RecentSensorValues"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/services/vehicle-data/RecentSensorValues"}>
                <RecentSensorDataValues />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/services/geofencing/geofence-collections"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/services/geofencing/geofence-collections"}>
                <GeofenceCollections />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/geofencing/geofence-collections/*"
            element={
              <ProtectedRoute redirectPath={`/login?redirect=${window.location.pathname}`}>
                <GeofenceCollection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/account/approve_account"
            element={
              <ProtectedRoute redirectPath={"/login?redirect=/services/account/approve_account"}>
                <ApprovePage />
              </ProtectedRoute>
            }
          />
          
        </Routes>
        </div>
      </>
      <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
