import React from 'react'
import { useForm } from "react-hook-form";
import { UnlinkApiCallback } from './UnlinkApiCallback';


const UnlinkAccount = () => {
      const { register, handleSubmit } = useForm();

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6  mt-4">
            <h2 style={{ color: "rgb(50, 50, 159);" }}>
              Unlink Vehicle Account
            </h2>
            <h5>Unlink an API account to your Connected Vehicle account</h5>
            <form onSubmit={handleSubmit(UnlinkApiCallback)}>
              <div class="form-group">
                <label for="connectedAccountEmail">
                  Connected Account Email <span class="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  class="form-control"
                  id="connectedAccountEmail"
                  {...register("Email", { required: "Email is required" })}
                  placeholder="Enter connected account email"
                />
              </div>
             
              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

     
    </>
  );
}

export default UnlinkAccount