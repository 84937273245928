import React from "react";
import { useForm } from "react-hook-form";
import { SendRemoteAPI } from "./SendRemoteAPI";
const SendRemoteCommand = () => {
  const { register, handleSubmit } = useForm();

  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-12 custom-md6-width  mt-2"
            style={{ "max-width": "50%" }}
          >
            <h2 style={{ color: "rgb(50, 50, 159);" }}>
             Send Remote Command
            </h2>
            <h5>Send Remote Command</h5>
            <form onSubmit={handleSubmit(SendRemoteAPI)}>
              <div className="form-group">
                <label for="vin">
                  VIN Number<span className="required-star">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="vin"
                  placeholder="Enter Vehicle Identification Number"
                  {...register("VIN")}
                />
              </div>
              <div className="form-group">
                <label for="pin">
                  Pin<span className="required-star">*</span>
                </label>
                <input
                  required
                  type="password"
                  className="form-control"
                  id="pin"
                  placeholder="Enter Pin"
                  {...register("PIN")}
                />
              </div>
              <div className="form-group">
                <label for="command">
                  Command<span className="required-star">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="command"
                  placeholder="Enter Command"
                  {...register("command")}
                />
              </div>
              
              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendRemoteCommand;
