import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
import { getUserToken, getApiKey } from "../../utils/utility";

export const DeleteAcountApiCallback = () => {
  const endpoint = API_URL + "/account/delete";
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Deleting Account",
    allowOutsideClick: false,
    willOpen: () => {
      Swal.showLoading();
    },
  });
  const data = fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      "x-api-key": getApiKey(),
    }
  })
    .then((response) => {

      if (!response.ok) {
        return response.json().then((errorData) => {
          throw (errorData.message);
        });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();
      const successWal = Swal.fire({
        title: "Done",
        text: "Account Deleted",
        icon: "success",
      });
      setTimeout(() => {
        successWal.close();
        return data;
      }, 3000);
    })
    .catch((error) => {
      Swal.fire({
        title: "Error",
        text: "Error deleting the Account",
        icon: "error",
      });
      return error;
    });

};
