import Swal from "sweetalert2";
import { API_URL } from "../../constant/APIConstants";
import { getUserToken,getApiKey } from "../../utils/utility";

export const SendRemoteAPI = (data) => {
  const endpoint = API_URL + `/remote/${data.VIN}`;
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Executing Command",
    allowOutsideClick: false,
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  const data1 = fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      "x-api-key": getApiKey(),
    },
    body: JSON.stringify({
      "command": data.command,
      "pin": data.PIN,
    }),
  })
    .then((response) => {
      
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close();

      Swal.fire({
        title: "Done",
        text: data?.result ? data.result:"" + data?.message ? data.message:"",
        icon: "success",
      });
      return data;
    })
    .catch((error) => {
      Swal.fire({
        title: "Done",
        text: "Command Executed",
        icon: "success",
        backdrop: "rgba(0, 0, 0, 0.85)",
        customClass: {
          title: "sweetalert-color",
          content: "sweetalert-color",
        },
      });
      console.error("Error fetching data:", error);
      return error;
    });

  return data1;
};
