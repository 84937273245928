import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { jsondata } from './SlimulatorApi';
import { API_URL } from "../../constant/APIConstants";

const Slimulator = () => {
    const [search, setSearch] = useState("")
    const [SimVin, setSimVin] = useState("")
    const { loading, UserData, error, success } = useSelector(
        (state) => state.UserData
    );


    useEffect(() => {
        if (UserData?.owned_vins) {
            let simvinKeys = [];

            for (let key in UserData.owned_vins) {
                if (key.startsWith("SIMVIN")) {
                    simvinKeys.push(key);
                }
            }
            setSimVin(simvinKeys)

        }
    }, [UserData])
    useEffect(() => {
        console.log(SimVin)
        setSelectedSimvin(SimVin[0])
    }, [SimVin])
    const [formData, setFormData] = useState(jsondata);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: String(value)
        });

    };



    const [SelectedSimvin, setSelectedSimvin] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        const endpoint = `${API_URL}/data/${SelectedSimvin}/sim_update`;
        const loadingSwal = Swal.fire({
            title: "sending payload...",
            text: "Sending payload",
            allowOutsideClick: false,
            showConfirmButton: false,
            backdrop: "rgba(0, 0, 0, 0.85)",
            willOpen: () => {
                Swal.showLoading();
            },
            customClass: {
                title: "sweetalert-color",
                content: "sweetalert-color",
            },
        });
        const payload_dynamic = JSON.stringify({
            "timestamp": Date.now(),
            "data": [
                {
                    "values": formData
                }
            ]
        })
        const data1 = fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "X-Api-Key": localStorage.getItem("ApiKey"),
            },
            body: JSON.stringify(JSON.parse(payload_dynamic)),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }
                return response.json();
            })
            .then((data) => {
                loadingSwal.close();

                Swal.fire({
                    title: "Done",
                    text: "Payload Sent Successfully",
                    icon: "success",
                });
                return data;
            })
            .catch((error) => {
                Swal.fire({
                    title: "Done",
                    text: "Error Sending the payload",
                    icon: "error",
                });
                console.error("Error fetching data:", error);
                return error;
            });

        return data1;

    }

    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
                        <h2 style={{ color: "rgb(50, 50, 159);" }}>Slimulator</h2>
                        <h5>
                            Insert the payload
                        </h5>
                        {SimVin.length !== 0 ? (
                            <>
                                <select
                                    required
                                    className="form-control"
                                    id="simvinSelect"
                                    value={SelectedSimvin}
                                    onChange={(e) => setSelectedSimvin(e.target.value)}
                                >
                                    <option value="" disabled>Select SIMVIN</option>
                                    {SimVin.map((key) => (
                                        <option key={key} value={key}>
                                            {key}
                                        </option>
                                    ))}
                                </select>

                                <br/>
                                <br/>

                                <label for="search">
                                            Search Signal<span className="required-star">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            id="payload"
                                            placeholder="search signals"
                                            onChange={(e) => setSearch(e.target.value)}
                                        ></input>

                                <form onSubmit={handleSubmit}>
                                    <div class="form-group">
                                        {Object.keys(formData)
                                        .filter(key => key.includes(search))
                                        .map((key) => (
                                            <div key={key} className='m-3'>
                                                <label htmlFor={key}>{key}</label>
                                                <input
                                                    type="text"
                                                    id={key}
                                                    name={key}
                                                    className='form-control'
                                                    value={formData[key]}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        ))}
                                        
                                    </div>

                                    <div class="form-group text-center">
                                        <button type="submit" class="btn btn-primary btn-block">
                                            Submit
                                        </button>
                                    </div>
                                </form></>) : <h2>No SimVin Associated</h2>}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Slimulator
