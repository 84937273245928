import React from 'react'
import { Link } from 'react-router-dom';
import './styles.css'
const BrandCard = (props) => {
  console.log(props)
   return (
     <div className="brand-card col-lg-4 col-md-6 col-sm-12">
       <div className="mt-3 p-3">
         <div className="card">
           <div class="card-header">
             <img src={props.image} />
           </div>
           <div className="card-body">
             <h2
               className="card-title pt-4"
                dangerouslySetInnerHTML={{ __html: props.heading }}
             ></h2>
             <p className="card-text pt-2 pb-3">{props.text}</p>
             <br />
             <Link
               className="more-details btn btn-block btn-lg p-1 mb-3 mt-1 btn-primary"
               to={props.link}
             >
               More Details
             </Link>
           </div>
         </div>
       </div>
     </div>
   );
}

export default BrandCard