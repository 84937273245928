import { API_URL } from "../../constant/APIConstants";
import Swal from "sweetalert2";
import { getUserToken, getApiKey } from "../../utils/utility";

export const RecentSensorValueAPI = (VIN) => {
  const loadingSwal = Swal.fire({
    title: "Loading...",
    text: "Fetching Data",
    allowOutsideClick: false,
    showConfirmButton: false,
    backdrop: "rgba(0, 0, 0, 0.85)",
    willOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      title: "sweetalert-color",
      content: "sweetalert-color",
    },
  });
  const endpoint = `${API_URL}/data/${VIN}`;
  const res = fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      "x-api-key": getApiKey(),
    },
  })
    .then((response) => {
      
      if (!response.ok) {
        return response.json().then((errorData) => {
          throw errorData.message;
        });
      }
      return response.json();
    })
    .then((data) => {
      loadingSwal.close()
      if (data?.message) {
        throw data.message
      }
      return data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
       Swal.fire({
         title: "Error",
         text: error,
         icon: "error",
       });
      return error;
    });

  return res;
};
