import { getUserToken } from "./utility";

export const checkUserToken = () => {
  const token = getUserToken();
  try {
     if (!token) {
       return false;
     }
     const decode = JSON.parse(atob(token.split(".")[1]));


     if (decode.exp * 1000 < new Date().getTime()) {
       return false;
     } else {
      return true;
     }
  } catch(error) {
    return false
  }
  
}