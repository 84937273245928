import React from 'react'
import { useForm } from 'react-hook-form';
import { ResetAccountApiPassword } from './ResetAcountApiCallback';
import { Container } from "react-bootstrap";
// import SLogo from "../../images/stellantis_image.png";
import '../Register/style.css'
const ResetAccountPassword = () => {

  const { register, handleSubmit } = useForm();

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 mt-4" style={{ "max-width": "50%" }}>
            <h2 style={{ color: "rgb(50, 50, 159);" }}>Reset password</h2>
            <h5>Reset password using Email</h5>
            <form onSubmit={handleSubmit(ResetAccountApiPassword)}>
              <div class="form-group">
                <label for="Email">
                  Username/Email <span class="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  class="form-control"
                  id="Email"
                  {...register("Email", { required: "Email is required" })}
                  placeholder="Enter Stellantis Email"
                />
              </div>

              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetAccountPassword