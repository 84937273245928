import React from 'react'
import { useState, useEffect } from 'react';
import BrandCard from '../../components/BrandCards/BrandCard';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import './style.css'
import SwaggerComp from '../../components/swagger/SwaggerComp';
import { Link } from 'react-router-dom';
import Banner from '../../components/Banner/Banner';

const TripReport_Feature = () => {
  const theme = useTheme();

  const [value, setValue] = useState('1')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div>
        <Banner
          image={"./images/software-development-button.jpg"}
          heading={"Meet our vehicle capabilities"} />
        <div className="container-fluid">
          <div className="container-fluid feature-content">
            <Box sx={{
              width: "100%",
              typography: "body1",
              paddingLeft: "5%",
              paddingRight: "5%"
            }}>

              <div className="row firstContainer">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-9">
                      <h2>Map generation of the trips</h2>
                      <p className='mt-3'>
                        Trip report automatically generates a comprehensive map depicting the entire journey's route. Within this map, users can locate notable events such as instances of hard braking, sharp cornering, and abrupt turning, in addition to receiving drive alerts. These features provide users with valuable insights into their driving habits and the overall safety of their journey. The app's ability to dynamically plot these events on the map enhances the user experience by offering visual representations of driving behavior throughout the trip. By incorporating these elements into the map interface, users can easily identify areas where they may need to improve their driving technique or where additional caution may be warranted. Overall, the app's mapping functionality serves as a valuable tool for promoting safer and more conscientious driving practices
                      </p>
                    </div>
                    <div class="col-sm-3">
                      <img
                        src="./images/map-color.png"
                        className="img-fluid float-right discription-image"
                        alt="Image in Div 3"
                      />
                    </div>
                  </div>
                  <br />
                  <br />

                  <div class="row">
                    <div class="col-sm-5 d-flex align-items-center">
                      <img
                        src="./images/trip-report-data.png"
                        className="img-fluid float-left discription-image"
                        alt="Image in Div 3"
                      />
                    </div>
                    <div class="col-sm-7">
                      <h2>Trip Data and Statistics</h2>
                      <p className='mt-3'>
                      Trip report utilizes vehicle-collected data to generate comprehensive insights. This includes trip distance, duration, start time, end time, and additional pertinent information. Users can expect access to a wealth of data that offers detailed analysis of their trips. By leveraging information gathered directly from the vehicle, the app ensures accuracy and reliability in its generated reports. This data-driven approach empowers users to gain valuable insights into their driving patterns and behaviors. Furthermore, users can anticipate a user-friendly interface that facilitates easy access to this wealth of information. Whether it's reviewing past trips or analyzing driving habits over time, the app's ability to generate and present data effectively enhances the overall user experience. Ultimately, the app's utilization of vehicle-collected data enables users to make informed decisions and improvements in their driving practices
                      </p>
                    </div>
                  </div>
                  <br />
                  <br />

                  <div class="row d-flex align-items-center">
                    <div class="col-sm-7">
                      <h2>Economy managing</h2>
                      <p className='mt-3'>
                      Through the utilization of both vehicle fuel/energy consumption data and user-provided pricing information, trip report possesses the capability to compute the total cost of the entire trip. By factoring in these key variables, users can obtain accurate estimations of their travel expenses. This functionality streamlines the process of budgeting for trips, providing users with valuable financial insights. The app's ability to calculate trip costs based on real-time fuel/energy usage and user-defined pricing enhances its practicality and utility. Users can rely on this feature to make informed decisions regarding their travel expenditures, ensuring better financial planning and management. Additionally, the app's seamless integration of these data points contributes to a user-friendly experience, where accessing trip cost estimates is intuitive and efficient. Overall, this feature represents a valuable asset for users seeking to understand and manage the financial aspects of their journeys effectively.
                      </p>
                    </div>
                    <br />
                    <br />

                    <div class="col-sm-5">
                      <img
                        src="./images/economy-trip.png"
                        className="img-fluid float-right discription-image"
                        alt="Image in Div 3"
                      />
                    </div>
                  </div>
                </div>
              </div>
             
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />

    </>
  );
}

export default TripReport_Feature