export const CATALOGUE = {
  "Vehicle Data": {
    "Engine oil life": {
      "tbm_data": {
        "V2C Signal Id": 10000,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 1400,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 4",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 2,
      "Sent": "",
      "Collection ID": 44,
      "CollectionsName": "SERVICE",
      "SignalID": "10000A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = None\n1 = Change oil soon\n2 = Change oil now\n3 = SNA",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "SERVICE_Scheduled maintenance (service) - OilLife (SubID, Valore, uom)"
    },
    "Acceleration along X (internal)": {
      "tbm_data": {
        "V2C Signal Id": 1000,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m/s^2",
        "Range": "[-20; 20] TBC",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 57,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "m/s^2",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 3,
      "Sent": "X",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "1000A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Acceleration along Y (internal)": {
      "tbm_data": {
        "V2C Signal Id": 1001,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m/s^2",
        "Range": "[-20; 20] TBC",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 58,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "m/s^2",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 4,
      "Sent": "X",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "1001A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Acceleration along Z (internal)": {
      "tbm_data": {
        "V2C Signal Id": 1002,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m/s^2",
        "Range": "[-20; 20] TBC",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 59,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "m/s^2",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 5,
      "Sent": "X",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "1002A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "TBM Status": {
      "tbm_data": {
        "V2C Signal Id": 10200,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "TRUE/FALSE",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID1, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 6,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "10200A",
      "TBM": "X",
      "MC": "",
      "Meaning": "TRUE/FALSE",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "False -> 0\nTrue -> 1",
      "Data Objects": ""
    },
    "Theft alarm activation": {
      "tbm_data": {
        "V2C Signal Id": 10400,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 7",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 29,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 7",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 7,
      "Sent": "X",
      "Collection ID": 47,
      "CollectionsName": "THEFT",
      "SignalID": "10400A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = disarm\n1 = prearm\n2 = armed\n3 = armed lights only\n4 = arm lights and horn\n5 = tamper alert\n6 = ITM alarm\n7 = SNA",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "THEFT_Volumetric sensor (SubID, Valore, uom)"
    },
    "positive lateral acceleration over-threshold events counter": {
      "tbm_data": {
        "V2C Signal Id": 105,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Event Counter Matrix",
        "Dataset ID": "ID8",
        "Available": "Yes",
        "TBM Sending Frequency": "Key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 8,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "105A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Lat Acceleration (SubID, Valore, uom)"
    },
    "negative logitudinal acceleration over-threshold events counter": {
      "tbm_data": {
        "V2C Signal Id": 106,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Event Counter Matrix",
        "Dataset ID": "ID8",
        "Available": "Yes",
        "TBM Sending Frequency": "Key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 9,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "106A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Long Acceleration (SubID, Valore, uom)"
    },
    "negative lateral acceleration over-threshold events counter": {
      "tbm_data": {
        "V2C Signal Id": 107,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Event Counter Matrix",
        "Dataset ID": "ID8",
        "Available": "Yes",
        "TBM Sending Frequency": "Key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 10,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "107A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Lat Acceleration (SubID, Valore, uom)"
    },
    "positive longitudinal acceleration over-threshold events counter": {
      "tbm_data": {
        "V2C Signal Id": 108,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Event Counter Matrix",
        "Dataset ID": "ID8",
        "Available": "Yes",
        "TBM Sending Frequency": "Key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 11,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "108A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Long Acceleration (SubID, Valore, uom)"
    },
    "negative vertical acceleration over-threshold events counter": {
      "tbm_data": {
        "V2C Signal Id": 109,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Event Counter Matrix",
        "Dataset ID": "ID8",
        "Available": "Yes",
        "TBM Sending Frequency": "Key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 12,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "109A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Vertical (SubID, Valore, uom)"
    },
    "positive vertical acceleration over-threshold events counter": {
      "tbm_data": {
        "V2C Signal Id": 110,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Event Counter Matrix",
        "Dataset ID": "ID8",
        "Available": "Yes",
        "TBM Sending Frequency": "Key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 14,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "110A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Vertical (SubID, Valore, uom)"
    },
    "Wake-up acceleration": {
      "tbm_data": {
        "V2C Signal Id": 111,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "g",
        "Range": "",
        "Dataset Type": "WA",
        "Dataset ID": "ID5",
        "Available": "Yes",
        "TBM Sending Frequency": "CRASH at key off"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 15,
      "Sent": "",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "111A",
      "TBM": "X",
      "MC": "",
      "Meaning": "",
      "Target uom": "g",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Report Field": {
      "tbm_data": {
        "V2C Signal Id": 112,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "- 'notification' \n- 'report' \n- 'crash'\n- 'accOverthreshold'\n- 'wakeup'",
        "Dataset Type": "MSD, Additional Data, EDR, Histogram, Wakeup Acceleration",
        "Dataset ID": "ID1",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 16,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "112A",
      "TBM": "X",
      "MC": "",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Intervention fuel cut system": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 700,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 21,
      "Sent": "",
      "Collection ID": 36,
      "CollectionsName": "FUEL",
      "SignalID": "12M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "FUEL_Intervention fuel cut system (SubID, Valore, uom)"
    },
    "Device Calibration": {
      "tbm_data": {
        "V2C Signal Id": 13,
        "Multiplication Factor": 1.0,
        "Format": "boolean",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 56,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change\nCRASH"
      },
      "row_no": 22,
      "Sent": "",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "13A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "False -> 0\nTrue -> 1",
      "Data Objects": ""
    },
    "Power steering failure": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 10,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0; 1; 7",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 23,
      "Sent": "",
      "Collection ID": 42,
      "CollectionsName": "STEERING",
      "SignalID": "13M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "STEERING_Power steering failure (SubID, Valore, uom)"
    },
    "Belt fault light": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 21,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0, 1, 2, 3, 7",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 24,
      "Sent": "X",
      "Collection ID": 43,
      "CollectionsName": "SEATS&BELTS",
      "SignalID": "14M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Seat belt fastened/Buckled\n1 = Seatbelt not fastened/Unbuckled\n2 = Seatbelt shortedlow/LOW\n3 = Seatbelt shorted high/HIGH\n7 = Signal not available/SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "SEATS&BELTS_Belt Status (SubID, Valore, uom)"
    },
    "Key/Ignition status TBM": {
      "tbm_data": {
        "V2C Signal Id": 16,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0;3;4;5;7",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 26,
      "Sent": "X",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "1600A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = IGN_LK\n1 = IGN_LK (addedd to manage an Ignite bug)\n3 = ACC\n4 = RUN\n5 = START\n6 = Initialization\n7 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Key/Ignition status MC": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2600,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0;3;4;5;7;8;9;10;11",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 27,
      "Sent": "X",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "16A",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = IGN_LK\n3 = ACC\n4 = RUN\n5 = START\n6 = Initialization\n7 = SNA\n8 = Stop\n9 = Park\n10 = Pw_On\n11 = Crank_On",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "NA as signal. To be provided by GSDP based on what read on V2C API",
      "Data Objects": ""
    },
    "Average acceleration along X": {
      "tbm_data": {
        "V2C Signal Id": 1700,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m/s^2",
        "Range": "[-20; 20] TBC",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 60,
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 29,
      "Sent": "X",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "1700A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Average acceleration along Y": {
      "tbm_data": {
        "V2C Signal Id": 1701,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m/s^2",
        "Range": "[-20; 20] TBC",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 61,
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 30,
      "Sent": "X",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "1701A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Average acceleration along Z": {
      "tbm_data": {
        "V2C Signal Id": 1702,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m/s^2",
        "Range": "[-20; 20] TBC",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 62,
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 31,
      "Sent": "X",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "1702A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Sunroof Status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 34,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 32,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "17M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Rotation angle of the vehicle along X": {
      "tbm_data": {
        "V2C Signal Id": 1800,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "degree/sec",
        "Range": "[-2000 \u2026 +2000]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 33,
      "Sent": "X",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1800A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "degree/sec",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation angle of the vehicle along Y": {
      "tbm_data": {
        "V2C Signal Id": 1801,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "degree/sec",
        "Range": "[-2000 \u2026 +2000]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 34,
      "Sent": "X",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1801A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "degree/sec",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation angle of the vehicle along Z": {
      "tbm_data": {
        "V2C Signal Id": 1802,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "degree/sec",
        "Range": "[-2000 \u2026 +2000]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 35,
      "Sent": "X",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1802A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "degree/sec",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Excessive engine coolant temperature": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 4,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 36,
      "Sent": "",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "18M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "ENGINE_Engine coolant temperature (SubID, Valore, uom)"
    },
    "Rotation Matrix axx": {
      "tbm_data": {
        "V2C Signal Id": 1900,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 69,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 37,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1900A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix axy": {
      "tbm_data": {
        "V2C Signal Id": 1901,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 70,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 38,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1901A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix axz": {
      "tbm_data": {
        "V2C Signal Id": 1902,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 71,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 39,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1902A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix ayx": {
      "tbm_data": {
        "V2C Signal Id": 1903,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 72,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 40,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1903A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix ayy": {
      "tbm_data": {
        "V2C Signal Id": 1904,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 73,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 41,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1904A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix ayz": {
      "tbm_data": {
        "V2C Signal Id": 1905,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 74,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 42,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1905A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix azx": {
      "tbm_data": {
        "V2C Signal Id": 1906,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 75,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 43,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1906A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix azy": {
      "tbm_data": {
        "V2C Signal Id": 1907,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 76,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 44,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1907A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Rotation Matrix azz": {
      "tbm_data": {
        "V2C Signal Id": 1908,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "",
        "Range": "[-1 \u2026 +1]",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 77,
        "Multiplication Factor": 1.0,
        "Format": "long integer",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "CRASH"
      },
      "row_no": 45,
      "Sent": "",
      "Collection ID": 55,
      "CollectionsName": "Gyroscope",
      "SignalID": "1908A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Impact Command": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3200,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 46,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "19M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Passenger airbag fault light": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 20,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 4",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 48,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "1M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Active\n1 = Not Active\n2 = Not Present\n3 = SNA\n4 = PassengerBagActive_lamp_flashing",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Impact toggle": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3216,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 49,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "20M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Any impact event": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3217,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 50,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "21M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Front Passenger Protection Pyrotechnical charges status": {
      "tbm_data": {
        "V2C Signal Id": 2300,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 53,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "2300A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = LAMP_OFF\n1 = LAMP_BLINK\n2 = LAMP_ON\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "the status of Front Passenger Protection Pyrotechnical charges",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Drive mode": {
      "tbm_data": {
        "V2C Signal Id": 2400,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0; 1; 2; 3; 4; 5; 7",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 55,
      "Sent": "X",
      "Collection ID": 34,
      "CollectionsName": "DRIVING STYLE",
      "SignalID": "2400A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Normal / NORMAL\n1 = City or urban / CITY\n2 = Sport / SPORT\n3 = Winter / WINTER\n4=Sport Plus / SPORT_PLUS\n5=Off Road / OFF_ROAD\n7=Signal not available / SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DRIVING STYLE_Drive mode (SubID, Valore, uom)"
    },
    "Suggested gear": {
      "tbm_data": {
        "V2C Signal Id": 2500,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0;1;2;3;4;5;6;7;8;9;10;15",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 57,
      "Sent": "",
      "Collection ID": 35,
      "CollectionsName": "GEAR",
      "SignalID": "2500A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 Neutral\n1 ForwardGear_1\n2 ForwardGear_2\n3 ForwardGear_3\n4 ForwardGear_4\n5 ForwardGear_5\n6 ForwardGear_6\n7 ReverseGear\n8 ForwardGear_7\n9 ForwardGear_8\n10 ForwardGear_9\n15 SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "GEAR_Gear Selection (SubID, Valore, uom)"
    },
    "FPS not available": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 701,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 58,
      "Sent": "X",
      "Collection ID": 36,
      "CollectionsName": "FUEL",
      "SignalID": "25M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = FPS_Available\n1 = FPS_Not_Available",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "FUEL_Intervention fuel cut system (SubID, Valore, uom)"
    },
    "Days to service": {
      "tbm_data": {
        "V2C Signal Id": 2600,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "day",
        "Range": "1 - 366",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 1300,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "day",
        "Range": "",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 59,
      "Sent": "X",
      "Collection ID": 44,
      "CollectionsName": "SERVICE",
      "SignalID": "2600A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "day",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "SERVICE_Scheduled maintenance (service) - km/days (SubID, Valore, uom)"
    },
    "Engine oil life (km)": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 1401,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "km",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 60,
      "Sent": "",
      "Collection ID": 44,
      "CollectionsName": "SERVICE",
      "SignalID": "26M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "km",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "SERVICE_Scheduled maintenance (service) - OilLife (SubID, Valore, uom)"
    },
    "Distance to service": {
      "tbm_data": {
        "V2C Signal Id": 2700,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "Km",
        "Range": "0 - 131070",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 1301,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "km",
        "Range": "",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 61,
      "Sent": "X",
      "Collection ID": 44,
      "CollectionsName": "SERVICE",
      "SignalID": "2700A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "Km",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "SERVICE_Scheduled maintenance (service) - km/days (SubID, Valore, uom)"
    },
    "Engine oil life (hours)": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 1402,
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "Hours",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 62,
      "Sent": "",
      "Collection ID": 44,
      "CollectionsName": "SERVICE",
      "SignalID": "27M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "Hours",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "SERVICE_Scheduled maintenance (service) - OilLife (SubID, Valore, uom)"
    },
    "Vehicle speed fail ": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3501,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "120 s"
      },
      "row_no": 65,
      "Sent": "",
      "Collection ID": 45,
      "CollectionsName": "SPEEDOMETER",
      "SignalID": "28M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "SPEEDOMETER (SubID, Valore, uom)"
    },
    "Tire inflation": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2205,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 66,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "29M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Normal_Tyre_Pressure\n1 = Under_Inflated_Tyre\n2 = SignificantlyUnderInflatedTyre\n3 = Over_Inflated_Tyre",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Battery Level": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 17,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "Volt",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 68,
      "Sent": "X",
      "Collection ID": 11,
      "CollectionsName": "BATTERY",
      "SignalID": "2M",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "Volt",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "BATTERY_Battery Volt (SubID, Valore, uom)"
    },
    "Brake Fluid sensor": {
      "tbm_data": {
        "V2C Signal Id": 3000,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 1,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 69,
      "Sent": "X",
      "Collection ID": 13,
      "CollectionsName": "BRAKE",
      "SignalID": "3000A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = OK\n1 = Low Level",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "BRAKE_Low brake fluid / racked electric parking brake (SubID, Valore, uom)"
    },
    "Tire position": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2206,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 7",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 70,
      "Sent": "",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "30M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Instant fuel consumption": {
      "tbm_data": {
        "V2C Signal Id": 3100,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "\u00b5l/100ms",
        "Range": "",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 71,
      "Sent": "",
      "Collection ID": 38,
      "CollectionsName": "FUEL OTHER",
      "SignalID": "3100A",
      "TBM": "X",
      "MC": "",
      "Meaning": "",
      "Target uom": "Km/l",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "Instantaneous fuel consumption (SubID, Valore, uom)"
    },
    "Ignition status fail": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2601,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0; 1; 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 72,
      "Sent": "X",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "31M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": ""
    },
    "Source platform": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": "-",
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": ""
      },
      "row_no": 76,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "34M",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Oil temp status": {
      "tbm_data": {
        "V2C Signal Id": 3500,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "\u00b0C",
        "Range": "-40\u00b0 - +160\u00b0",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 77,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "3500A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "\u00b0C",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "ENGINE_Engine Oil Temperature (SubID, Valore, uom)"
    },
    "Engine Status Powernet": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3301,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15",
        "Dataset Type": "",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 78,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "35M",
      "TBM": "",
      "MC": "X",
      "Meaning": "1 = ENS_STOPPED\n2 = ENS_RQ_ST\n3 = ENS_RUN\n4 = ENS_STOP_PEND\n5 = ENS_ST_PRTCT\n6 = ENS_ST_INH\n7 = ENS_DSBL\n8 = ENS_IHB_LATCH\n9 = ENS_STARTING\n10 = KEY_START\n15 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "ENGINE_Engine status (SubID, Valore, uom)"
    },
    "Exterior temp MC": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 30,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "\u00b0C",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 79,
      "Sent": "X",
      "Collection ID": 46,
      "CollectionsName": "TEMPERATURE",
      "SignalID": "3600A",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "\u00b0C",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "TEMPERATURE_Ext Temperature (SubID, Valore, uom)"
    },
    "Exterior temp TBM": {
      "tbm_data": {
        "V2C Signal Id": 3600,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "\u00b0F",
        "Range": "-40 - +210(\u00b0F)",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 80,
      "Sent": "X",
      "Collection ID": 46,
      "CollectionsName": "TEMPERATURE",
      "SignalID": "3601A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "\u00b0F",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Bonnet status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2705,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 81,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "36M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Heated seats on/off": {
      "tbm_data": {
        "V2C Signal Id": 3701,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 83,
      "Sent": "X",
      "Collection ID": 43,
      "CollectionsName": "SEATS&BELTS",
      "SignalID": "3701A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "SEATS&BELTS_Temperature (SubID, Valore, uom)"
    },
    "Door lock status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2706,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 84,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "37M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Locked\n1 = Driver_Door_Unlocked\n2 = All_Door_Unlocked\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Air conditioning temperature": {
      "tbm_data": {
        "V2C Signal Id": 3801,
        "Multiplication Factor": 1.0,
        "Format": "%",
        "UOM": "",
        "Range": "0 - 100%",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 86,
      "Sent": "X",
      "Collection ID": 46,
      "CollectionsName": "TEMPERATURE",
      "SignalID": "3801A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "TEMPERATURE_Other (SubID, Valore, uom)"
    },
    "Hatch lock status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2707,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 87,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "38M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Locked\n1 = Unlocked",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Forward Collision Warning": {
      "tbm_data": {
        "V2C Signal Id": 4000,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0;1;2;3;4;5;6;7;8;9;10;11",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 91,
      "Sent": "X",
      "Collection ID": 3,
      "CollectionsName": "ADAS_Collision mitigation",
      "SignalID": "4000A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Idle\n1 = Pre_Intervention_warning\n2 = Intervention__warning\n3 = Not_Available_warning_type1\n4 = Not_Available_warning_4WD_Low\n5 = ACTIVE_BRAKING_ENBL\n6 = ACTIVE_BRAKING_DSBL\n7 = FCW_USER_DISABLED\n8 = FCW_RADAR_BLINDED\n9 = FCW_CAMERA_BLINDED\n10 = FCW_CAMERA_FAIL\n11 = FCW_PERM_FAIL",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ADAS_Collision mitigation (SubID, Valore, uom)"
    },
    "IPC status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 55,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off\nCRASH"
      },
      "row_no": 92,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "40M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": ""
    },
    "Forward Collision Autobrake": {
      "tbm_data": {
        "V2C Signal Id": 4102,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 7",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 94,
      "Sent": "X",
      "Collection ID": 3,
      "CollectionsName": "ADAS_Collision mitigation",
      "SignalID": "4102A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = IDLE\n1 = ACC\n2 = CMS\n3 = ABA\n4 = LSCM\n5 = CMS_XTD\n6 = PEB\n7 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ADAS_Collision mitigation (SubID, Valore, uom)"
    },
    "Hatch MC": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 2704,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 95,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "41M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": ""
    },
    "Lane Departure Warning": {
      "tbm_data": {
        "V2C Signal Id": 4203,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0; 6",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 99,
      "Sent": "",
      "Collection ID": 7,
      "CollectionsName": "ADAS_Lane Departure Warning",
      "SignalID": "4203A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 None\n6 Half_Chime",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ADAS_Lane Departure Warning (SubID, Valore, uom)"
    },
    "Harsh Type": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 78,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 2",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 100,
      "Sent": "",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "42M",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Harsh Acceleration": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 79,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "m/s^2",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 101,
      "Sent": "",
      "Collection ID": 52,
      "CollectionsName": "Acceleration",
      "SignalID": "43M",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "m/s^2",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Engine Status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3300,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 102,
      "Sent": "",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "44M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Engine_OFF\n1 = Engine_Cranking\n2 = Engine_ON\n3 = Information_Not_Available",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Blind Spot Monitors and Sensors": {
      "tbm_data": {
        "V2C Signal Id": 4601,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 105,
      "Sent": "X",
      "Collection ID": 2,
      "CollectionsName": "ADAS_Blind Spot",
      "SignalID": "4601A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = No Sensor Blocked Request\n1 = Sensor Blocked Request",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ADAS_Blind Spot (SubID, Valore, uom)"
    },
    "Headlamp status": {
      "tbm_data": {
        "V2C Signal Id": 4800,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 108,
      "Sent": "X",
      "Collection ID": 39,
      "CollectionsName": "LIGHTS",
      "SignalID": "4800A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = OFF\n1 = ON",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "LIGHTS_Other (SubID, Valore, uom)"
    },
    "Gear box type": {
      "tbm_data": {
        "V2C Signal Id": 4901,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 4;#",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 110,
      "Sent": "X",
      "Collection ID": 35,
      "CollectionsName": "GEAR",
      "SignalID": "4901A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Not valid\n1 = MTX\n2 = MTA (Robotized Gearbox)\n3 = DDCT\n4 = ATX\n# = Not Used",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "PROXI_Gear type (SubID, Valore, uom)"
    },
    "Gear selection": {
      "tbm_data": {
        "V2C Signal Id": 4905,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 8",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 114,
      "Sent": "X",
      "Collection ID": 35,
      "CollectionsName": "GEAR",
      "SignalID": "4905A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = N\n1 = D1\n2 = D2\n3 = D3\n4 = D4\n5 = D5\n6 = D6\n7 = D7\n8 = D8",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "Target Gear - Tells of the intended target gear that the transmission will be shifting to. Also includes an cancel shift that the DASM uses for better control",
      "Data Objects": "GEAR_Gear Selection (SubID, Valore, uom)"
    },
    "timestamp": {
      "tbm_data": {
        "V2C Signal Id": "V2C",
        "Multiplication Factor": 1.0,
        "Format": "long",
        "UOM": "ms",
        "Range": "-",
        "Dataset Type": "V2C",
        "Dataset ID": "ID2, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": 54,
        "Multiplication Factor": 1.0,
        "Format": "long",
        "UOM": "ms",
        "Range": "",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 116,
      "Sent": "X",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "4A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "ms",
      "Target Format": "lon",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Turn indicator status": {
      "tbm_data": {
        "V2C Signal Id": 5203,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 121,
      "Sent": "X",
      "Collection ID": 39,
      "CollectionsName": "LIGHTS",
      "SignalID": "5203A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Stop\n1 = Moving",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "LIGHTS_External Lights (turn,\u2026) (SubID, Valore, uom)"
    },
    "Windshield wiper status": {
      "tbm_data": {
        "V2C Signal Id": 5300,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 122,
      "Sent": "X",
      "Collection ID": 50,
      "CollectionsName": "WINDSHIELD",
      "SignalID": "5300A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Stop\n1 = Moving",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "WINDSHIELD_Wiper (SubID, Valore, uom)"
    },
    "Seat belt state": {
      "tbm_data": {
        "V2C Signal Id": 5404,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 127,
      "Sent": "X",
      "Collection ID": 43,
      "CollectionsName": "SEATS&BELTS",
      "SignalID": "5404A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = PSG_SEATBELT_OK\n1 = PSG_SEATBELT_NOK\n2 = SEATBELT_FAIL\n3= SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "SEATS&BELTS_Belt Status (SubID, Valore, uom)"
    },
    "Yaw angle": {
      "tbm_data": {
        "V2C Signal Id": 5800,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "\u00b0/s",
        "Range": "-327,68 - +327,66",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 131,
      "Sent": "X",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "5800A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "\u00b0/s",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Yaw angle (SubID, Valore, uom)"
    },
    "Airbag deployment state - CRC": {
      "tbm_data": {
        "V2C Signal Id": 5900,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 132,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5900A",
      "TBM": "X",
      "MC": "",
      "Meaning": "CRC according to SAE J1850 standard that has to be calculated  at each frame transmission.",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS Front Pretensioner Event": {
      "tbm_data": {
        "V2C Signal Id": 5901,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3203,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 133,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5901A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS Rear Event": {
      "tbm_data": {
        "V2C Signal Id": 5902,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3204,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 134,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5902A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "More Severe Frontal Event": {
      "tbm_data": {
        "V2C Signal Id": 5903,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3205,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 135,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5903A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Less Severe Side Event": {
      "tbm_data": {
        "V2C Signal Id": 5904,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3206,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 136,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5904A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Rear Event": {
      "tbm_data": {
        "V2C Signal Id": 5905,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3207,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 137,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5905A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS Less Severe Frontal Event": {
      "tbm_data": {
        "V2C Signal Id": 5906,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3208,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 138,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5906A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Not Used": {
      "tbm_data": {
        "V2C Signal Id": 5907,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3209,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 139,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5907A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "non - EARS RolloverEvent": {
      "tbm_data": {
        "V2C Signal Id": 5908,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3210,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 140,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5908A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS RolloverEvent": {
      "tbm_data": {
        "V2C Signal Id": 5909,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3211,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 141,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5909A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS Driver or Left side event": {
      "tbm_data": {
        "V2C Signal Id": 5910,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3212,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 142,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5910A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS Side Event": {
      "tbm_data": {
        "V2C Signal Id": 5911,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3213,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 143,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5911A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "EARS Passenger or right side event": {
      "tbm_data": {
        "V2C Signal Id": 5912,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3214,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 144,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5912A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Pedestrian Event": {
      "tbm_data": {
        "V2C Signal Id": 5913,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3215,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 145,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5913A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Do_Not_Actuate\n1 = Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Impact confirm": {
      "tbm_data": {
        "V2C Signal Id": 5914,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3201,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 146,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5914A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Actuate\n1 = Do_Not_Actuate",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Impact fail status": {
      "tbm_data": {
        "V2C Signal Id": 5915,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 3202,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 147,
      "Sent": "X",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5915A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Airbag deployment state - Message counter": {
      "tbm_data": {
        "V2C Signal Id": 5916,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "-",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 148,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "5916A",
      "TBM": "X",
      "MC": "",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AIRBAG_Airbag Status (SubID, Valore, uom)"
    },
    "Steering wheel angle": {
      "tbm_data": {
        "V2C Signal Id": 6000,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "degree",
        "Range": "-2048 - 2047",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 151,
      "Sent": "X",
      "Collection ID": 42,
      "CollectionsName": "STEERING",
      "SignalID": "6000A",
      "TBM": "X",
      "MC": "",
      "Meaning": "-2048 to 2047 Degrees\nresolution =  0.5 deg",
      "Target uom": "degree",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "STEERING_Angle (SubID, Valore, uom)"
    },
    "Driver request": {
      "tbm_data": {
        "V2C Signal Id": 6200,
        "Multiplication Factor": 1.0,
        "Format": "%",
        "UOM": "",
        "Range": "0 - 100",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 155,
      "Sent": "",
      "Collection ID": 14,
      "CollectionsName": "BRAKE ACCELEROMETER",
      "SignalID": "6200A",
      "TBM": "X",
      "MC": "",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE ACCELEROMETER_Throttle position (SubID, Valore, uom)"
    },
    "Braking position": {
      "tbm_data": {
        "V2C Signal Id": 6300,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 156,
      "Sent": "X",
      "Collection ID": 13,
      "CollectionsName": "BRAKE",
      "SignalID": "6300A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = No braking / IDLE\n1 = Braking / BRAKING\n2 = Not Defined / NDEF2\n3 = Signal not available / SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "BRAKE_Brake position (SubID, Valore, uom)"
    },
    "Motive mode": {
      "tbm_data": {
        "V2C Signal Id": 6500,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 7",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 157,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "6500A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Engine is stopped / STOP\n1 = Engine starting / START\n2 = Engine idling, unstable / IDLE_UNSTBL\n3 = Engine idling, stable / IDLE_STBL\n4 = Engine running, unlimited rpm / UNLIMITED\n5 = Engine running, limited rpm / LIMITED\n6 = not defined / NDEF6\n7 = Signal not available / SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ENGINE_Engine status (SubID, Valore, uom)"
    },
    "Distance to empty fuel tank (DTE)": {
      "tbm_data": {
        "V2C Signal Id": 6600,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "km",
        "Range": "0 - 1022",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 31,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "km",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nKey off"
      },
      "row_no": 158,
      "Sent": "",
      "Collection ID": 36,
      "CollectionsName": "FUEL",
      "SignalID": "6600A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "km",
      "Target Format": "float",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "FUEL_Distance to empty (SubID, Valore, uom)"
    },
    "Fuel level TBM": {
      "tbm_data": {
        "V2C Signal Id": 6700,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "l",
        "Range": "0 - 255",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 159,
      "Sent": "",
      "Collection ID": 36,
      "CollectionsName": "FUEL",
      "SignalID": "6700A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "l",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "FUEL_Fuel Level (average,..) (SubID, Valore, uom)"
    },
    "Fuel Level MC": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 28,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "%",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "120 s"
      },
      "row_no": 160,
      "Sent": "",
      "Collection ID": 36,
      "CollectionsName": "FUEL",
      "SignalID": "6700M",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "%",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Odometer": {
      "tbm_data": {
        "V2C Signal Id": 6900,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "Km",
        "Range": "0 - +1677721,4",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 25,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "km",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "120 s"
      },
      "row_no": 161,
      "Sent": "X",
      "Collection ID": 49,
      "CollectionsName": "ODOMETER",
      "SignalID": "6900A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "Km",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "BRAKE_Odometer (SubID, Valore, uom)"
    },
    "Passenger seat belt status": {
      "tbm_data": {
        "V2C Signal Id": 7401,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 166,
      "Sent": "X",
      "Collection ID": 43,
      "CollectionsName": "SEATS&BELTS",
      "SignalID": "7401A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Seat_Not_Checked\n1 = Seat_Checked",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "SEATS&BELTS_Belt Status (SubID, Valore, uom)"
    },
    "Airbag Deployment": {
      "tbm_data": {
        "V2C Signal Id": 7500,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 167,
      "Sent": "",
      "Collection ID": 9,
      "CollectionsName": "AIRBAG_Airbag Status",
      "SignalID": "7500A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = OFF \n1 = CONT \n2 = BLINK \n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Driver door status": {
      "tbm_data": {
        "V2C Signal Id": 7600,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2700,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 168,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "7600A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Pass door status": {
      "tbm_data": {
        "V2C Signal Id": 7601,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2702,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 169,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "7601A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Door LR status": {
      "tbm_data": {
        "V2C Signal Id": 7602,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2701,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 170,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "7602A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Door RR status": {
      "tbm_data": {
        "V2C Signal Id": 7603,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2703,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "on change"
      },
      "row_no": 171,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "7603A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Closed\n1 = Open",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Hatch status": {
      "tbm_data": {
        "V2C Signal Id": 7604,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 172,
      "Sent": "X",
      "Collection ID": 15,
      "CollectionsName": "DOORS",
      "SignalID": "7604A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Unlocked\n1 = Locked",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "DOORS_DOORS_Doors Status (SubID, Valore, uom)"
    },
    "Reverse gear usage": {
      "tbm_data": {
        "V2C Signal Id": 7900,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 173,
      "Sent": "",
      "Collection ID": 35,
      "CollectionsName": "GEAR",
      "SignalID": "7900A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 Not Inserted\n1 Inserted",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "GEAR_Reverse Gear (SubID, Valore, uom)"
    },
    "AWD or 4x4 usage": {
      "tbm_data": {
        "V2C Signal Id": 8000,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 7",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 175,
      "Sent": "X",
      "Collection ID": 34,
      "CollectionsName": "DRIVING STYLE",
      "SignalID": "8000A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = SH_IPG\n1 = 4LO\n2 = 4HI\n3 = 2WD\n4 = N\n5 = AUTO\n6 = PT\n7 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DRIVING STYLE_AWD or 4x4 (SubID, Valore, uom)"
    },
    "Gear lever position": {
      "tbm_data": {
        "V2C Signal Id": 8100,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0; 1; 2; 3; 4; 5; 7",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 176,
      "Sent": "",
      "Collection ID": 35,
      "CollectionsName": "GEAR",
      "SignalID": "8100A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 INIT\n1 PARK\n2 REV\n3 NEUT\n4 DRIVE\n5 FIFTH\n7 SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "GEAR_Reverse Gear (SubID, Valore, uom)"
    },
    "ID of key in use": {
      "tbm_data": {
        "V2C Signal Id": 8200,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0;1;2;3;4;5;6;7;8;15",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 177,
      "Sent": "",
      "Collection ID": 37,
      "CollectionsName": "KEY",
      "SignalID": "8200A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 FOB_DEFAULT\n1 FOB_1\n2 FOB_2\n3 FOB_3\n4 FOB_4\n5 FOB_5\n6 FOB_6\n7 FOB_7\n8 FOB_8\n15 SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "KEY_ID of key in use (SubID, Valore, uom)"
    },
    "Audio system status": {
      "tbm_data": {
        "V2C Signal Id": 8502,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 180,
      "Sent": "X",
      "Collection ID": 10,
      "CollectionsName": "AUDIO",
      "SignalID": "8502A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Initialization\n1 = Available\n2 = Not_Available\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AUDIO_Status (SubID, Valore, uom)"
    },
    "Audio system volume": {
      "tbm_data": {
        "V2C Signal Id": 8602,
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 183,
      "Sent": "X",
      "Collection ID": 10,
      "CollectionsName": "AUDIO",
      "SignalID": "8602A",
      "TBM": "X",
      "MC": "",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "AUDIO_Status (SubID, Valore, uom)"
    },
    "Differential lock usage\\state": {
      "tbm_data": {
        "V2C Signal Id": 8700,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 184,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "8700A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Active\n1 = Not Active",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ENGINE_Differential lock state (SubID, Valore, uom)"
    },
    "Driver alert system events": {
      "tbm_data": {
        "V2C Signal Id": 8928,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 213,
      "Sent": "",
      "Collection ID": 5,
      "CollectionsName": "ADAS_Driver alert system events",
      "SignalID": "8928A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 No_right_rear_chime_on_req\n1 Right_rear_chime_on_req",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ADAS_Driver alert system events (SubID, Valore, uom)"
    },
    "12v battery status": {
      "tbm_data": {
        "V2C Signal Id": 9102,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "V",
        "Range": "5 - 18",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 218,
      "Sent": "X",
      "Collection ID": 11,
      "CollectionsName": "BATTERY",
      "SignalID": "9102A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "V",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BATTERY_Battery Volt (SubID, Valore, uom)"
    },
    "Tire pressure system status RL": {
      "tbm_data": {
        "V2C Signal Id": 9200,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "psi",
        "Range": "0 - 126",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 219,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9200A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "psi",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire pressure system status RR": {
      "tbm_data": {
        "V2C Signal Id": 9201,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "psi",
        "Range": "0 - 126",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 220,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9201A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "psi",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire pressure system status FR": {
      "tbm_data": {
        "V2C Signal Id": 9202,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "psi",
        "Range": "0 - 126",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 221,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9202A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "psi",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire pressure system status FL": {
      "tbm_data": {
        "V2C Signal Id": 9203,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "psi",
        "Range": "0 - 126",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 222,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9203A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "psi",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire pressure system fail": {
      "tbm_data": {
        "V2C Signal Id": 9209,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2204,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 228,
      "Sent": "",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9209A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 Fail_Not_Present\n1 Fail_Present",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire inflation status FL": {
      "tbm_data": {
        "V2C Signal Id": 9205,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2203,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 5",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 224,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9205A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Normal_Tyre_Pressure\n1 = Under_Inflated_Tyre\n2 = Over_Inflated_Tyre\n3 = SNA\n4 = Sensor_Missing\n5 = Significantly_Under_Inflated_Tyre",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire inflation status RL": {
      "tbm_data": {
        "V2C Signal Id": 9206,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2200,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 5",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 225,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9206A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Normal_Tyre_Pressure\n1 = Under_Inflated_Tyre\n2 = Over_Inflated_Tyre\n3 = SNA\n4 = Sensor_Missing\n5 = Significantly_Under_Inflated_Tyre",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire inflation status RR": {
      "tbm_data": {
        "V2C Signal Id": 9207,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2201,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 5",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 226,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9207A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Normal_Tyre_Pressure\n1 = Under_Inflated_Tyre\n2 = Over_Inflated_Tyre\n3 = SNA\n4 = Sensor_Missing\n5 = Significantly_Under_Inflated_Tyre",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "Tire inflation status FR": {
      "tbm_data": {
        "V2C Signal Id": 9208,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 2202,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 5",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 227,
      "Sent": "X",
      "Collection ID": 48,
      "CollectionsName": "TIRE",
      "SignalID": "9208A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Normal_Tyre_Pressure\n1 = Under_Inflated_Tyre\n2 = Over_Inflated_Tyre\n3 = SNA\n4 = Sensor_Missing\n5 = Significantly_Under_Inflated_Tyre",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TIRE_Tire inflation (SubID, Valore, uom)"
    },
    "State of battery charge": {
      "tbm_data": {
        "V2C Signal Id": 9300,
        "Multiplication Factor": 1.0,
        "Format": "%",
        "UOM": "",
        "Range": "0 - 100%",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 229,
      "Sent": "X",
      "Collection ID": 59,
      "CollectionsName": "Traction Battery",
      "SignalID": "9300A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "BATTERY_Battery SOC (SubID, Valore, uom)"
    },
    "Engine coolant temperature": {
      "tbm_data": {
        "V2C Signal Id": 9400,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "\u00b0C",
        "Range": "-40 - +214",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 230,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "9400A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "\u00b0C",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "ENGINE_Engine coolant temperature (SubID, Valore, uom)"
    },
    "Transmission fluid temperature": {
      "tbm_data": {
        "V2C Signal Id": 9500,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 231,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "9500A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = False\n1 = True (Excessive trans temp)",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "ENGINE_Transm Coolant (SubID, Valore, uom)"
    },
    "Traction control event": {
      "tbm_data": {
        "V2C Signal Id": 9600,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 232,
      "Sent": "X",
      "Collection ID": 13,
      "CollectionsName": "BRAKE",
      "SignalID": "9600A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Active\n1 = Not Active",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "BRAKE_Traction Control Status (SubID, Valore, uom)"
    }
  },
  "Diagnostic Data": {
    "Low level fuel warning light": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 23,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 13,
      "Sent": "X",
      "Collection ID": 30,
      "CollectionsName": "DIAGNOSTIC_Low level fuel warning light",
      "SignalID": "10M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = False\n1 = True",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Low level fuel warning light (SubID, Valore, uom)"
    },
    "Oil pressure sensor failure": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 19,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 18,
      "Sent": "X",
      "Collection ID": 32,
      "CollectionsName": "DIAGNOSTIC_Oil pressure sensor failure",
      "SignalID": "11M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Oil pressure sensor failure (SubID, Valore, uom)"
    },
    "Failure EOBD system / injection": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 3,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 25,
      "Sent": "X",
      "Collection ID": 24,
      "CollectionsName": "DIAGNOSTIC_Failure EOBD system / injection",
      "SignalID": "15M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Flash_Light_Indication\n2 = Fail_Fix_Light_Indication\n3 = Fail_EOL_Procedure_Not_Completed",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Failure EOBD system / injection"
    },
    "Water in diesel fuel filter": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 800,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 28,
      "Sent": "X",
      "Collection ID": 29,
      "CollectionsName": "DIAGNOSTIC_Faulty fuel level sensor",
      "SignalID": "16M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Water_Not_Present\n1 = Water_Present",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Faulty fuel level sensor (SubID, Valore, uom)"
    },
    "Oil pressure": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 501,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "kPaG",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 52,
      "Sent": "X",
      "Collection ID": 20,
      "CollectionsName": "DIAGNOSTIC_DTC Code",
      "SignalID": "22M",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "kPaG",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_DTC Code (SubID, Valore, uom)"
    },
    "Oil temperature": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 502,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 54,
      "Sent": "X",
      "Collection ID": 20,
      "CollectionsName": "DIAGNOSTIC_DTC Code",
      "SignalID": "23M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = OFF\n1 = ON\n2 = FLASH\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_DTC Code (SubID, Valore, uom)"
    },
    "Water in diesel fuel filter fail": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 801,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 56,
      "Sent": "X",
      "Collection ID": 30,
      "CollectionsName": "DIAGNOSTIC_Low level fuel warning light",
      "SignalID": "24M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Filter warning - Diesel Particulate Filter": {
      "tbm_data": {
        "V2C Signal Id": 2800,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0;1;2;3;4;5",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 63,
      "Sent": "",
      "Collection ID": 28,
      "CollectionsName": "DIAGNOSTIC_Filter Warning Status",
      "SignalID": "2800A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = No Warning / NO_WARN\n1  = Non-Diesel Warning / NON_WARN\n2 = Interruptable Warning / INT_WARN\n3 = DPF Warning / DPF_WARN\n4 = DEF Warning / DEF_WARN\n15 = Signal not available / SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Filter Warning Status (SubID, Valore, uom)"
    },
    "Filter warning - Gasoline Particulate Filter": {
      "tbm_data": {
        "V2C Signal Id": 2801,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 64,
      "Sent": "",
      "Collection ID": 28,
      "CollectionsName": "DIAGNOSTIC_Filter Warning Status",
      "SignalID": "2801A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Clean\n1 = Dirty",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Filter Warning Status (SubID, Valore, uom)"
    },
    "Low washer fluid sensor": {
      "tbm_data": {
        "V2C Signal Id": 3300,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 74,
      "Sent": "",
      "Collection ID": 31,
      "CollectionsName": "DIAGNOSTIC_low washer fluid warning",
      "SignalID": "3300A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = OK\n1 = Fluid is low",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_low washer fluid warning (SubID, Valore, uom)"
    },
    "Failure Airbag": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 16,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 90,
      "Sent": "X",
      "Collection ID": 18,
      "CollectionsName": "DIAGNOSTIC_Airbag fault light",
      "SignalID": "3M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_not_present_Lamp_off\n1 = Fail_not_present_Lamp_flashing\n2 = Fail_present_Lamp_on\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Airbag fault light (SubID, Valore, uom)"
    },
    "Alternator failure": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 15,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 117,
      "Sent": "X",
      "Collection ID": 19,
      "CollectionsName": "DIAGNOSTIC_Alternator failure",
      "SignalID": "4M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = indicator_off\n1 = indicator_on",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Alternator failure (SubID, Valore, uom)"
    },
    "Antilock Braking Systems state": {
      "tbm_data": {
        "V2C Signal Id": 5700,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 200,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 129,
      "Sent": "X",
      "Collection ID": 17,
      "CollectionsName": "DIAGNOSTIC_ABS status",
      "SignalID": "5700A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Non_Active\n1 = Active",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_ABS status (SubID, Valore, uom)"
    },
    "Antilock Braking Systems fail status": {
      "tbm_data": {
        "V2C Signal Id": 5701,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 201,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 130,
      "Sent": "X",
      "Collection ID": 17,
      "CollectionsName": "DIAGNOSTIC_ABS status",
      "SignalID": "5701A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present\n2 = BLINK\n3 = SNA",
      "Target uom": "",
      "Target Format": "int",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_ABS status (SubID, Valore, uom)"
    },
    "Low engine oil pressure": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 500,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 150,
      "Sent": "X",
      "Collection ID": 20,
      "CollectionsName": "DIAGNOSTIC_DTC Code",
      "SignalID": "5M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Normal_Pressure\n1 = Low_Pressure",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_DTC Code (SubID, Valore, uom)"
    },
    "Electronic Stability Control activation state": {
      "tbm_data": {
        "V2C Signal Id": 6102,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 154,
      "Sent": "X",
      "Collection ID": 22,
      "CollectionsName": "DIAGNOSTIC_ESC status",
      "SignalID": "6102A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 = Active\n1 = Not Active",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_ESC status (SubID, Valore, uom)"
    },
    "Electronic Brake Distribution failure": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 18,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 163,
      "Sent": "X",
      "Collection ID": 21,
      "CollectionsName": "DIAGNOSTIC_Electronic Brake Distribution failure",
      "SignalID": "6M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Electronic Brake Distribution failure (SubID, Valore, uom)"
    },
    "Failure Automatic Transmission": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 9,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 174,
      "Sent": "X",
      "Collection ID": 23,
      "CollectionsName": "DIAGNOSTIC_Failure Automatic Transmission",
      "SignalID": "7M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = FailNotPresent\n1 = Fail_Present\n2 = ECU_WarningPresent\n3 = ECU_VehicleInterfaceFailPresent",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Failure Automatic Transmission (SubID, Valore, uom)"
    },
    "Failure DST": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 12,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 216,
      "Sent": "X",
      "Collection ID": 25,
      "CollectionsName": "DIAGNOSTIC_Failure DST",
      "SignalID": "8M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = Fail_Present",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Failure DST (SubID, Valore, uom)"
    },
    "Faulty fuel level sensor": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 6,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 1",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\non change\nKey off"
      },
      "row_no": 234,
      "Sent": "",
      "Collection ID": 27,
      "CollectionsName": "DIAGNOSTIC_Faulty fuel level sensor",
      "SignalID": "9M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": "DIAGNOSTIC_Faulty fuel level sensor"
    }
  },
  "GPS Position": {
    "Altitude": {
      "tbm_data": {
        "V2C Signal Id": 11,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "m",
        "Range": "-",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 68,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "m",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off"
      },
      "row_no": 17,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "11A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "m",
      "Target Format": "double",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "GPS Status": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 19,
      "Sent": "",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "120A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "For MC data: \nif HDOP (39M) > 5 -> GPS Status = 0; else GPS Status = 1\n\nFor TBM data: \nif HEPE (12A) = '-1' -> GPS Status = 0; else GPS Status = 1",
      "Data Objects": ""
    },
    "GPS accuracy (HEPE)": {
      "tbm_data": {
        "V2C Signal Id": 12,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "meters",
        "Range": "-",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 20,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "12A",
      "TBM": "X",
      "MC": "",
      "Meaning": "not enumeric",
      "Target uom": "meters",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "HDOP": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 65,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 88,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "39M",
      "TBM": "",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": ""
    },
    "Latitude": {
      "tbm_data": {
        "V2C Signal Id": 5,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "decimal degree",
        "Range": "[-90 \u2026 +90] ",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 63,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "decimal degree",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 149,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "5A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "decimal degree",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Longitude": {
      "tbm_data": {
        "V2C Signal Id": 6,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "decimal degree",
        "Range": "[-180 \u2026 +180] ",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 64,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "decimal degree",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 162,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "6A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "decimal degree",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": ""
    },
    "Heading": {
      "tbm_data": {
        "V2C Signal Id": 8,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "degree",
        "Range": "[0 ... +359] \n(0=north \u2013 clockwise)",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 67,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "degree",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 215,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "8A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "degree",
      "Target Format": "double",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    }
  },
  "Vehicle Sensitive Data": {
    "TripID": {
      "tbm_data": {
        "V2C Signal Id": 1,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0-2147483647",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID1, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": 51,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "every  sending"
      },
      "row_no": 47,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "1A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "string",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "Trip A odometer": {
      "tbm_data": {
        "V2C Signal Id": 2200,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "Km",
        "Range": "0 - 16093,3",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 51,
      "Sent": "",
      "Collection ID": 49,
      "CollectionsName": "ODOMETER",
      "SignalID": "2200A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0-16093.3 km",
      "Target uom": "Km",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "TRIP A_distance (SubID, Valore, uom)"
    },
    "DeviceID": {
      "tbm_data": {
        "V2C Signal Id": 2,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID1, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": 53,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "every  sending"
      },
      "row_no": 67,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "2A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "string",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    },
    "VIN message": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "-",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 24,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 73,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "32M",
      "TBM": "",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "-",
      "Note": "",
      "Data Objects": ""
    },
    "UserID": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": 1.0,
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": 50,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "every  sending"
      },
      "row_no": 75,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "33M",
      "TBM": "X",
      "MC": "X",
      "Meaning": "",
      "Target uom": "",
      "Target Format": "string",
      "SQDF (Si/No)": "No",
      "Note": "NA as signal. To be provided by GSDP",
      "Data Objects": ""
    },
    "VIN (plain, pseudo, anonymous)": {
      "tbm_data": {
        "V2C Signal Id": 3,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID1, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "every sending"
      },
      "mc_data": {
        "MC Signal Id": 52,
        "Multiplication Factor": 1.0,
        "Format": "string",
        "UOM": "",
        "Range": "",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "every  sending"
      },
      "row_no": 89,
      "Sent": "",
      "Collection ID": 51,
      "CollectionsName": "SourceInfo",
      "SignalID": "3A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "",
      "Target Format": "string",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    }
  },
  "Vehicle Speed Data": {
    "Engine speed": {
      "tbm_data": {
        "V2C Signal Id": 5600,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "rpm",
        "Range": "0 - 65534",
        "Dataset Type": "Additional Data, EDR",
        "Dataset ID": "ID4, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 128,
      "Sent": "X",
      "Collection ID": 16,
      "CollectionsName": "ENGINE",
      "SignalID": "5600A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 - 65534 rpm",
      "Target uom": "rpm",
      "Target Format": "",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "ENGINE_Engine speed (SubID, Valore, uom)"
    },
    "CAN vehicle speed": {
      "tbm_data": {
        "V2C Signal Id": 7000,
        "Multiplication Factor": 1.0,
        "Format": "float",
        "UOM": "km/h",
        "Range": "0 - +511.984375",
        "Dataset Type": "Additional Data",
        "Dataset ID": "ID4",
        "Available": "Yes",
        "TBM Sending Frequency": "300 s"
      },
      "mc_data": {
        "MC Signal Id": 3500,
        "Multiplication Factor": 1.0,
        "Format": "double",
        "UOM": "km/h",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "120 s"
      },
      "row_no": 164,
      "Sent": "X",
      "Collection ID": 45,
      "CollectionsName": "SPEEDOMETER",
      "SignalID": "7000A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "km/h",
      "Target Format": "double",
      "SQDF (Si/No)": "Si",
      "Note": "",
      "Data Objects": "SPEEDOMETER (SubID, Valore, uom)"
    },
    "GPS Speed": {
      "tbm_data": {
        "V2C Signal Id": 9,
        "Multiplication Factor": 3.6,
        "Format": "double",
        "UOM": "m/s",
        "Range": "-",
        "Dataset Type": "MSD, EDR",
        "Dataset ID": "ID3, ID7",
        "Available": "Yes",
        "TBM Sending Frequency": "120 s\nCRASH"
      },
      "mc_data": {
        "MC Signal Id": 66,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "Km/h",
        "Range": "SNA",
        "Dataset Type": "Periodic Flow, Crash Report",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\n120 s\nKey off\nCRASH"
      },
      "row_no": 233,
      "Sent": "X",
      "Collection ID": 54,
      "CollectionsName": "GPS",
      "SignalID": "9A",
      "TBM": "X",
      "MC": "X",
      "Meaning": "not enumeric",
      "Target uom": "Km/h",
      "Target Format": "double",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": ""
    }
  },
  "Others": {
    "DC/DC Converter Fail": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 138,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0-3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nOn change\nKey off"
      },
      "row_no": 103,
      "Sent": "",
      "Collection ID": 56,
      "CollectionsName": "DC/DC Converter ",
      "SignalID": "45M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = OFF\n1 = On_Fixed\n2 = On_Blinking\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "AVAS Generic Fail": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 136,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0-5",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nOn change\nKey off"
      },
      "row_no": 106,
      "Sent": "",
      "Collection ID": 57,
      "CollectionsName": "AVAS",
      "SignalID": "46M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = Fail_Not_Present\n1 = QVPM_Fail\n2 = Front_Speaker_Fail\n3 = Rear_Speaker_Fail\n4 = Plausibility_Fail\n5 = MissingMsg_Fail",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Electric Motor Fail": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 133,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0-3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nOn change\nKey off"
      },
      "row_no": 107,
      "Sent": "",
      "Collection ID": 58,
      "CollectionsName": "Electric Motor",
      "SignalID": "47M",
      "TBM": "",
      "MC": ".X",
      "Meaning": "0 = OFF\n1 = On_Fixed\n2 = On_Blinking\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Traction Battery Fail ": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 134,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0-3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nOn change\nKey off"
      },
      "row_no": 109,
      "Sent": "",
      "Collection ID": 59,
      "CollectionsName": "Traction Battery",
      "SignalID": "48M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = OFF\n1 = On_Fixed\n2 = On_Blinking\n3 = SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Turtle mode - torque limited ": {
      "tbm_data": {
        "V2C Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": 135,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "int",
        "Range": "0-3",
        "Dataset Type": "Periodic Flow",
        "Dataset ID": "ID1",
        "MC Sending Frequency": "Key on\nOn change\nKey off"
      },
      "row_no": 115,
      "Sent": "",
      "Collection ID": 60,
      "CollectionsName": "Turtle Mode",
      "SignalID": "49M",
      "TBM": "",
      "MC": "X",
      "Meaning": "0 = OFF\n1 = On_Fixed\n2 = On_Blinking\n3 = SNA",
      "Target uom": "int",
      "Target Format": "",
      "SQDF (Si/No)": "",
      "Note": "",
      "Data Objects": ""
    },
    "Driver alert system events": {
      "tbm_data": {
        "V2C Signal Id": 8929,
        "Multiplication Factor": 1.0,
        "Format": "int",
        "UOM": "",
        "Range": "0 - 15",
        "Dataset Type": "",
        "Dataset ID": "",
        "Available": "No",
        "TBM Sending Frequency": ""
      },
      "mc_data": {
        "MC Signal Id": "",
        "Multiplication Factor": "",
        "Format": "",
        "UOM": "",
        "Range": "",
        "Dataset Type": "",
        "Dataset ID": "",
        "MC Sending Frequency": ""
      },
      "row_no": 214,
      "Sent": "",
      "Collection ID": 5,
      "CollectionsName": "ADAS_Driver alert system events",
      "SignalID": "8929A",
      "TBM": "X",
      "MC": "",
      "Meaning": "0 NONE\n1 TYP_1\n2 TYP_2\n3 TYP_3\n4 TYP_4\n5 TYP_5\n6 TYP_6\n7 TYP_7\n8 TYP_8\n9 TYP_9\n10 TYP_10\n11 TYP_11\n12 TYP_12\n13 TYP_13\n14 TYP_14\n15 SNA",
      "Target uom": "",
      "Target Format": "",
      "SQDF (Si/No)": "No",
      "Note": "",
      "Data Objects": "ADAS_Driver alert system events (SubID, Valore, uom)"
    }
  }
}