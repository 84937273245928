import React from "react";
// import PlanImage from "../../images/plan_img.jpg";
import './Subscription.css'
import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
// import NextImgPlan from "../../images/next_btn_plans.png";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Diversity2Outlined } from "@mui/icons-material";
// import carousel from "./carousel";
// import subscriptionCards from "./cards"

const Subscription = () => {
  const [goToSlide, setgoToSlide] = useState(0)

  const state = {
    goToSlide: goToSlide,
    offsetRadius: 2,
    // showNavigation: true,
    config: config.gentle
  };

  let slides = [
    {
      key: uuidv4(),
      content: <Card className="plan-card" style={{top:"220px",backgroundColor:"#186277"}}>
        <CardContent>
          <p className="plan-center-align plan-card-header">Premium</p>
          <ul className="plan-card-contents">
            <li>Provides the possibility to retrieve data and send commands on a real vehicle</li>
            <li>10x api call number against the free plan</li>
          </ul>
          <div className="plan-center-align">
            <div style={{marginTop:"10vh"}}>
              <div>
                <p className="plan-center-align text-wrapper-3">$39.99</p>
                <p className="plan-center-align text-wrapper-4">per month</p>
              </div>
              <div className="plan-center-align">
                <Button className="plan_button" variant="light">Subscribe</Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

    },
    {
      key: uuidv4(),
      content: <Card className="plan-card" style={{backgroundColor:"#412283"}}>
        <CardContent>
          <p className="plan-card-header plan-center-align">Business</p>
          <ul className="plan-card-contents">
            <li>Project published online</li>
            <li>No API call limit</li>
            <li>Supported by Stellantis operations team</li>
            <li>10x api call number against the free plan</li>
          </ul>
          <div className="plan-center-align">
            <div style={{marginTop:"20vh"}}>
              <div className="plan-center-align">
                <Button  className="plan_button" variant="light">Contact Us</Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

    },
    {
      key: uuidv4(),
      content: <Card className="plan-card" style={{backgroundColor:"#243782"}}>
        <CardContent>
          <p className="plan-card-header plan-center-align">Free</p>
          <ul className="plan-card-contents">
            <li>Explore our APIs capacity on a simulated vehicle</li>
            <li>Limited number of API call</li>
          </ul>
          <div className="plan-center-align">
            <div style={{marginTop:"20vh"}}>
              <p className="plan-center-align text-wrapper-3">$0</p>
              <Button  className="plan_button" variant="light" style={{width:"auto", align:"center"}}>Subscribe</Button>
            </div>
          </div>
        </CardContent>
      </Card>
    }
  ].map((slide, index) => {
    return { ...slide, onClick: () => setgoToSlide(index) };
  });


  // onChangeInput = e => {
  //   this.setState({
  //     [e.target.name]: parseInt(e.target.value, 10) || 0
  //   });
  // }
  return (
    <>
      <div className="plan">
        <img className="main-image" src={'/images/plan_img.jpg'} alt="Index image" />
        <div className="overlay" >
          <div>
            <p>Subscription</p>
          </div>
        </div>
        <div className="container content-body">
          <p >Plans</p>
          <p className="sub-text">Subscribe to our plans to start to using our APIs available for each brand find here below here below</p>

          {/* <Container>
            <div class="row">
              <div class="col-md-4">
                <div class="card card-1">
                  <div className="text-wrapper-1">Free</div>
                  <ul className="text-wrapper-2">
                    <li>Explore our APIs capacity on a simulated vehicle</li>
                    <li>Limited number of API call</li>
                  </ul> 
                  <div className="card-last-section">
                    <p className="text-wrapper-3">$0</p>
                    <Button  className="plan_button" variant="light" style={{width:"auto", align:"center"}}>Subscribe</Button>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card card-2">
                  <div className="text-wrapper-1">Premium</div>
                  <ul className="text-wrapper-2">
                    <li>Provides the possibility to retrieve data and send commands on a real vehicle</li>
                    <li>10x api call number against the free plan</li>
                  </ul>
                  <div className="card-last-section">
                    <p className="text-wrapper-3">$39.99</p> <section className="text-wrapper-4">per month</section>
                    <Button className="plan_button" variant="light" style={{width:"auto", align:"center"}}>Subscribe</Button>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card card-3">
                  <div className="text-wrapper-1">Business</div>
                  <ul className="text-wrapper-2">
                    <li>Project published online</li>
                    <li>No API call limit</li>
                    <li>Supported by Stellantis operations team</li>
                  </ul>
                  <div className="card-last-section">
                    <Button className="plan_button" variant="light" style={{width:"auto", align:"center"}}>Contact us</Button>
                  </div>
                </div>
              </div>
            </div>
          </Container> */}
          <div className="row last-section">
            <div className="col-sm-10 col-md-10 col-lg-10">
              <p className="text-wrapper-5">Do you have business Fleet?
                <section className="text-wrapper-6">Check on our fleet portal to improve your business</section>
              </p>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <img className="nxt-image" src={'images/next_btn_plans.png'} alt="Next image plan" />
            </div>
            <div style={{ width: '100%', height: '100%', border: '3px #868686 solid', marginBottom: "6%" }}></div>
          </div>
        </div>
        <div className="plan-cards">
            <div className="carousel-gen carousel-component">
              <Carousel
                slides={slides}
                goToSlide={state.goToSlide}
                // offsetRadius={2}
                offsetRadius={state.offsetRadius}
                // showNavigation={state.showNavigation}
                animationConfig={state.config}
              />
            </div>
          </div>
      </div>
    </>
  );
}
export default Subscription;