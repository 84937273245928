import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { UpdateAccountPasswordApiCallback } from "./UpdateAccountPasswordApiCallback";

const UpdateAccountPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm();

  const validatePassword = (password) => {
    if (/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/.test(password)) {
      // if password does not meet criteria
      setError("password", {
        type: "custom",
        message:
          "Password is required and must contain at least 8 characters, including at least one of the following: uppercase letter, lowercase letter, number, and special character.",
      })
    }
    else {
      clearErrors(["password"])
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-12 custom-md6-width  mt-2"
            style={{ "max-width": "50%" }}
          >
            <h2 style={{ color: "rgb(50, 50, 159);" }}>
              Update Account Password
            </h2>
            <h5>Update account password</h5>
            <form onSubmit={handleSubmit(UpdateAccountPasswordApiCallback)}>
              <div className="form-group">
                <label for="connectedAccountEmail">
                  Username/Email <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="connectedAccountEmail"
                  {...register("Email")}
                  placeholder="Enter User Name"
                />
              </div>
              <div className="form-group">
                <label for="userPassword">
                  Old Password<span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="password"
                  className="form-control"
                  id="currentPassword"
                  {...register("CurrentPassword", {
                    required: "Password is required",
                  })}
                  placeholder="Enter current password"
                  autoComplete="current-password"
                />
                {errors?.CurrentPassword && (
                  <p>{errors.CurrentPassword.message}</p>
                )}
              </div>
              <div className="form-group">
                <label for="password">
                  New Password<span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  {...register("password", {})}
                  onBlur={(e) => validatePassword(e.target.value)}
                  placeholder="Enter new password"
                  autoComplete="new-password"
                />
                {errors?.password && <p>{errors.password.message}</p>}
              </div>
              <div className="form-group">
                <label for="confirmPassword">
                  Confirm account Password<span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm new password"
                  autoComplete="  Confirm New-password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === watch("password") || "Passwords do not match",
                  })}
                />
                {errors?.confirmPassword && (
                  <p>{errors.confirmPassword.message}</p>
                )}
              </div>
              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary btn-block" disabled={Object.keys(errors).length > 0}>
                  Submit
                </button>                
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAccountPassword;
