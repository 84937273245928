import React from "react";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./style.css";
import SwaggerPageGenerator from "../../components/SwaggerPageGenerator/SwaggerPageGenerator";
import Banner from "../../components/Banner/Banner";
import { FCA_SWAGGER } from "./swagger";
const FCA_API = () => {
    const theme = useTheme();

    const [value, setValue] = useState("1");
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <>
        <div>
        <Banner 
        image={"/images/automotive-spice-determining-software-process-improvement-and-capability-blog-fetaured.jpg"} 
        heading={"FCA Vehicle API's"} />
        </div>
        <br />
        <div className='page_box'>
          <div className='box_container'>
            <SwaggerPageGenerator swagger={FCA_SWAGGER} defaultView={true} />
          </div>
        </div>  
      </>
    );
}

export default FCA_API
