import React from 'react'
import { useForm } from "react-hook-form";
import { SubmitBugReportApi } from './SubmitBigReportApi';
const SubmitBugReport = () => {
      const { register, handleSubmit } = useForm();

    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
              <h2 style={{ color: "rgb(50, 50, 159);" }}>Submit Bug Report</h2>
              <h5>
                Submit Bug Report. For General feedback or questions, use
                /feedback instead
              </h5>
              <form onSubmit={handleSubmit(SubmitBugReportApi)}>
                <div class="form-group">
                  <label for="bugs">
                    Bugs<span className="required-star">*</span>
                  </label>
                  <textarea
                    required
                    className="form-control"
                    id="bugs"
                    placeholder="Enter Bug report"
                    {...register("Bug")}
                  ></textarea>
                </div>

                <div class="form-group text-center">
                  <button type="submit" class="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
}

export default SubmitBugReport