export const ENVIRONMENT = `
<h1><strong><span>Environments</span></strong></h1>
<p>The Supplier of the system(s) must have their own Development and Test Environment in which they can independently develop their integrations. In addition they must have their own Certification environment in which they can perform all the integration tests required and its own production environment to host the system that will be integrated with the CMM.
<br>
<br>
The necessary environments will be at least:
</p>
<ul>
    <li><span class="bold">Development</span> environment is used to create the program. It will be set and managed by component/system (interface) owners in accordance with Stellantis requirements.</li>
    <li><span class="bold">Integration</span> environment is the environment for the testing teams to execute test cases.  It will be set and managed by the component/system (interface) owner in accordance with the Stellantis deploy system.</li>
    <li><span class="bold">Pre Production (Stage)</span> environment is the environment to combine and validate the work of the entire project,  so the entire work can be tested before being promoted to the Production Environment. Technical requirements are in accordance with the production environment. It will be set and managed by the component/system (interface) owner in accordance with the Stellantis deploy system.</li>
    <li><span class="bold">Production environment</span> is the final environment where the new system will be deployed to be available to all other systems. It will be set by and managed by the component/system (interface) owner in accordance with the Stellantis deploy system.</li>
</ul>
<p>Within this integration, the environments will be pointing to one another as per below table:</p>

<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>Stellantis Environment</th>
            <th>THIRD PARTY Environment</th>
        </tr>
        <tr>
            <td>INTEGRATION</td>
            <td>INTEGRATION</td>
        </tr>
        <tr>
            <td>PRE PRODUCTION</td>
            <td>INTEGRATION</td>
        </tr>
        <tr>
            <td>PRODUCTION</td>
            <td>PRODUCTION</td>
        </tr>
    </table>
</div>

<br>
<br>
<h1><strong><span>Networking and Security Authentication</span></strong></h1>
<br>
<h2>INBOUND - Third Party -> Stellantis</h2>
<br>
<h3>Networking - HTTPS</h3>
<p class="p-no-padding">No VPN is set up between Third Party and Stellantis servers.</p>
<br>
<h3>mTLS Authentication</h3>
<p class="p-no-padding">The complete Domain + Certificate Chain is provided by FCL for THIRD PARTY to be adopted and used as Client Certificate, generated in compliance with <a href="http://mtls.proxy.fcl-01.fcagcv.com/identity/v2/echoes/oauth2/token?usp=sharing">.csr File Creation - FCL Guidelines</a> .
FCL provides THIRD PARTY with its own Domain + Certificate Chain for certificate pinning as well.
</p>


<br>
<h3>MBS Domain Names</h3>
<div class="table-container">
    <table class="plain-table">
        <tr>
            <td>
            <br/>
            </td>
            <th>EMEA</th>
            <th>NAFTA</th>
        </tr>
        <tr>
            <td class="bold">PRE PRODUCTION</td>
            <td>preprod.fca-fcl- emea.mobilisights.io</td>
            <td>preprod.fca-fcl-nafta.mobilisights.io</td>
        </tr>
        <tr>
            <td class="bold">PRODUCTION</td>
            <td>prod.fca-fcl-emea.mobilisights.io</td>
            <td>prod.fca-fcl-nafta.mobilisights.io</td>
        </tr>
    </table>
</div>
<br>
<h3>OAuth2 Tokenization</h3>
<p class="p-no-padding">OAuth is an open standard for authorization which is currently in version 2, it will use an Access Token sent as Bearer Token. Method used is the client credential specs for server to server interactions.</p>
<br>

<h3>Process Overview</h3>
<img src="/assets/mobilisight/processoverview.png" />

<ol class="no-style-list">
    <li>
        <span>1.</span>Get Token
        <ol>
            <li><span>1.1.</span>Consumers retrieve the secret key.</li>
            <li><span>2.1.</span>Consumer use API to request OAuth2.0 authorization, passing the secret, client_id and scopes to Server to Server IDP</li>
            <li><span>3.1.</span>Server to Server IDP validates secret, client_id and scopes and generates a time bound JWT access token, sending it as Bearer Token to Consumer.e</li>
            <li><span>4.1.</span>Consumer get JWT access token</li>
        </ol>
    </li>
    <li>
        <span>2.</span>Call Resource API
        <ol>
            <li><span>1.1.</span>Consumer passes the access token (as Bearer header) in authorization header while calling the Feature API on Consumer / Supplier Resource Server</li>
        </ol>
    </li>
    <li>
        <span>3.</span>Validate the token and provide a response.
        <ol>
            <li><span>1.1.</span>Call Server to Server IDP API to receive public key for corresponding user pool (JWKS)
                <ol>
                    <li><span>1.1.1.</span>Verify the JWT access token signature and expiry date, Get claims from the JWT token, evaluate if claims present if the token has access to requested API.</li>
                </ol>
            </li>
            <li><span>2.1.</span>Forward the request for processing if authorization is successful, else deny</li>
            <li><span>3.1.</span>Resource server authorizer validates the JWT access token and the embedded claims API responds with 401 Unauthorized error in case the token or claims are invalid). If the token is valid and the claimed scope has access to the requested resource API operation, Resource Server provides the response as per the request.</li>
            <li><span>4.1.</span>API response</li>
        </ol>
    </li>
</ol>

<br>
<h3>Sequence Diagram</h3>
<img src="/assets/mobilisight/sequencediagram.png" />
<br>
<br>
<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>FCL IDP Endpoints for MBS</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td></td>
            <td class="bold">EMEA</td>
            <td class="bold">NAFTA</td>
        </tr>
        <tr>
            <td class="bold">PRE PRODUCTION</td>
            <td>
                <a class="green-highlight">http://mtls.proxy.fcl-01.prep.fcagcv.com/identity/v2/<span class="bold">mobilisight</span>/oauth2/token</a>
            </td>
            <td>
                <u>
                    <a class="green-highlight">http://mtls.proxy.fcl-02.prep.fcagcv.com/identity/v2/<span class="bold">mobilisight</span>/oauth2/token</a>
                </u>
            </td>
        </tr>
        <tr>
            <td class="bold">PRODUCTION</td>
            <td>
                <u>
                    <a>http://mtls.proxy.fcl-01.fcagcv.com/identity/v2/<span class="bold">mobilisight</span>/oauth2/token</a>
                </u>
            </td>
            <td>
                <u>
                    <a>http://mtls.proxy.fcl-02.fcagcv.com/identity/v2/<span class="bold">mobilisight</span>/oauth2/token</a>
                </u>
            </td>
        </tr>
    </table>
</div>

<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>MBS ClientID Credentials (UserIDs)</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td></td>
            <td class="bold">EMEA</td>
            <td class="bold">NAFTA</td>
        </tr>
        <tr>
            <td class="bold">PRE PRODUCTION</td>
            <td>
                2sh5eqt3e85pfrprora2lfeefb
            </td>
            <td>
                <span class="green-highlight">Shared, possessed by Herman Kuntz & Alexandre Godard</span>
            </td>
        </tr>
        <tr>
            <td class="bold">PRODUCTION</td>
            <td>5e68ndp52d526o9n1a7pt7jlvv</td>
            <td>
                Shared, possessed by Herman Kuntz & Alexandre Godard
            </td>
        </tr>
    </table>
</div>

<br>
<h2>OUTBOUND - Stellantis -> Third Party</h2>
<br>
<h3>Networking - Kinesis</h3>
<p class="p-no-padding">Kinesis is a Data Stream service provided by Amazon AWS. Link to the official documentation <a href="https://gsdp.jira.intra.fcagcv.com/browse/CR-4122">HERE</a>.
FCL can be the host of the Kinesis Streams in use for any integration, or it could print out data on Streams hosted by the Third Party itself.
A dedicated Role shall be defined within FCL Systems, for Third Party to be assumed, which shall have READ privileges while accessing the dedicated Streams. A single Role shall be defined for accessing both the Streams.
In order to assume a Role and read a Stream, Third Party shall need to be listed within FCL’s Trusted Relationships settings section, indicating the Account Name with which it will be presenting itself:
</p>

<div class="table-container">
    <table style="margin-bottom: 0px;" class="table-with-blue-header">
        <tr>
            <th>MBS AWS Account IDs in Whitelist</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td></td>
            <td class="bold">EMEA</td>
            <td class="bold">NAFTA</td>
        </tr>
        <tr>
            <td class="bold">PRE PRODUCTION</td>
            <td>
                <span class="green-highlight">910238657047</span>
            </td>
            <td>
                <span class="green-highlight">910238657047</span>
            </td>
        </tr>
        <tr>
            <td class="bold">PRODUCTION</td>
            <td>976324155967</td>
            <td>976324155967</td>
        </tr>
    </table>
</div>
<p class="p-no-padding"><i>IMPORTANT: SecretIDs are also assigned to each Account Name within FCL, in order to restrict the Roles’ accessibility per single Application.</i></p>

<br>
<h3>GCV Data Delivery Stream</h3>
<p class="p-no-padding">This Stream purpose is to share continuous streams of data, related to the Fleet (FTD) or Usage-Based Insurance (UBI) use cases</p>

<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>Kinesis ARN Roles for GCV Data stream</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td></td>
            <td class="bold">EMEA</td>
            <td class="bold">NAFTA</td>
        </tr>
        <tr>
            <td class="bold">PRE PRODUCTION</td>
            <td>
                <span class="green-highlight">139489035434</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:iam::139489035434:role/gcv-dispatcher-data-delivery-consumer-role-MBS
                </span>
            </td>
            <td>
                <span class="green-highlight">054030820656</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:iam::054030820656:role/gcv-dispatcher-data-delivery-consumer-role-MBS
                </span>
            </td>
        </tr>
        <tr>
            <td class="bold">PRODUCTION</td>
            <td>
                <span>806090307827</span>
                <br>
                <br>
                <span>
                    arn:aws:iam::806090307827:role/gcv-dispatcher-data-delivery-consumer-role-MBS
                </span>
            </td>
            <td>
                <span>109251457487</span>
                <br>
                <br>
                <span>
                    arn:aws:iam::109251457487:role/gcv-dispatcher-data-delivery-consumer-role-MBS
                </span>
            </td>
        </tr>
    </table>
</div>

<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>FCL Kinesis GCV Data Stream IDs</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td class="blue-cell"></td>
            <td class="bold blue-cell">EMEA</td>
            <td class="bold blue-cell">NAFTA</td>
        </tr>
        <tr>
            <td class="bold blue-cell">PRE PRODUCTION</td>
            <td>
                <span class="green-highlight">139489035434</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:kinesis:eu-west-1:139489035434:stream/gcv-dispatcher-data-delivery-MBS
                </span>
            </td>
            <td>
                <span class="green-highlight">054030820656</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:kinesis:us-east-1:054030820656:stream/gcv-dispatcher-data-delivery-MBS
                </span>
            </td>
        </tr>
        <tr>
            <td class="bold blue-cell">PRODUCTION</td>
            <td>
                <span>806090307827</span>
                <br>
                <br>
                <span>
                    arn:aws:kinesis:eu-west-1:806090307827:stream/gcv-dispatcher-data-delivery-MBS
                </span>
            </td>
            <td>
                <span>109251457487</span>
                <br>
                <br>
                <span>
                    arn:aws:kinesis:us-east-1:109251457487:stream/gcv-dispatcher-data-delivery-MBS
                </span>
            </td>
        </tr>
    </table>
</div>


<br>
<h3>On-Demand / Callback Stream</h3>
<p class="p-no-padding">This Stream purpose is to share asynchronous events, either requested via API or triggered by an in-vehicle event</p>

<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>Kinesis ARN Roles for On-Demand / Callback stream</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td></td>
            <td class="bold">EMEA</td>
            <td class="bold">NAFTA</td>
        </tr>
        <tr>
            <td class="bold">PRE PRODUCTION</td>
            <td>
                <span class="green-highlight">139489035434</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:iam::139489035434:role/gcv-afe-kinesis-role-access-mbs
                </span>
            </td>
            <td>
                <span class="green-highlight">054030820656</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:iam::054030820656:role/gcv-afe-kinesis-role-access-mbs
                </span>
            </td>
        </tr>
        <tr>
            <td class="bold">PRODUCTION</td>
            <td>
                <span>806090307827</span>
                <br>
                <br>
                <span>
                    arn:aws:iam::806090307827:role/gcv-afe-kinesis-role-access-mbs
                </span>
            </td>
            <td>
                <span>109251457487</span>
                <br>
                <br>
                <span>
                    arn:aws:iam::109251457487:role/gcv-afe-kinesis-role-access-mbs
                </span>
            </td>
        </tr>
    </table>
</div>

<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>
                <span class="black-text">
                    FCL Kinesis
                </span>
                On-Demand / Callback 
                <span class="black-text">
                    Stream IDs
                </span>
            </th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <td class="blue-cell"></td>
            <td class="bold blue-cell">EMEA</td>
            <td class="bold blue-cell">NAFTA</td>
        </tr>
        <tr>
            <td class="bold blue-cell">PRE PRODUCTION</td>
            <td>
                <span class="green-highlight">139489035434</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:kinesis:eu-west-1:139489035434:stream/gcv-afe-callback-consumer-mbs
                </span>
            </td>
            <td>
                <span class="green-highlight">054030820656</span>
                <br>
                <br>
                <span class="green-highlight">
                    arn:aws:kinesis:us-east-1: 054030820656:stream/gcv-afe-callback-consumer-mbs
                </span>
            </td>
        </tr>
        <tr>
            <td class="bold blue-cell">PRODUCTION</td>
            <td>
                <span>806090307827</span>
                <br>
                <br>
                <span>
                    arn:aws:kinesis:eu-west-1:806090307827:stream/gcv-afe-callback-consumer-mbs
                </span>
            </td>
            <td>
                <span>109251457487</span>
                <br>
                <br>
                <span>
                    arn:aws:kinesis:<span class="yellow-highlight">us-east</span>-1:109251457487:stream/gcv-afe-callback-consumer-mbs
                </span>
            </td>
        </tr>
    </table>
</div>

<br>
<h3>IAM Authentication</h3>
<p class="p-no-padding">Authentication criteria are non-public functions nested within AWS Service itself. No credentials need to be used, the whole validation process is done silently by Amazon, its criteria respected by the consumers’ Profiles.</p>


<br>
<h3>Data Retention</h3>
<p class="p-no-padding">The standard data retention set for Kinesis Streams is 7 DAYS. It is configurable to reach up to 14 DAYS.</p>
<p class="p-no-padding"><span class="bold">The current Data Retention set value for the GCV Data Stream is X Days 7 Days<span></p>
<p class="p-no-padding"><span class="bold">The current Data Retention set value for the Callback Data Stream is 3 Days<span></p>


<br>
<br>
<h1><strong><span>FCL APIs Primary Details</span></strong></h1>
<h2>Base URLs</h2>

<div class="table-container">
    <table style="margin-bottom: 0px;" class="table-with-blue-header">
        <tr>
            <th></th>
            <th>EMEA</th>
            <th>NAFTA</th>
        </tr>
        <tr>
            <td class="bold blue-cell">PRE PRODUCTION</td>
            <td>
                <a href="https://mtls.proxy.fcl-01.prep.intra.fcagcv.com/partners/">https://mtls.proxy.fcl-01.prep.intra.fcagcv.com/partners/</a>
            </td>
            <td>
                <a href="https://mtls.proxy.fcl-02.prep.intra.fcagcv.com/partners/">https://mtls.proxy.fcl-02.prep.intra.fcagcv.com/partners/</a>
            </td>
        </tr>
        <tr>
            <td class="bold blue-cell">PRODUCTION</td>
            <td>
                <a href="https://mtls.proxy.fcl-01.intra.fcagcv.com/partners/">https://mtls.proxy.fcl-01.intra.fcagcv.com/partners/</a>
            </td>
            <td>
                <a href="https://mtls.proxy.fcl-02.intra.fcagcv.com/partners/">https://mtls.proxy.fcl-02.intra.fcagcv.com/partners/</a>
            </td>
        </tr>
    </table>
</div>

<p class="yellow-highlight p-no-padding bold" style="color: red; margin-top: 2rem; font-size: 22px; width: fit-content">
    <u>
        MBS PARTNER ID IN FCL = “ MBS “ (everywhere but the Callback Stream)
    </u>
</p>

<br>
<h2>Response Codes</h2>
<div class="table-container">
    <table class="table-with-blue-header">
        <tr>
            <th>Response Code</th>
            <th>HTTP Code</th>
            <th>Response Meaning</th>
            <th>Retry Strategy</th>
        </tr>
        <tr>
            <td>2000</td>
            <td class="bold">200</td>
            <td>Sucess, OK</td>
            <td>NA</td>
        </tr>
        <tr>
            <td>5000</td>
            <td class="bold">500</td>
            <td>Generic Error</td>
            <td></td>
        </tr>
        <tr>
            <td>5001</td>
            <td class="bold">401</td>
            <td>Unauthorized</td>
            <td></td>
        </tr>
        <tr>
            <td>5002</td>
            <td class="bold">204</td>
            <td>Missing</td>
            <td></td>
        </tr>
        <tr>
            <td>5003</td>
            <td class="bold">400</td>
            <td>Invalid parameter</td>
            <td></td>
        </tr>
        <tr>
            <td>5005</td>
            <td class="bold">403</td>
            <td>Request denied</td>
            <td></td>
        </tr>
        <tr>
            <td>5006</td>
            <td class="bold">418</td>
            <td>Deprecated</td>
            <td></td>
        </tr>
        <tr>
            <td>5007</td>
            <td class="bold">204</td>
            <td>Vehicle not found</td>
            <td></td>
        </tr>
    </table>
</div>

<br>
<h2>Limitations</h2>
<p class="p-no-padding">Most of the APIs FCL is exposing towards third parties, regardless of their purpose, do not suffer from any specific technical limitations in terms reachability: those could be invoked 24/7, and any number of inputs could be handled, since the AWS Structure they are based on is completely scalable.
</p>
<p>
Despite this fact, the majority of such inputs (Opt-In/Out requests excluded) shall be redirected by FCL towards the designated supplier system, in charge of the direct interactions towards the Onboard black box module.
</p>
<p>
This given, and due to the black boxes’ physical limitation in terms of networking, some of the commands might imply the need for caution when used recursively: <span class="bold"><i>you can find every specific limitation in the dedicated API Tables within this document.
</i></span></p>
`