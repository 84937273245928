
export const SERVICE_ACTIVATION = `
<h1><strong><span>Services Activation (AFE - Automatic Fleet Enrollment)</span></strong></h1>
<p>This is the first step any data receptor must first take when one or more new Vehicles need to be accessed, for any of the following purposes:</p>
<ul>
    <li>UBI - Data Monetization packages requested in the Kinesis GCV Stream</li>
    <li>FTD - Fleet Telematic Data packages requested in the Kinesis GCV Stream</li>
    <li>RUA - Radio Usage Analytics Data packages requested in the Kinesis GCV Stream</li>
    <li>PHEV - Electric Motors data requests + operations accessibility</li>
    <li>RO - Remote Operations accessibility</li>
</ul>

<img src="/assets/mobilisight/SERVICEACTIVATIONARCH.png" />
<p>
The above represented procedure needs to be executed according to the following workflow, independently from the VIN identity (Brand/Model/Series), mounted telematic box (TBM, MC), SalesCluster (B2B/B2C) or Region (NAFTA, EMEA):</span></p>
<ol>
    <li>The Third Party requests an <strong>Eligibility Check (8.1.1)</strong> on the desired list of VINs. They shall receive a synchronous Response containing an array of Services of which the inputted VINs are capable of</li>
    <li>The Third Party requests a <strong>Service Activation (8.1.2)&nbsp;</strong>) for the desired list of VINs, inputting all the needed services (in accordance with the point 2 response) and their duration (in Months)</li>
    <li>FCL reroutes the Service Activation request towards the correct Provider (Ignite for TBM VINs, Targa for MC VINs)</li>
    <li>The Provider activates the Services on the VINs -> from now on and until the Services expiration, it shall be possible for the VINs to generate GCV Data, and for the Third Party to submit On-Demand requests on those</li>
    <li>The Provider sends a Callback event towards FCL, stating the results of the requested operation</li>
    <li>FCL pushes the Callback Event towards the Kinesis On-Demand Stream OR a dedicated API exposed by the partner, for the Third Party to be acknowledged</li>
</ol>
<h2>NOTES</h2>
<ul>
    <li>The Third Party can check for their <strong>Service Activation (8.1.1)&nbsp;</strong> requests status, even if they still did not receive a dedicated callback, by invoking the <strong>&nbsp;Service Provisioning Status (8.1.3)</strong>API<ol class="decimal_type">
            <li>Please note that this request does not generate a callback: its results shall be part of the synchronous API response</li>
        </ol>
    </li>
    <li>The Third Party can modify a service’s duration on any number of VINs anytime, by invoking a new <strong>Service Activation (8.1.1)&nbsp;</strong> request with a different duration. This command shall flow according to the main process, hence generating a new dedicated callback on the Kinesis On-Demand Stream<ol class="decimal_type">
            <li>Please note that the start date  SHALL be impacted by this call, so the new duration has to be calculated according to the LAST inputted</li>
        </ol>
    </li>
    <li>The Third Party can request for an immediate interruption of one or more Services on any VIN, by invoking the <strong>Service  Deactivation (8.1.4)</strong>API<ol>
            <li>Please note that this request does not generate a callback: its results shall be part of the synchronous API response</li>
            <li>Please use this command, followed by a new standard activation procedure, whenever an Ownership Change procedure is needed</li>
        </ol>
    </li>
    <li><u>Any reference to MC Vehicles is related to EMEA Region only</u></li>
</ul>`