import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import logout from "../../images/logout.svg";
import { useSelector } from "react-redux";
import './headers.css'
import { checkUserToken } from "../../utils/CheckUserToken";
import { ADMIN_EMAIL } from "../../constant/APIConstants";

const Headers = () => {
  const [LoggedInState, setLoggedInState] = useState(false);

  const { loading, userInfo, userToken, error, success } = useSelector(
    (state) => state.auth
  );
  const data = useSelector(
    (state) => state.UserData
  );
  var email = data?.UserData && data.UserData.api_account_owner;


  const activeItemRef = useRef(null);

  const handleItemClick = (event) => {
    if (activeItemRef.current) {
      activeItemRef.current.classList.remove("active");
    }
    event.currentTarget.classList.add("active");
    activeItemRef.current = event.currentTarget;
  };

  useEffect(() => {
    if (checkUserToken()) {
      setLoggedInState(true);
    }
  }, [Navigate]);

  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const AdminEmails = ADMIN_EMAIL.split(",")
  return (
    <>
      {LoggedInState ? (
        <>
          <button className={`toggle-button `} onClick={handleToggleCollapse}>
            <i class="fa-solid fa-bars"></i>
          </button>
          <div
            className={`col-md-3 col-lg-3 p-1 d-lg-block ${collapsed ? " collapse" : ""
              }`}
          >
            <div className="position-sticky">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button  collapsed btn-md"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Accounts
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul className="nav flex-column">
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/services/account/accountDetails"
                            className="text-sm nav-link active"
                            style={{ fontSize: "12px" }}
                          >
                            Account Details
                          </Link>
                        </li>

                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/Services/account/link-api-account"
                            className="text-sm nav-link active"
                            style={{ fontSize: "12px" }}
                          >
                            Link Connected Vehicle
                          </Link>
                        </li>
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/Services/account/UnLink-api-account"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Unlink Vehicle Account
                          </Link>
                        </li>
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/Services/account/UpdateAccountPassword"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Update API Account Password
                          </Link>
                        </li>
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/Services/account/ResetPassword"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Reset Password Via Email
                          </Link>
                        </li>
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/Services/account/PermanentlyDeleteAccount"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Permanently Delete API account
                          </Link>
                        </li>
                        {AdminEmails.includes(email) &&
                          <li className="nav-item nav-link text-sm">
                            <Link
                              to="/services/account/approve_account"
                              className="text-sm nav-link"
                              style={{ fontSize: "12px" }}
                            >
                              Approve Account
                            </Link>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button btn-md collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Vehicle Data
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul className="nav flex-column">
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/services/vehicle-data/RecentSensorValues"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Retrieves Recent Data(Sensor Values)
                          </Link>
                        </li>
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/services/vehicle-data/LastKnownDataVIN"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Retrieves Last Known Data Sensor Values
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button btn-md collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Remote Commands
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body p-0">
                      <ul className="nav flex-column">
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/remote-commands/send-remote-commands"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Send Remote Commands To Vehicle{" "}
                          </Link>
                        </li>
                        <li className="nav-item nav-link text-sm">
                          <Link
                            to="/remote-commands/send-popup"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Send popup to vehicle{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button btn-md collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-feedback"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Feedback{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-feedback"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body p-0">
                      <ul className="nav flex-column">
                        <li
                          className="nav-item nav-link text-sm"
                          onClick={handleItemClick}
                        >
                          <Link
                            to="/feedback/submit-general-feedback"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Submit General Feedback.
                          </Link>
                        </li>
                        <li
                          className="nav-item nav-link text-sm"
                          onClick={handleItemClick}
                        >
                          <Link
                            to="/feedback/submit-bug-report"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Submit Bug Report
                          </Link>
                        </li>
                        <li
                          className="nav-item nav-link text-sm"
                          onClick={handleItemClick}
                        >
                          <Link
                            to="/feedback/submit-support-request"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Submit Support Request
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button btn-md collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      Geofencing
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse collapse5"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body p-0">
                      <ul className="nav flex-column">
                        <li
                          className="nav-item nav-link text-sm"
                          onClick={handleItemClick}
                        >
                          <Link
                            to="/services/geofencing/geofence-collections"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Geofence Collections
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button btn-md collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      Slimulator
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse collapse5"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body p-0">
                      <ul className="nav flex-column">
                        <li
                          className="nav-item nav-link text-sm"
                        >
                          <Link
                            to="/Slimulator"
                            className="text-sm nav-link"
                            style={{ fontSize: "12px" }}
                          >
                            Slimulator
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Headers;
