import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SendPopupAPICall } from "./SendPopupAPI";
import { getUserToken, getApiKey } from "../../utils/utility";

const SendPopup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const API_KEY = getApiKey();
    const TOKEN = getUserToken();
    const [showSpinner, setShowSpinner] = useState(true);
    const [sendPopupAPIMessage, setSendPopupAPIMessage] = useState(null);
    const [popupMessage, setPopupMessage] = useState("");
    const [buttonText1, setButtonText1] = useState("");
    const [buttonText2, setButtonText2] = useState("");
    const [VIN, setVIN] = useState(null);


    const data = useSelector(
        (state) => state.UserData
    );

    useEffect(() => {
        if (data) {
            setShowSpinner(false);
        }
    }, [data]);

    const handleChange = (event) => {
        setVIN(event.target.value);
    };

    const callSendPopup = (e) => {
        e.preventDefault()
        setSendPopupAPIMessage(null);
        setShowSpinner(true);

        if (!popupMessage.match(/^[0-9a-z]+$/) || (buttonText1 !== "" && !buttonText1.match(/^[0-9a-z]+$/)) || (buttonText2 !== "" && !buttonText2.match(/^[0-9a-z]+$/))) {
            setShowSpinner(false);
            setSendPopupAPIMessage("Error: Please enter alphanumeric values for these fields.")
        }
        else {
            let obj = {
                "popup_text": popupMessage
            }

            if (buttonText1 !== "") {
                obj["button_text_1"] = buttonText1
            }

            if (buttonText2 !== "") {
                obj["button_text_2"] = buttonText2
            }

            SendPopupAPICall(VIN, obj, TOKEN, API_KEY)
                .then((resp) => {
                    setShowSpinner(false);
                    if (resp.data) {
                        setVIN(null);
                        setPopupMessage("");
                        setButtonText1("");
                        setButtonText2("");
                        setSendPopupAPIMessage(JSON.stringify(resp.data));
                    }
                    else if (resp.response?.data) {
                        setSendPopupAPIMessage(JSON.stringify(resp.response?.data));
                    }
                    else {
                        setSendPopupAPIMessage(resp.stack)
                    }

                })
                .catch((err) => {
                    setShowSpinner(false);
                    setSendPopupAPIMessage(err.response?.data);
                });

        }
    }

    return (
        <>
            <Box sx={{ display: "flex", paddingTop: "1rem", paddingBottom: "1rem" }}>
                <Typography variant="h5">Send Popup to Vehicle</Typography>
            </Box>

            <form autoComplete="off" onSubmit={callSendPopup} className="col-12 max-md-w-6  mt-4" style={{ "max-width": "50%" }}>
                <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
                    <InputLabel id="demo-simple-select-label">VIN</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={VIN}
                        label="VIN"
                        onChange={handleChange}
                        required
                    >
                        {(data?.UserData && data?.UserData?.owned_vins) ? Object.keys(data.UserData.owned_vins).map((vin, index) =>
                            <MenuItem value={vin}>{vin}</MenuItem>
                        )
                            :
                            <></>}
                    </Select>
                </FormControl>
                <TextField
                    label="Popup Message (max: 40 characters)"
                    onChange={e => setPopupMessage(e.target.value)}
                    variant="outlined"
                    required
                    color="primary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    multiline
                    value={popupMessage}
                    inputProps={{ maxLength: 40 }}
                />
                <TextField
                    label="Button Text 1 (max: 15 characters)"
                    onChange={e => setButtonText1(e.target.value)}
                    variant="outlined"
                    color="primary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={buttonText1}
                    inputProps={{ maxLength: 40 }}
                />
                <TextField
                    label="Button Text 2 (max: 15 characters)"
                    onChange={e => setButtonText2(e.target.value)}
                    variant="outlined"
                    color="primary"
                    type="text"
                    value={buttonText2}
                    inputProps={{ maxLength: 40 }}
                    fullWidth
                    sx={{ mb: 3 }}
                />
                <Button variant="contained" className="btn btn-primary btn-block" type="submit">Send popup</Button>

            </form>

            {
                sendPopupAPIMessage ?
                    <Typography variant="body1" mt={3} pl={2}>
                        {sendPopupAPIMessage}
                    </Typography>
                    :
                    <></>
            }
        </>
    );
}

export default SendPopup;