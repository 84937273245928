import React from "react";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { swagger } from "./swagger";
import "./style.css";
// import VehicleCapabilitiesImg from "../../images/vehicle_capabilities.png";

import SwaggerPageGenerator from "../../components/SwaggerPageGenerator/SwaggerPageGenerator";
import Banner from "../../components/Banner/Banner";

const VehicleCap_base = () => {
  const theme = useTheme();

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const beinsp = {"Trips":[
    {
      heading:"Trip Report",
      text:"Be inspired by the Trip Report Stellantis feature. Enjoy the trip statistics and all the data of your trips!",
      image:"/images/destination.png",
      link:"/TripReport"
  }]}
  return (
    <>
      <div>
      <Banner 
      image={"./images/bannerVehCap.png"} 
      heading={"Meet our vehicle capabilities"} />
      </div>
      <br />
      <div className='page_box'>
        <div className='box_container'>
          <SwaggerPageGenerator swagger={swagger} defaultView={true} beInspiredTags={beinsp}/>
        </div>
      </div>
    </>
  );
};

export default VehicleCap_base;
