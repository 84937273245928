import { createSlice } from "@reduxjs/toolkit";
import { UserData } from "../Actions/UsersData";


const initialState = {
  loading: true,
  UserData: {},
  error: null,
  success: false,
};

export const UserDataSlice = createSlice({
  name: "UserData",
  initialState,
  reducers: {},
  extraReducers: {
    [UserData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [UserData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.UserData = payload;
    },
    [UserData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    
  },
});

export default UserDataSlice.reducer;
