import React, { useState, useEffect, Suspense } from "react";
import Banner from '../../components/Banner/Banner'
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
const Catalogue = React.lazy(() => import('../../components/Catalogue/Catalogue'));
 
const OnboardPage  = () => {
  const location = useLocation();
  const [onboardCatalogue, setOnboardCatalogue] = useState(null);
  const [ErrorState, setErrorState] = useState(false)
  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    fetch('/OnboardCatalogue.json')
      .then(response => response.json())
      .then(data => {
        setOnboardCatalogue(data)
        setLoading(false)

      })
      .catch(error => {
        console.error('Error loading OnboardCatalogue:', error)
        setErrorState(true)
        setLoading(false)
      
      });
  }, []);
 
    return (

        <>
          <div>
          <Banner image={"/images/onboardBanner.png"} heading={"Stella Brain Onboard"} />
    
            
          </div>
          <br />

          {Loading &&
                <>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              </>}
              { ErrorState && (<div>
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>)}
          

          {onboardCatalogue && <Suspense fallback={
              <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}>
        <div className='page_box'>
            <div className='box_container'>
            <Catalogue Catalogue={onboardCatalogue} type="Accordion" />
            </div>
          </div></Suspense>}
          
          
        </>
      );
}
export default OnboardPage