import React from "react";
import { useState } from "react";
import BrandCard from "../../components/BrandCards/BrandCard";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import "./style.css";

const Firstpage = () => {
  const theme = useTheme();  

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div>
        <div className="banner">
          <img
            src="./images/alfa-parts_cta_fullsize.jpg"
            className="img-fluid"
            style={{height:"55vh", objectFit:"cover"}}
            alt="Banner Image"
          />
          <div className="text-white">
            <h1 className="text-right text-white">the mechanics of emotions</h1>
          </div>
        </div>
        <br />
        <br />
        <div className="container-fluid" id="test1">
          <div className="container-fluid headings1">
            <h1>Discover the features available</h1>
            <p className="text-lg">lorem ipsum bla cvmp bu gsdp ole</p>

            <br />
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    allowScrollButtonsMobile
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    textColor="#A2202C"
                    indicatorColor="#A2202C"
                    TabIndicatorProps={{
                      style: {
                        background: "#A2202C",
                        fontSize: "20px",
                        color: "#A2202C",
                        height: "10px",
                        bottom: 0,
                      },
                    }}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="Trips and Navigation"
                      value="1"
                    />
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="Remote Operations"
                      value="2"
                    />
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="Vehicle health"
                      value="3"
                    />
                    <Tab
                      sx={{
                        fontWeight: 400,
                        fontSize: 25,
                      }}
                      label="EV Charging"
                      value="4"
                    />
                  </TabList>
                </Box>
                <TabPanel
                  value="2"
                  sx={{
                    padding: 0,
                  }}
                >
                  <div className="row" p-0>
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                  </div>
                </TabPanel>
                <TabPanel
                  value="1"
                  sx={{
                    padding: 0,
                  }}
                >
                  <div className="row">
                    <BrandCard
                      heading="Trip <br />Report"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/destination.png"
                      link="/TripReport"
                    />
                    <BrandCard
                      heading="Eco <br />Coaching"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/leaf.png"
                      link="#"
                    />
                    <BrandCard
                      heading="Send <br />Destination"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/carfinder.png"
                      link="#"
                    />
                    <BrandCard
                      heading="Calendar <br />Integration"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Dynamic Range <br />Mapping"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/map.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Vehicle <br />Finder"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                  </div>
                </TabPanel>
                <TabPanel
                  value="3"
                  sx={{
                    padding: 0,
                  }}
                >
                  <div className="row">
                    <BrandCard
                      heading="Trip <br />Report"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                  </div>
                </TabPanel>
                <TabPanel
                  value="4"
                  sx={{
                    padding: 0,
                  }}
                >
                  <div className="row">
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                    <BrandCard
                      heading="Remote Operations"
                      text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      image="./images/calender.png"
                      link="/remote_ops"
                    />
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default Firstpage;
