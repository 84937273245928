import React,{useEffect} from 'react'
import { useSelector } from 'react-redux'
import './style.css'

const Profile = () => {
    const data = useSelector(
        (state) => state.UserData
      );
      console.log(data)
      useEffect(() => {
        console.log(data)      }, [data])
      
  return (
    <div>
        <div className='pt-5 profile' style={{paddingLeft:"120px", paddingRight:"120px"}}>
            <h2>User Profile</h2>
            <p>Here you can find the information related to your account</p>
            <div className='p-3'>
                <ul className='list-unstyled'>
                    <div style={{borderRadius:"15px", backgroundColor:"#F6F6F6", paddingLeft:"20px"}}><li><p ><strong>API Account Owner:</strong></p> <p className='ps-3'>{data.UserData.api_account_owner}</p></li></div>
                    <div style={{borderRadius:"15px", backgroundColor:"#F6F6F6", paddingLeft:"20px", marginTop:"20px"}}><li><p ><strong>FIRST NAME:</strong></p> <p className='ps-3'>{data.UserData.first_name}</p></li></div>
                    <div style={{borderRadius:"15px", backgroundColor:"#F6F6F6", paddingLeft:"20px", marginTop:"20px"}}><li><p ><strong>LAST NAME:</strong></p> <p className='ps-3'>{data.UserData.last_name}</p></li></div>

                </ul>
            </div>
        </div>
      
    </div>
  )
}

export default Profile
