export const swagger = {
    "openapi": "3.0.3",
    "info": {
        "title": "Swagger Petstore - OpenAPI 3.0",
        "description": "This is a sample Pet Store Server based on the OpenAPI 3.0 specification.  You can find out more about\nSwagger at [https://swagger.io](https://swagger.io). In the third iteration of the pet store, we've switched to the design first approach!\nYou can now help us improve the API whether it's by making changes to the definition itself or to the code.\nThat way, with time, we can improve the API in general, and expose some of the new features in OAS3.\n\n_If you're looking for the Swagger 2.0/OAS 2.0 version of Petstore, then click [here](https://editor.swagger.io/?url=https://petstore.swagger.io/v2/swagger.yaml). Alternatively, you can load via the `Edit > Load Petstore OAS 2.0` menu option!_\n\nSome useful links:\n- [The Pet Store repository](https://github.com/swagger-api/swagger-petstore)\n- [The source API definition for the Pet Store](https://github.com/swagger-api/swagger-petstore/blob/master/src/main/resources/openapi.yaml)",
        "termsOfService": "http://swagger.io/terms/",
        "contact": {
            "email": "apiteam@swagger.io"
        },
        "license": {
            "name": "Apache 2.0",
            "url": "http://www.apache.org/licenses/LICENSE-2.0.html"
        },
        "version": "1.0.11"
    },
    "externalDocs": {
        "description": "Find out more about Swagger",
        "url": "http://swagger.io"
    },
    "servers": [
        {
            "url": "https://petstore3.swagger.io/api/v3"
        }
    ],
    "tags": [
        {
            "name": "Service Activation",
            "description": "Activate the service. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
        },
        {
            "name": "On-Demand Services",
            "description": "On-Demand Services. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
        },
        {
            "name": "Remote Commands",
            "description": "Perform remote commands. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
        },
        {
            "name": "Real Time Data Feed",
            "description": "Data Stream. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
        }
    ],
    "paths": {
        "/v1/partners/{partnerId}/vehicles/check-eligibility": {
            "post": {
                "tags": [
                    "Service Activation"
                ],
                "summary": "Elegibility Check",
                "description": "This API will check the eligibility of a set of vins for a partnerId, for the On-Demand services. <strong>\"vins\" field of this API supports an array of VINs taken in input, up to 20 entries</strong>",
                "operationId": "updatePet",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "check the eligibility of a set of vins for a partnerId",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CheckEligibility"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CheckEligibilityResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/service-provisioning": {
            "post": {
                "tags": [
                    "Service Activation"
                ],
                "summary": null,
                "description": "This API will activate a set of services on a set of vins for a PartnerId (PartnerProfile)<br> Mandatory fields <br>\"vins\"  <br>\"services\" <br>\"correlationId\"  <br>\"start\"  <br>\"duration\" <br>vins array, up to 100 entries  duration in Months  services it is possible to input whatever list of needed services, always according to the vehicle's capabilities which can be checked for with the dedicated API (see Chapter 8.2.1). The full Services list matrix can be found at Chapter 8.2.2.1",
                "operationId": "t",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "check the status of activation of the services for a set of vins",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CheckServiceProvisioning"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CheckServiceProvisioningResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/check-service-provisioning-status": {
            "post": {
                "tags": [
                    "Service Activation"
                ],
                "summary": "check status of services provisioned",
                "description": "This API will check the status of activation of the services for a set of vins",
                "operationId": "test",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "check the status of activation of the services for a set of vins",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CheckEligibility"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CheckServiceProvisioningResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/ownership-change": {
            "post": {
                "tags": [
                    "Service Activation"
                ],
                "summary": "Service Deactivation / Ownership Change",
                "description": "This API will disassociate a VIN from a partner",
                "operationId": "owner",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "disassociate a VIN from a partner",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CheckEligibility"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CheckServiceProvisioningResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/info": {
            "get": {
                "tags": [
                    "Remote Commands"
                ],
                "summary": "Service Deactivation / Ownership Change",
                "description": "This API will disassociate a VIN from a partner",
                "operationId": "rc",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/VehicleInfoResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/lcoation": {
            "get": {
                "tags": [
                    "Remote Commands"
                ],
                "summary": "Vehicle Location",
                "description": "This API send the vehicle location to third party",
                "operationId": "rcloc",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/VehicleLocationResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/privacymode/request": {
            "post": {
                "tags": [
                    "Remote Commands"
                ],
                "summary": "Privacy Mode Management",
                "description": "This API will enable the users to set the privacy mode for the vehicle<br>Service required <strong>PRIVACY",
                "operationId": "rclocprivacy",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "enable the users to set the privacy mode for the vehicle.",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/PrivacyRequestBody"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PrivacymodeResponsePOST"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/remote": {
            "post": {
                "tags": [
                    "Remote Commands"
                ],
                "summary": "Basic Remote Command",
                "description": "API for remote operations given to partners",
                "operationId": "rcloccommand",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "API for remote operations given to partners",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/BasicRemoteCommand"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PrivacymodeResponsePOST"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/remote/status": {
            "get": {
                "tags": [
                    "Remote Commands"
                ],
                "summary": "Remote Status",
                "description": "This API send the remote status to third party",
                "operationId": "rclocremotestatus",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/RemoteStatusResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/enrollment/profileids/{profileId}/eligibility": {
            "get": {
                "tags": [
                    "Real Time Data Feed"
                ],
                "summary": "Eligibility Check - GCV",
                "description": "This API will check the eligibility of a set of vins for a partnerId, for the GCV Stream services",
                "operationId": "gvcprofile",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "profileId",
                        "in": "path",
                        "description": "profile identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/EligibilityGVCDataStream"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/enrollment": {
            "post": {
                "tags": [
                    "Real Time Data Feed"
                ],
                "summary": "Enrollment",
                "description": "This API would be called by Partner to manually enroll a vehicle to receive signals data from it.",
                "operationId": "GVCenrollment",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "This API would be called by Partner to manually enroll a vehicle to receive signals data from it",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/EnrollmentRequestPayload"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Successful operation"
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/[partnerId]/vehicles/[vin]/enrollment": {
            "delete": {
                "tags": [
                    "Real Time Data Feed"
                ],
                "summary": "Enrollment",
                "description": "This API would be called by Partner to manually enroll a vehicle to receive signals data from it.",
                "operationId": "GVCenrollmentDelete",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation"
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/ev": {
            "post": {
                "tags": [
                    "On-Demand Services"
                ],
                "summary": "PHEV Info (Deep Refresh",
                "description": "This API is used to request a manual refresh of the PHEV Stored data. The vehicle is queried for the last status, and an updated PHEV Data package is then sent within the Callback Stream.",
                "operationId": "PHEVinfo",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/evResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/ev/chargenow": {
            "post": {
                "tags": [
                    "On-Demand Services"
                ],
                "summary": "Charge Now",
                "description": "API to start the charging process for the given vehicle.",
                "operationId": "chargenowev",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/evResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/ev/chargestop": {
            "post": {
                "tags": [
                    "On-Demand Services"
                ],
                "summary": "Charge Stop",
                "description": "API to stop the charging process for the given vehicle with an MC box.",
                "operationId": "chargenowevstop",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/evResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/ev/preference": {
            "post": {
                "tags": [
                    "On-Demand Services"
                ],
                "summary": "Charge Preferences Set",
                "description": "API to start the charging preference for the vehicle (input power, voltage, etc).",
                "operationId": "chargenowevstopprefrences",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "API to delete schedules for the charging and climate in vehicles with MC box.",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ChargeSchedulePreferencesRequestPayload"
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/evResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/{partnerId}/vehicles/{vin}/ev/schedule": {
            "post": {
                "tags": [
                    "On-Demand Services"
                ],
                "summary": "Charge Schedule Set",
                "description": "API to set schedules for the charging and climate in the vehicle. It is possible to input only one type of schedule per request, depending on the vehicle capability.",
                "operationId": "chargenowscheduleset",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "set schedules for the charging and climate in the vehicle.",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ChargeScheduleSetRequestPayload"
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/evResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        },
        "/v1/partners/[partnerId]/vehicles/{vin}/ev/schedule": {
            "delete": {
                "tags": [
                    "On-Demand Services"
                ],
                "summary": "Charging Schedule Delete",
                "description": "API to delete schedules for the charging and climate in vehicles with MC box.<br> Service required <strong> CPPLUS_MC</strong>.<br> This command generates a callback event, to be read within the Callback Kinesis Stream.",
                "operationId": "GVCenrollmentDelete",
                "parameters": [
                    {
                        "name": "Authorization",
                        "in": "header",
                        "description": "Bearer {Access Token}",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "description": "API key",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "partnerId",
                        "in": "path",
                        "description": "id of the partner",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "description": "vehicle identifier number",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "description": "set schedules for the charging and climate in the vehicle.",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ChargeScheduleDeleteRequestPayload"
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "Successful operation",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/evResponse"
                                }
                            }
                        }
                    },
                    "204": {
                        "description": "Vehicle not found/Missing"
                    },
                    "400": {
                        "description": "Invalid parameter"
                    },
                    "401": {
                        "description": "Unauthorized"
                    },
                    "403": {
                        "description": "Request Denied"
                    },
                    "418": {
                        "description": "Deprecated"
                    },
                    "500": {
                        "description": "Generic Error"
                    }
                }
            }
        }
    },
    "components": {
        "schemas": {
            "ChargeScheduleDeleteRequestPayload": {
                "type": "object",
                "properties": {
                    "scheduleType": {
                        "type": "string",
                        "example": "CLIMATE"
                    },
                    "scheduleIds": {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "example": [
                                1,
                                2,
                                3
                            ]
                        }
                    }
                }
            },
            "ScheduleV3Object": {
                "type": "object",
                "properties": {
                    "enableScheduleType": {
                        "type": "boolean"
                    },
                    "scheduleType": {
                        "type": "string",
                        "example": "CHARGE"
                    },
                    "climateSettings": {
                        "type": "object",
                        "properties": {
                            "celsius": {
                                "type": "string",
                                "example": "24.5"
                            },
                            "fahrenheit": {
                                "type": "integer",
                                "example": 77
                            }
                        }
                    },
                    "climateParameters": {
                        "type": "string",
                        "example": "PARAMS_NOT_SET"
                    },
                    "chargeToFull": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    },
                    "scheduledDays": {
                        "type": "object",
                        "properties": {
                            "monday": {
                                "type": "boolean"
                            },
                            "tuesday": {
                                "type": "boolean"
                            },
                            "wednesday": {
                                "type": "boolean"
                            },
                            "thursday": {
                                "type": "boolean"
                            },
                            "friday": {
                                "type": "boolean"
                            },
                            "saturday": {
                                "type": "boolean"
                            },
                            "sunday": {
                                "type": "boolean"
                            }
                        }
                    },
                    "repeatSchedule": {
                        "type": "boolean"
                    }
                }
            },
            "ScheduleV2Object": {
                "type": "object",
                "properties": {
                    "enableScheduleType": {
                        "type": "boolean"
                    },
                    "scheduleType": {
                        "type": "string",
                        "example": "CHARGE"
                    },
                    "chargeToFull": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    },
                    "cabinPriority": {
                        "type": "boolean"
                    },
                    "scheduledDays": {
                        "type": "object",
                        "properties": {
                            "monday": {
                                "type": "boolean"
                            },
                            "tuesday": {
                                "type": "boolean"
                            },
                            "wednesday": {
                                "type": "boolean"
                            },
                            "thursday": {
                                "type": "boolean"
                            },
                            "friday": {
                                "type": "boolean"
                            },
                            "saturday": {
                                "type": "boolean"
                            },
                            "sunday": {
                                "type": "boolean"
                            }
                        }
                    },
                    "repeatSchedule": {
                        "type": "boolean"
                    }
                }
            },
            "ChargeScheduleObject": {
                "type": "object",
                "properties": {
                    "enableChargeSchedule": {
                        "type": "string",
                        "example": "DEFAULT"
                    },
                    "scheduleType": {
                        "type": "string",
                        "example": "WEEKDAY"
                    },
                    "chargeUntillFull": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    }
                }
            },
            "ChargeScheduleSetRequestPayload": {
                "type": "object",
                "properties": {
                    "chargeSchedules": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ChargeScheduleObject"
                        }
                    },
                    "schedulesV2": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ScheduleV2Object"
                        }
                    },
                    "schedulesV3": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ScheduleV3Object"
                        }
                    },
                    "schedulesTarga": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/SchedulesTarga"
                        }
                    }
                }
            },
            "ChargeSchedulePreferencesRequestPayload": {
                "type": "object",
                "properties": {
                    "preference": {
                        "type": "string",
                        "example": "LEVEL_ONE"
                    }
                }
            },
            "EnrollmentRequestPayload": {
                "type": "object",
                "properties": {
                    "b2bPartnerProfileId": {
                        "type": "string"
                    },
                    "expirationTimestamp": {
                        "type": "integer"
                    }
                }
            },
            "EligibilityGVCDataStream": {
                "type": "object",
                "properties": {
                    "deviceType": {
                        "type": "string",
                        "example": "MC",
                        "enum": [
                            "MC",
                            "TBM"
                        ]
                    },
                    "eligibility": {
                        "type": "boolean"
                    },
                    "vehicleAttributes": {
                        "type": "object",
                        "properties": {
                            "color": {
                                "type": "string"
                            },
                            "year": {
                                "type": "integer"
                            },
                            "radio": {
                                "type": "string"
                            },
                            "fuelType": {
                                "type": "string"
                            },
                            "modelDescription": {
                                "type": "string"
                            },
                            "make": {
                                "type": "string"
                            },
                            "sdp": {
                                "type": "string"
                            },
                            "market": {
                                "type": "string"
                            },
                            "soldRegion": {
                                "type": "string"
                            },
                            "navigator": {
                                "type": "string"
                            },
                            "tcuType": null,
                            "company": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "RemoteStatusResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    },
                    "eventTime": {
                        "type": "string"
                    },
                    "doors": {
                        "type": "object",
                        "properties": {
                            "driver": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    }
                                }
                            },
                            "passenger": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    }
                                }
                            },
                            "leftRear": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    }
                                }
                            },
                            "rightRear": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "trunk": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            }
                        }
                    },
                    "hatch": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            }
                        }
                    },
                    "gloveBox": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            }
                        }
                    },
                    "ramBox": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            }
                        }
                    },
                    "liftGate": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            },
                            "lockStatus": {
                                "type": "string"
                            }
                        }
                    },
                    "engine": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            }
                        }
                    },
                    "hvac": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            },
                            "temperature": {
                                "type": "object",
                                "properties": {
                                    "unit": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "integer"
                                    }
                                }
                            }
                        }
                    },
                    "autoOnComfort": {
                        "type": "object",
                        "properties": {
                            "status": {
                                "type": "string"
                            }
                        }
                    },
                    "windows": {
                        "type": "object",
                        "properties": {
                            "driver": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    },
                                    "rate": {
                                        "type": "integer"
                                    }
                                }
                            },
                            "passenger": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    },
                                    "rate": {
                                        "type": "integer"
                                    }
                                }
                            },
                            "leftRear": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    },
                                    "rate": {
                                        "type": "integer"
                                    }
                                }
                            },
                            "rightRear": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    },
                                    "rate": {
                                        "type": "integer"
                                    }
                                }
                            },
                            "sunroof": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    }
                                }
                            },
                            "ramRear": {
                                "type": "object",
                                "properties": {
                                    "status": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "BasicRemoteCommand": {
                "type": "object",
                "properties": {
                    "command": {
                        "type": "string",
                        "example": "HBLF",
                        "enum": [
                            "HBLF",
                            "RDL",
                            "RDU",
                            "RDDU",
                            "ROLIGHTS",
                            "ROLIFTGATELOCK",
                            "ROLIFTGATEUNLOCK",
                            "ROTRUNKLOCK",
                            "ROTRUNKUNLOCK",
                            "VF"
                        ]
                    },
                    "params": {
                        "type": "object",
                        "properties": {
                            "duration": {
                                "type": "integer"
                            },
                            "interiorTemperature": {
                                "type": "integer"
                            },
                            "temperatureUOM": {
                                "type": "string",
                                "example": "Celsisus"
                            },
                            "fanSpeed": {
                                "type": "integer"
                            },
                            "targetTemp": {
                                "type": "integer"
                            },
                            "timeoutAfterTemp": {
                                "type": "integer"
                            },
                            "preTripTimeout": {
                                "type": "integer"
                            }
                        }
                    }
                }
            },
            "PrivacyRequestBody": {
                "type": "object",
                "properties": {
                    "active": {
                        "type": "boolean"
                    },
                    "duration": {
                        "type": "string",
                        "example": "TWO_HOURS"
                    }
                }
            },
            "evResponse": {
                "type": "object",
                "properties": {
                    "message": {
                        "type": "string",
                        "example": "success"
                    },
                    "roRequestId": {
                        "type": "string"
                    }
                }
            },
            "PrivacymodeResponsePOST": {
                "type": "object",
                "properties": {
                    "message": {
                        "type": "string",
                        "example": "success"
                    },
                    "roRequestId": {
                        "type": "string"
                    }
                }
            },
            "VehicleLocationResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    },
                    "eventTime": {
                        "type": "string"
                    },
                    "position": {
                        "type": "object",
                        "properties": {
                            "latitude": {
                                "type": "string"
                            },
                            "longitude": {
                                "type": "string"
                            },
                            "altitude": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "ObjectFailureServiceProvisioningResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    },
                    "failureReasonCode": {
                        "type": "string",
                        "example": "VIN_NOT_FOUND"
                    },
                    "failureReason": {
                        "type": "string"
                    }
                }
            },
            "ObjectFailureEligibilityResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    },
                    "failureReasonCode": {
                        "type": "string",
                        "example": "VIN_NOT_FOUND"
                    },
                    "failureReason": {
                        "type": "string"
                    }
                }
            },
            "ObjectSuccessServiceProvisioningResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    }
                }
            },
            "ObjectSuccessEligibilityResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    },
                    "color": {
                        "type": "string"
                    },
                    "year": {
                        "type": "string"
                    },
                    "radio": {
                        "type": "string"
                    },
                    "fuelType": {
                        "type": "string"
                    },
                    "modelDescription": {
                        "type": "string"
                    },
                    "make": {
                        "type": "string"
                    },
                    "sdp": {
                        "type": "string"
                    },
                    "market": {
                        "type": "string"
                    },
                    "soldRegion": {
                        "type": "string"
                    },
                    "navigator": {
                        "type": "string"
                    },
                    "brand": {
                        "type": "string"
                    },
                    "ecuType": {
                        "type": "string"
                    },
                    "company": {
                        "type": "string"
                    },
                    "services": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "example": [
                            "SVLA",
                            "ECOCOACHING20",
                            "TRIPREPORT"
                        ]
                    }
                }
            },
            "CheckEligibilityResponse": {
                "type": "object",
                "properties": {
                    "success": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ObjectSuccessEligibilityResponse"
                        }
                    },
                    "failure": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ObjectFailureEligibilityResponse"
                        }
                    }
                }
            },
            "CheckServiceProvisioning": {
                "type": "object",
                "properties": {
                    "vins": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "example": [
                            "VIN1",
                            "VIN2",
                            "VIN3"
                        ]
                    },
                    "services": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "example": [
                            "SVLA",
                            "ECOCOACHING20",
                            "TRIPREPORT"
                        ]
                    },
                    "start": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "duration": {
                        "type": "integer"
                    },
                    "correlationId": {
                        "type": "string"
                    },
                    "blockEnrrolment": {
                        "type": "string"
                    },
                    "contractId": {
                        "type": "string"
                    },
                    "gigyaUserId": {
                        "type": "string"
                    },
                    "fleetType": {
                        "type": "string"
                    }
                },
                "xml": {
                    "name": "customer"
                }
            },
            "CheckEligibility": {
                "type": "object",
                "properties": {
                    "vins": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        },
                        "example": [
                            "VIN1",
                            "VIN2",
                            "VIN3"
                        ]
                    }
                },
                "xml": {
                    "name": "customer"
                }
            },
            "CheckServiceProvisioningResponse": {
                "type": "object",
                "properties": {
                    "success": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ObjectSuccessServiceProvisioningResponse"
                        }
                    },
                    "failure": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ObjectFailureServiceProvisioningResponse"
                        }
                    }
                }
            },
            "SchedulesTarga": {
                "type": "object",
                "properties": {
                    "scheduleId": {
                        "type": "integer"
                    },
                    "enableScheduleType": {
                        "type": "boolean"
                    },
                    "scheduleType": {
                        "type": "string",
                        "example": "INTERVAL"
                    },
                    "airCondition": {
                        "type": "string",
                        "example": "OFF"
                    },
                    "chargeToFull": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    },
                    "scheduledDays": {
                        "type": "object",
                        "properties": {
                            "monday": {
                                "type": "boolean"
                            },
                            "tuesday": {
                                "type": "boolean"
                            },
                            "wednesday": {
                                "type": "boolean"
                            },
                            "thursday": {
                                "type": "boolean"
                            },
                            "friday": {
                                "type": "boolean"
                            },
                            "saturday": {
                                "type": "boolean"
                            },
                            "sunday": {
                                "type": "boolean"
                            }
                        }
                    },
                    "repeatSchedule": {
                        "type": "boolean"
                    }
                }
            },
            "Schedules": {
                "type": "object",
                "properties": {
                    "enableScheduleType": {
                        "type": "boolean"
                    },
                    "scheduleType": {
                        "type": "string",
                        "example": "CHARGE"
                    },
                    "chargeToFull": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    },
                    "cabinPriority": {
                        "type": "boolean"
                    },
                    "scheduledDays": {
                        "type": "object",
                        "properties": {
                            "monday": {
                                "type": "boolean"
                            },
                            "tuesday": {
                                "type": "boolean"
                            },
                            "wednesday": {
                                "type": "boolean"
                            },
                            "thursday": {
                                "type": "boolean"
                            },
                            "friday": {
                                "type": "boolean"
                            },
                            "saturday": {
                                "type": "boolean"
                            },
                            "sunday": {
                                "type": "boolean"
                            }
                        }
                    },
                    "repeatSchedule": {
                        "type": "boolean"
                    }
                }
            },
            "ChargingScheduleV1": {
                "type": "object",
                "properties": {
                    "enableChargeSchedule": {
                        "type": "string",
                        "example": "DEFAULT"
                    },
                    "scheduleType": {
                        "type": "string",
                        "example": "WEEKDAY"
                    },
                    "chargeUntillFull": {
                        "type": "boolean"
                    },
                    "startTime": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    }
                }
            },
            "Trip": {
                "type": "object",
                "properties": {
                    "averageEnergyUsed": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "energyUsed": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "name": {
                        "type": "string"
                    },
                    "totalDistance": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "totalElectricDistance": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "totalHybridDistance": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "TirePressureObject": {
                "type": "object",
                "properties": {
                    "pressure": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "status": {
                        "type": "string"
                    },
                    "type": {
                        "type": "string"
                    },
                    "warning": {
                        "type": "boolean"
                    }
                }
            },
            "VehicleInfoResponse": {
                "type": "object",
                "properties": {
                    "vin": {
                        "type": "string"
                    },
                    "eventTime": {
                        "type": "string"
                    },
                    "wheelCount": {
                        "type": "integer"
                    },
                    "externalTemperature": {
                        "type": "integer"
                    },
                    "evInfo": {
                        "type": "object",
                        "properties": {
                            "chargingLevel": {
                                "type": "string"
                            },
                            "charginStatus": {
                                "type": "string"
                            },
                            "distanceToEmpty": {
                                "type": "object",
                                "properties": {
                                    "unit": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    },
                    "plugInStatus": {
                        "type": "boolean"
                    },
                    "stateOfCharge": {
                        "type": "integer"
                    },
                    "timeToFullyChargeL1": {
                        "type": "integer"
                    },
                    "timeToFullyChargeL2": {
                        "type": "integer"
                    },
                    "totalRange": {
                        "type": "integer"
                    },
                    "gasRange": {
                        "type": "integer"
                    },
                    "chargingScheduleV1": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/ChargingScheduleV1"
                        }
                    },
                    "schedules": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/Schedules"
                        }
                    },
                    "schedulesTarga": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/SchedulesTarga"
                        }
                    },
                    "vehicleInfo": {
                        "type": "object",
                        "properties": {
                            "batteryInfo": {
                                "type": "object",
                                "properties": {
                                    "batteryStatus": {
                                        "type": "string"
                                    },
                                    "batteryVoltage": {
                                        "type": "object",
                                        "properties": {
                                            "unit": {
                                                "type": "string"
                                            },
                                            "value": {
                                                "type": "string"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "daysToService": {
                        "type": "string"
                    },
                    "distanceToService": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "fuel": {
                        "type": "object",
                        "properties": {
                            "distanceToEmpty": {
                                "type": "object",
                                "properties": {
                                    "unit": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            },
                            "fuelAmount": {
                                "type": "object",
                                "properties": {
                                    "unit": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            },
                            "fuelAmountLevel": {
                                "type": "string"
                            }
                        }
                    },
                    "isTPMSVehicle": {
                        "type": "boolean"
                    },
                    "odometer": {
                        "type": "object",
                        "properties": {
                            "unit": {
                                "type": "string"
                            },
                            "value": {
                                "type": "string"
                            }
                        }
                    },
                    "oilLevel": {
                        "type": "string"
                    },
                    "tripsInfo": {
                        "type": "object",
                        "properties": {
                            "trips": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "$ref": "#/components/schemas/Trip"
                                }
                            }
                        }
                    },
                    "tirePressure": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "$ref": "#/components/schemas/TirePressureObject"
                        }
                    }
                }
            }
        },
        "securitySchemes": {
            "petstore_auth": {
                "type": "oauth2",
                "flows": {
                    "implicit": {
                        "authorizationUrl": "https://petstore3.swagger.io/oauth/authorize",
                        "scopes": {
                            "write:pets": "modify pets in your account",
                            "read:pets": "read your pets"
                        }
                    }
                }
            },
            "api_key": {
                "type": "apiKey",
                "name": "api_key",
                "in": "header"
            }
        }
    }
}