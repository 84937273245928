import { React, useEffect, useState } from "react";
import './ExplorePage.css'
import { Link, useNavigate } from 'react-router-dom';
// import ExploreHeaderImg from "../../images/explore_header.jpg";
// import bannersmallexplorer2 from "../../images/bannersmallexplorer2.png";
// import cloudCapImg from "../../images/bannersmallexplorer1.png";
// import bannersmallexplorer3 from "../../images/bannersmallexplorer3.png";
// import fiatLogImg from "../../images/fiat_logo_explore.png";
// import psaLogoImg from "../../images/PSA_logo_explore.png";
// import SilverArrImg from "../../images/arrow_silver.png";
import { checkUserToken } from "../../utils/CheckUserToken";
import Banner from "../../components/Banner/Banner";

import 'bootstrap/dist/css/bootstrap.min.css';



const Explore = () => {
  const [LoggedInState, setLoggedInState] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (checkUserToken()) {
      setLoggedInState(true);
      if (localStorage.getItem("redirect") !== null) {
        const redirectDestination = localStorage.getItem("redirect");
        localStorage.removeItem("redirect");
        navigate(redirectDestination, { replace: true });
      }
    } else {
      setLoggedInState(false);
      localStorage.removeItem("redirect");
    }
  }, []);

  return (
    <>
      <div className="explore">
        <div>
          <Banner
            image={'/images/explore_header.jpg'}
            heading={"Explore"} />
        </div>
        <div style={{"paddingRight":"8%", paddingLeft:"8%"}} >
          <div className="pb-5">
            <h2>Legacy portals</h2 >
            <div className="container-line"></div>
            <div className="row pt-4">
              <div className="col">
                <Link to="FCA_Vehicle_API">
                  <div className="current-portal-img-container">
                    <img className="current-portal-img-config" src={'/images/fiat_logo_explore.png'} alt="fiatLogImg" />
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link to="https://developer.groupe-psa.io/webapi/" target="_blank">
                  <div className="current-portal-img-container">
                    <img className="current-portal-img-config" src={'/images/PSA_logo_explore.png'} alt="psaLogoImg" />
                  </div>
                </Link>
              </div>

            </div>
          </div>

          <div className="pt-4 pb-5">
            <h2>Stellantis Cloud Capabilities</h2 >
            <div className="container-line"></div>
            <div className="img-container">
              <img src={'/images/bannersmallexplorer1.png'} alt="Stellantis Cloud Capabilities" />
              <div className="overlay-content left">
                <p>Discover stellantis API capabilities</p>
              </div>
              <div className="overlay-content right">
                <Link to="/capabilities" style={{ textDecoration: 'none' }}>
                  <img style={{ height: "100%", width: "100%" }} src={'/images/arrow_silver.png'} />
                </Link>
              </div>
            </div>
          </div>

          <div className="pt-4 pb-5">
            <h2>Stella Brain on-board capabilities</h2 >
            <div className="container-line"></div>
            <div className="img-container">
              <img src={'/images/bannersmallexplorer2.png'} alt="Discover onboard capabilities" />
              <div className="overlay-content left">
                <p>Discover the brain of Stellantis vehicles</p>
              </div>
              <div className="overlay-content right">
                <Link to="/Explore/OnboardCatalogue" style={{ textDecoration: 'none' }}>
                  <img style={{ height: "100%", width: "100%" }} src={'/images/arrow_silver.png'} />
                </Link>
              </div>
            </div>
          </div>

          <div className="pt-4 pb-5">
            <h2>Data sharing for Mobilisights</h2 >
            <div className="container-line"></div>
            <div className="img-container">
              <img src={'/images/bannersmallexplorer3.png'} alt="Data sharing for Mobilisights" />
              <div className="overlay-content left">
                <p>Data sharing for Mobilisights</p>
              </div>
              <div className="overlay-content right">
                <Link to="/Explore/Mobilisight" style={{ textDecoration: 'none' }}>
                  <img style={{ height: "100%", width: "100%" }} src={'/images/arrow_silver.png'} />
                </Link>
              </div>
            </div>
          </div>
          <br />
          <br />

        </div>
      </div>

    </>
  );
}
export default Explore;
