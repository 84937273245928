import React, { useEffect } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from '@mui/material/Box';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTheme } from "@mui/material/styles";
import SwaggerTemplate from '../swaggerTemplate/SwaggerTemplate';
import './styles.css'


const SwaggerWithTabs = (props) => {
    const swagger = props.swagger;
    const SwaggerSelectedTag = props.SwaggerSelectedTag

    const theme = useTheme();
    const docs = props.docs
    const env_info = props.env_info
    const callbackAPIs = props.callbackAPIs;


    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderDocumentationSection = () => {
        return Object.entries(props.documentation_files).map(([key, value]) => (
            <div className="p-2">
                <div class="row">
                    <div class="col-6">
                        <p>
                            <u>{key}</u>
                        </p>
                    </div>

                    <div class="col-6 text-right">
                        <a href={value}>
                            <i className="fa fa-download"></i>
                        </a>
                    </div>
                </div>
            </div>

        ));
    };


    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='p-0'>
            <Box sx={{ width: "100%", typography: "body" }}>
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                        }}
                    >
                        <TabList
                            className="mobilisight-tabs"
                            allowScrollButtonsMobile
                            variant={isSmallScreen ? "scrollable" : "fullWidth"}
                            textColor="#A2202C"
                            indicatorColor="#A2202C"
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    background: "#A2202C",
                                    fontSize: "27px",
                                    color: "#A2202C",
                                    height: "10px",
                                    bottom: 0,
                                },
                            }}
                            aria-label="lab API tabs example"
                        >
                            <Tab
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 25,
                                }}
                                label="Exposed API's"
                                value="1" />
                            <Tab
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 25,
                                }}
                                label="Callback Payloads"
                                value="2"
                                disabled={SwaggerSelectedTag.name == "Service Activation"} />
                            <Tab
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 25,
                                }}
                                disabled={env_info ? false : true}
                                label="Environment"
                                value="3" />
                            <Tab
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 25,
                                }}
                                disabled={docs ? false : true}
                                label="Documentation"
                                value="4" />
                        </TabList>
                    </Box>
                    <TabPanel
                        value="1"
                        sx={{
                            paddingTop: 8,
                        }}
                    >
                        <SwaggerTemplate SwaggerSelectedTag={SwaggerSelectedTag} swagger={swagger} heading={"Stellantis exposed APIs"} />
                    </TabPanel>
                    <TabPanel
                        value="2"
                        sx={{
                            paddingTop: 8,
                        }}
                    >
                        <div className='callback_api'>
                            <div dangerouslySetInnerHTML={{ __html: callbackAPIs ? callbackAPIs : `<p>No data available</p>` }} />
                        </div>
                    </TabPanel>
                    <TabPanel
                        value="3"
                        sx={{
                            paddingTop: 8,
                        }}
                    >
                        <div className='documentation'>
                            <div dangerouslySetInnerHTML={{ __html: env_info ? env_info : false }} />
                            {/* {
                                props?.environment_files ?
                                    (<div class="row pt-5">
                                        <h1 className="pb-3">More Documentation</h1>
                                        <hr size="3" />
                                        <br />
                                        <p>
                                            Here you can download more file related to this
                                            functionality
                                        </p>
                                        {renderEnvironmentSection()}
                                    </div>)
                                    : ""
                            } */}
                        </div>
                    </TabPanel>
                    <TabPanel
                        value="4"
                        sx={{
                            paddingTop: 8,
                        }}
                    >
                        <div className='documentation'>
                            <div dangerouslySetInnerHTML={{ __html: docs ? docs : false }} />
                            {
                                props?.documentation_files ?
                                    (<div class="row pt-5">
                                        <h1 className="pb-3">More Documentation</h1>
                                        <hr size="3" />
                                        <br />
                                        <p>
                                            Here you can download more file related to this
                                            functionality
                                        </p>
                                        {renderDocumentationSection()}
                                    </div>)
                                    : ""
                            }
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>

        </div>
    )
}

export default SwaggerWithTabs
