import React from "react";

import "./HomePage.css";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import VehicleBackground from "../../images/vehicle_background.png";
// import NameLogo from "../../images/NameLogo.png";
// import Rectangle from "../../images/NameRectangle.png";
// import Developer from "../../images/DEVELOPERPORTAL.png";
// import Idea from "../../images/Idea.png";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <>
    <div className="main">
    <div style={{ boxShadow: "0px 4px 4px" }}>
    <img
            src={'/images/vehicle_background.png'}
            alt="Vehicle Brands"
            style={{ width: "100%"}}
          />
          <img
            src={'/images/NameRectangle.png'}
            alt="Name"
            className="rectangle"
          />
          <img
            src={'/images/NameLogo.png'}
            alt="Name"
            className="nameLogo"
          />
          <img
            src={'/images/Developer_Portal.png'}
            alt="Name"
            className="devPortal"
          />
        </div>
        <div className="secondDiv">
          <div className="secondInternalDiv">
            <img
              src={'/images/Idea.png'}
              alt="Logo"
              className="idea"
            />
            <div
              className="wordingsDiv"
            >
              <p
                className="wordingsDiv1"
              >
                Exploit our capabilities!
              </p>
              <p
                className="wordingsDiv2"
              >
                Stellantis Developer Portal has been <br /> created to support
                developers, start­ups <br /> and enterprises for creating
                innovative <br /> applications with official vehicle-related{" "}
                <br /> APIs.{" "}
              </p>
              <button
                type="submit"
                className="exploreButton"
                onClick={() => {navigate("/explore")}}
              >
                Explore
              </button>
            </div>
          </div>
        </div>
        <>
          <p
            className="midTextSection"
          >
            Collecting Data to drive the future
          </p>
        </>
        <Container className="containerClass" >
          <Row className="flex-row-container">
            <Col
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              className="flex-box"
            >
              <div className="flex-content">
                <p
                  className="flex-heading"
                >
                  TECHNOLOGY
                </p>
                <p
                  className="flex-data"
                >
                  Stellantis uses diverse
                  technologies to create attractive,
                  affordable vehicles that reduce
                  CO2 emissions, aiming for
                  sustainable and innovative
                  transportation solutions.
                </p>
              </div>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              className="flex-box"
            >
              <div className="felx-content">
                <p
                  className="flex-heading"
                >
                  SOFTWARE
                </p>
                <p
                 className="flex-data"
                >
                  Stellantis leverages software
                  to personalize mobility,
                  offering updates and investing
                  €30 billion in electrification
                  and software by 2025 to
                  lead in sustainable mobility.
                </p>
              </div>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              className="flex-box"
            >
              <div className="felx-content">
                <p
                  className="flex-heading"
                >
                  DATA
                </p>
                <p
                  className="flex-data"
                >
                  Vehicle data enhances daily
                  life through big data
                  analysis, with collaboration
                  and innovation reshaping
                  the automotive landscape to
                  simplify and enrich lives.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default HomePage;
