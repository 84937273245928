export const ON_DEMAND_SERVICE = `
<h1><strong>On-Demand Services</strong></h1>
<p><strong><img src="/assets/mobilisight/ondemandservices.png" alt="" /></strong></p>
<p>The above represented procedure needs to be executed according to the following workflow, independently from the VIN identity (Brand/Model/Series), mounted telematic box (TBM, MC), SalesCluster (B2B/B2C) or Region (NAFTA, EMEA):</p>
<ol>
<li><strong>Provided the Service of interest has already been activated according to the Service Activation procedure described in the dedicated chapter,</strong> The Third Party inputs a Remote Command. They shall receive a synchronous Response containing the system&rsquo;s acknowledgement, meaning the request has been taken in charge and will be processed</li>
<li>FCL reroutes the Request towards the correct Provider (Ignite for TBM VINs, Targa for EMEA MC VINs)</li>
<li>The Provider executes the command on the VIN</li>
<li>The VIN sends the outcome of the command execution towards the Supplier system</li>
<li>The Provider sends a Callback event towards FCL, stating the results of the requested operation</li>
<li>FCL pushes the Callback Event towards the Kinesis On-Demand Stream OR a dedicated API exposed by the partner, for the Third Party to be acknowledged</li>
</ol>
<p>&nbsp;</p>
<h2><strong>PHEV Data and Commands</strong></h2>
<p><u>PHEV Data packages are always sent within the Callback Stream. Their payload shall contain the Response Payload described for <strong>PHEV Info (Deep Refresh)</strong> API.</u></p>
<p>Those can be generated by an on-board trigger, o a manual request performed with the below listed APIs.</p>
<p>The possible triggers for the automated package sendings are the following:</p>
<ul>
<li><strong>IGNITION_ON / IGNITION_OFF </strong></li>
<li><strong>PLUGGED_IN / UNPLUGGED </strong></li>
<li><strong>CHARGE_START / CHARGE_COMPLETED / CHARGE_INTERRUPTED / CHARGE_FAULTED</strong></li>
<li><strong>ON_DEMAND</strong></li>
<li><strong>CHARGE_SCHEDULE</strong></li>
<li><strong>POWER_PREFERENCE</strong></li>
</ul>
<p>On top of the DataPackages, several electric motor-related commands can be inputted to interact with the Charge procedures, by scheduling it or starting/stopping it immediately. Those commands&rsquo; flow follows the same logic as any common Remote Operation (see Chapter 10.2).</p>
`