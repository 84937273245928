import React, { useState, useEffect } from "react";
import BackGroundVideoFrame from "../../components/BackgroundVideo/BackGroundVideoFrame";
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { userLogin } from "../../Actions/LoginAction";
import { checkUserToken } from "../../utils/CheckUserToken";
import './Login.css';
// import SLogo from "../../images/stellantis_image.png";
// import SocialLogo from "../../images/social_logo.png";
import 'bootstrap/dist/css/bootstrap.min.css'
import { UserData } from "../../Actions/UsersData";
import Swal from "sweetalert2";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const LoginPage = () => {
  
  const { userInfo, userToken, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const redirectPage = searchParams.get('page');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (window.location.search.toLowerCase() !== "") {
      localStorage.setItem("redirect", window.location.search.split("=")[1])
    }

    if (checkUserToken()) {
      navigate("/Explore", { replace: true });
    }
  }, [userInfo, userToken]);
  useEffect(() => {
    if (
      error ===
      "You are attempting to retrieve a bearer token with a temporary password. Please use /setpassword to set a permanent password first "
    ) {
      navigate("/ChangeTempPassword", { replace: true });
    }
  }, [error]);
  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error",
        text: error,
        backdrop: "rgba(0, 0, 0, 0.85)",
        icon: "error",
        customClass: {
          title: 'sweetalert-color',
          htmlContainer: 'sweetalert-color',
          content: 'sweetalert-color'
        },
      });
    }
  }, [error]);

  const [Loading, setLoading] = useState(false);

  const [Message, setMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const LoginCallback = async (data) => {
    setLoading(true);
    await dispatch(userLogin(data));
    setLoading(false);
  };

  const loginData = useSelector((state) => state.auth);
  const getUserDetails = async () => {
    await dispatch(UserData(loginData.userInfo.access_token));
  };
  if (Object.keys(loginData.userInfo).length > 0) {
    getUserDetails();
  }

  return (
    <>
      <div className="login-page">
        <form onSubmit={handleSubmit(LoginCallback)} className="p-2">
          <Container className="login-container p-5">
            <img src={'/images/stellantis_image.png'} alt="SLogo" className="img-fluid" />
            <h4 className="p-3">Sign in with your Stellantis account</h4>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="userEmail"
                {...register("Email", {
                  required: "Email is required",
                })}
                required
                placeholder="Email"
              />
            </div>
            <div className="form-group password-container">
              <input
                type={showPassword ? "text" : "password"}
                {...register("Password", {
                  required: "Password is required",
                })}
                className="form-control"
                required
                id="userPassword"
                placeholder="Password"
              />
             {password && (
              <span
                className="password-toggle-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon style={{ color: "#959595", fontSize: "1.3rem" }} />
                ) : (
                  <VisibilityOffOutlinedIcon style={{ color: "#959595", fontSize: "1.3rem" }} />
                )}
              </span>
            )}
            </div>
            <div className="form-check p-3">
              <input type="checkbox" {...register("keepSignIn")} className="form-check-input" />
              <label className="form-check-label" >Keep me signed</label>
            </div>
            <div className="center-align">
              <button type="submit" className="signin-button" variant="light">
                {Loading ? (
                  <div
                    class="spinner-border"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Sign in"
                )}
              </button>
            </div>
            <div>
              <Link to="/forgotPassword" id="login-icon" style={{ color: "inherit" }}>
                <h4>Forgot the password?</h4>
              </Link>
            </div>
            <div>
              <h4>
                <Link to="/Register" id="login-icon" style={{ color: "inherit" }}>Don't have an SDK account?
                  Request one here</Link>
              </h4>
            </div>
            <h4 className="p-3">Stellantis Cloud SDK is currently in a closed beta. All registrations will be reviewed by Stellantis. Information on public availability is coming soon!</h4>

          </Container>
        </form>
      </div >
    </>
  );
};

export default LoginPage;
