import React from 'react';
import "./footer_style.css"
import { Link } from 'react-router-dom';
const Footer = () => {



  return (
    <>

      <footer
        className="col-md-12 col-12 footer"
        style={{ background: "black", color: "white", marginBottom: "0px", position: "absolute", height: "175px" }}>
        <div >
          <div className="row justify-content-center" style={{ marginRight: 0 }}>
            <div className="col-sm-4 col-md-6 col-6 item " style={{
              "display": "flex", "flex-direction": "column", "align-content": "flex-start", "flex-wrap": "wrap", "justify-content": "center", "align-items": "center", paddingLeft:"8.5rem"
            }}>

              <h3>

                <img src="/images/logo.png" />
              </h3>
              <h4>All rights reserved to Stellantis SpA</h4>

            </div>
            <div className="col-sm-4 col-md-3 col-3 item pt-4" >
              <h3>Developer Portal</h3>
              <ul className="list-unstyled">
                <li className='pb-1'><Link to="/Login">Login</Link></li>
                <li className='pb-1'><Link to="/Explore">Explore</Link></li>
                <li className='pb-1'><Link to="/faq">FAQ</Link></li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-3 col-3 item pt-4">
              <h3>Contacts</h3>
              <ul className="list-unstyled">
                <li className='pb-1'><Link to="/contact-us">Contact us</Link></li>
                <li className='pb-1'><a href="https://www.stellantis.com/en/privacy" target="_blank">Privacy Policy</a></li>
                <li className='pb-1'><a href="https://www.stellantis.com/en/legal-notes" target="_blank">Legal Notes</a></li>
              </ul>
            </div>
          </div>
        </div>


      </footer>

    </>
  );
}



export default Footer;
