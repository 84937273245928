export let jsondata = {
    "TOTALODOMETER": "NA",
    "PRESSUREVALUE_RHF_TYRE": "NA",
    "PRESSUREVALUE_LHF_TYRE": "NA",
    "PRESSUREVALUE_RHR_TYRE": "NA",
    "PRESSUREVALUE_LHR_TYRE": "NA",
    "AVGFUELLVL": "NA",
    "ABSACTIVE": "NA",
    "VEHICLESPEED": "NA",
    "HVBATSOC_HCP": "NA",
    "POWERMODESTS": "NA",
    "Latitude": "NA",
    "Longitude": "NA",
    "Altitude": "NA",
    "Course": "NA"
}