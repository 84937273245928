import React, { lazy, Suspense } from 'react';
import "./styles.css";
import { swagger } from "./swagger";
import { SERVICE_ACTIVATION } from './assets/Service_activation';
import { REAL_TIME_DATA_FEED } from './assets/Real_time_data_feed';
import { ON_DEMAND_SERVICE } from './assets/On_demand_service';
import { REMOTE_COMMANDS } from './assets/Remote_commands';
import { ENVIRONMENT } from './assets/Environment';
import { REAL_TIME_DATA_FEED_CALLBACK_API } from './assets/Real_time_data_feed_callback_api';
import { REMOTE_COMMANDS_CALLBACK_API } from './assets/Remote_commands_callback_api';
import { ON_DEMAND_SERVICE_CALLBACK_API } from './assets/On_demand_service_callback_api';
import Banner from '../../components/Banner/Banner';
import { Backdrop, CircularProgress } from '@mui/material';

const SwaggerPageGenerator = lazy(() => import('../../components/SwaggerPageGenerator/SwaggerPageGenerator'));

const Mobilisight_Page = () => {

  const docs = {
    "Service Activation": SERVICE_ACTIVATION,
    "Real Time Data Feed": REAL_TIME_DATA_FEED,
    "On-Demand Services": ON_DEMAND_SERVICE,
    "Remote Commands": REMOTE_COMMANDS,
    "environment": ENVIRONMENT,
    "documentation_files": { "Documentation Architecture": '/assets/mobilisight/mobilisightdocumentation.docx' }
  };

  const callbackAPIs = {
    "Real Time Data Feed": REAL_TIME_DATA_FEED_CALLBACK_API,
    "Remote Commands": REMOTE_COMMANDS_CALLBACK_API,
    "On-Demand Services": ON_DEMAND_SERVICE_CALLBACK_API
  }
  return (
    <>
      <div>
      <Banner image={"/images/Mobilisights-button-data-stream.png"} heading={"Stellantis for Mobilisights"} />

        
      </div>
      <br />
      <div className='page_box'>
        <div className='box_container'>
        <Suspense fallback={
              <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}>
            <SwaggerPageGenerator swagger={swagger} defaultView={false} docs={docs} callbackAPIs={callbackAPIs} />
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default Mobilisight_Page;
