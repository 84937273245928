const DownloadJsonObject = (json_object, symbol) => {
  
    const jsonBlob = new Blob([JSON.stringify(json_object)], {
      type: "application/json",
    });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(jsonBlob);
    downloadLink.download = `${symbol}_data.json`;

    downloadLink.click();

    window.URL.revokeObjectURL(downloadLink.href);
};

export default DownloadJsonObject