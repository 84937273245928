import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./coustom.css";
import SmallCircleButton from "../../components/SmallCircleButton/SmallCircle";
import { API_URL } from "../../constant/APIConstants";

const GetAccountDetails = () => {

  const navigate = useNavigate();
  const data = useSelector(
    (state) => state.UserData
  );
  
  useEffect(() => {
    if (localStorage.getItem("redirect") !== null) {
      const redirectDestination = localStorage.getItem("redirect");
      localStorage.removeItem("redirect");
      navigate(redirectDestination, { replace: true });
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="p-2 mt-2">
          <h2 className="text-primary">Accounts</h2>
          {data?.loading ? (
            <>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </>
          ) : (
            <>
              <div className="row pt-3">
                <div className="col-3">
                  <h6>API Account Owner:</h6>
                </div>
                <div className="col">
                  <p>{data?.UserData && data.UserData.api_account_owner}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <h6>First Name:</h6>
                </div>
                <div className="col">
                  <p>{data?.UserData && data.UserData.first_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <h6>Last Name:</h6>
                </div>
                <div className="col">
                  <p>{data?.UserData && data.UserData.last_name}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <h6>Phone Number:</h6>
                </div>
                <div className="col">
                  <p>{data?.UserData && data.UserData.phone_number}</p>
                </div>
              </div>
              <div className="pt-5">
                <table className="table pt-5">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Connected Account</th>
                      <th scope="col">VIN</th>
                      <th scope="col">Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.UserData.usernameVinArray &&
                      data.UserData.usernameVinArray.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>{rowIndex + 1}</td>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                          <td>
                            <Link
                              to="/remote-commands/send-remote-commands"
                              className="small-circle-button"
                            >
                              <i className="fas fa-arrow-up-from-bracket"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GetAccountDetails;
