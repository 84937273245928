export const FAQ = {
    "General Information": {
        "details": [
            {
                "question": "<span>What is the Stellantis Developer Portal?</span>",
                "answer": "<p>The Stellantis Developer Portal is a platform that provides comprehensive documentation, manuals, and APIs for our SDP cloud capabilities, stella brain onboard vehicles capabilities and Mobilisights integrations.</p>"
            },
            {
                "question": "<span>Who can access the different sections of the portal?</span>",
                "answer": "<p>Our cloud capabilities are publicly accessible. Stella Brain section and Mobilisights sections are restricted and only accessible to employees of Stellantis/Mobilisghts after logging in.</p>"
            },
            {
                "question": "<span>How can I contact support?</span>",
                "answer": "<p>You can reach us by using the contact form available on the \"Contact Us\" page of our website. You can reach this page on every page of the website by clicking in the specific link present in the footer.</p>"
            }
        ]
    },
    "Access and Accounts": {
        "details": [
            {
                "question": "<span>How do I create an account?</span>",
                "answer": "<p>Only employees of Stellantis/Mobilisights can create accounts for accessing Stella Brain and Mobilisights sections. If you are an employee, please follow the procedures to get the permission to access in by clicking on the link “<a href='/Register'>Don't have an SDK account? Request one here</a>” in the Login page.</p>"
            },
            {
                "question": "<span>I am an employee, but I can't log in. What should I do?</span>",
                "answer": "<p>Ensure you are using your company credentials. If the problem persists, please contact the support team using the contact form on the \"Contact Us\" page.</p>"
            },
            {
                "question": "<span>Can external users access the onboarding and third-party sections?</span>",
                "answer": "<p>No, access to the Stella Brain and Mobilisights sections is restricted to Stellantis/Mobilisights employees only.</p>"
            }
        ]
    },
    "Stellantis Cloud Capabilities": { 
        "details": [
            {
                "question": "<span>What kind of Stellantis cloud capabilities are available on the portal?</span>",
                "answer": "<p>Our Stellantis cloud capabilities section includes public APIs, detailed documentation, and manuals for various cloud services we offer. This section is accessible to everyone.</p>"
            },
            {
                "question": "<span>Where can I find the API documentation for cloud services?</span>",
                "answer": "<p>The API documentation for our cloud services is available in the cloud capabilities section of the portal. Reach the section from the “Explore” page.</p>"
            }
        ]
    },
    "Stella Brain Onboard": {
        "details": [
            {
                "question": "<span>What resources are available in the Stella Brain section?</span>",
                "answer": "<p>The Stella Brain section includes detailed manuals, API documentation, and guides to help new employees and developers get started with our systems and processes.</p>"
            },
            {
                "question": "<span>How do I access the Stella Brain documentation?</span>",
                "answer": "<p>You need to log in using your employee credentials to access the Stella Brain documentation.</p>"
            },
            {
                "question": "<span>I am having trouble understanding the Stella Brain process. Where can I get help?</span>",
                "answer": "<p>If you need assistance, please use the contact form on the \"Contact Us\" page to reach out to our support team.</p>"
            }
        ]
    },
    "Stellantis for Mobilisights Integrations": {
        "details": [
            {
                "question": "<span>What kind of Stellantis for Mobilisights does the portal support?</span>",
                "answer": "<p>Our portal supports only the exF APIs exposed to Mobilisights, so you can find the relevant APIs and documentation in the Mobilisights section.</p>"
            },
            {
                "question": "<span>How do I access the Stellantis for Mobilisights APIs?</span>",
                "answer": "<p>You need to be logged in as an employee of Mobilisights to access the Stellantis for Mobilisights APIs.</p>"
            },
        ]
    },
    "Technical Support": {
        "details": [
            {
                "question": "<span>The portal isn’t loading properly. What should I do?</span>",
                "answer": "<p>Try clearing your browser’s cache and cookies, or try accessing the site from a different browser or device. If the issue continues, please contact our support team using the contact form on the \"Contact Us\" page.</p>"
            }
        ]
    }
}