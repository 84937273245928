import React, { useState } from 'react'
import { useForm } from "react-hook-form";

import {RequestApiCallback} from './RequestApiCallback';

const LinkVehicleAccount = () => {

  const { register, handleSubmit } = useForm();

  
  

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6  mt-4">
            <h2 style={{ color: "rgb(50, 50, 159);" }}>
              Connect Vehicle Account
            </h2>
            <h5>Link an API account to your Connected Vehicle account</h5>
            <form onSubmit={handleSubmit(RequestApiCallback)}>
              <div class="form-group">
                <label for="connectedAccountEmail">
                  Connected Account Email <span class="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  class="form-control"
                  id="connectedAccountEmail"
                  {...register("Email")}
                  placeholder="Enter User Name"
                />
              </div>
              <div class="form-group">
                <label for="userPassword">
                  Connected Account Password<span class="text-danger">*</span>
                </label>
                <input
                  required
                  type="password"
                  class="form-control"
                  id="Password"
                  {...register("Password")}
                  placeholder="Enter Password"
                />
              </div>
              <div class="form-group text-center">
                <button type="submit" class="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  );
}

export default LinkVehicleAccount