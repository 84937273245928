import React from 'react'
import { useForm } from 'react-hook-form';
import { RequestApiCredentialsApi } from './RequestApiCedentialsApi';

const RequestApiCredentials = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm();

  return (
    <>
      <div class="col-m-9 p-2">
        <div id="request" class="body-content">
          <h2 style={{ color: "rgb(50, 50, 159)" }}>Account Registration</h2>
          <h5>Request API Account Credentials for Stellantis Cloud API</h5>
          <form onSubmit={handleSubmit(RequestApiCredentialsApi)}>
            <div class="row m-0">
              <div class="form-group col-6">
                <label for="apiAccountOwner">
                  API Account Owner Email <span class="required-star">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="form-control small-input"
                  id="apiAccountOwner"
                  placeholder="Enter API Account Owner"
                  {...register("apiAccountOwner", {
                    required: "Email/Account is required",
                  })}
                />
                {errors?.apiAccountOwner && (
                  <p>{errors.apiAccountOwner.message}</p>
                )}
              </div>
              <div class="form-group col-6">
                <label for="password">
                  Stellantis Password <span class="required-star">*</span>
                </label>
                <input
                  required
                  type="password"
                  className="form-control small-input"
                  id="password"
                  placeholder="Enter a Password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                />
                {errors?.password && <p>{errors.password.message}</p>}
              </div>
            </div>
            <div className="row m-0">
              <div
                class="form-group col-6 "
                style={{ "margin-bottom": "1rem" }}
              >
                <label for="firstName">
                  First Name <span class="required-star">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="form-control small-input"
                  id="firstName"
                  placeholder="Enter First Name"
                  {...register("firstName", {
                    required: "First Name is required",
                  })}
                />
                {errors?.firstName && <p>{errors.firstName.message}</p>}
              </div>
              <div
                className="form-group col-6"
                style={{ "margin-bottom": "1rem" }}
              >
                <label for="lastName">
                  Last Name <span class="required-star">*</span>
                </label>
                <input
                  type="text"
                  required
                  class="form-control small-input"
                  id="lastName"
                  placeholder="Enter Last Name"
                  {...register("lastName", {
                    required: "Last Name is required",
                  })}
                />
                {errors?.lastName && <p>{errors.lastName.message}</p>}
              </div>
            </div>
            <div class="row m-0">
              <div class="form-group col-6" style={{ "margin-bottom": "1rem" }}>
                <label for="phoneNumber">
                  Phone Number <span class="required-star">*</span>
                </label>
                <input
                  required
                  class="form-control small-input"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  {...register("phoneNumber", {
                    required: "Phone number is required",
                  })}
                />
                {errors?.phoneNumber && <p>{errors.phoneNumber.message}</p>}
              </div>
              <div
                className="form-group col-6"
                style={{ "margin-bottom": "1rem" }}
              >
                <label for="address">
                  Address <span class="required-star">*</span>
                </label>
                <textarea
                  type="text"
                  required
                  className="form-control small-input"
                  id="address"
                  placeholder="Enter Address"
                  {...register("address", {
                    required: "address is required",
                  })}
                ></textarea>
                {errors?.address && <p>{errors.address.message}</p>}
              </div>
            </div>

            <div class="row m-0">
              <div
                class="form-group col"
                style={{ "margin-bottom": "1rem" }}
              >
                <label for="usageDescription">
                  Usage Description <span class="required-star">*</span>
                </label>
                <textarea
                  type="text"
                  required
                  className="form-control small-input"
                  id="usageDescription"
                  placeholder="Enter Usage Description"
                  {...register("usageDescription", {
                    required: "usageDescription is required",
                  })}
                ></textarea>
                {errors?.usageDescription && (
                  <p>{errors.usageDescription.message}</p>
                )}
              </div>
            </div>
            <button
              id="loading-button"
              class="btn btn-primary mt-2"
              type="button"
              disabled
              style={{ display: "none", "margin-left": " 0.5rem" }}
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
            <button
              id="submit-button"
              type="submit"
              class="btn btn-primary mt-2"
              style={{ "margin-left": "0.5rem", "margin-bottom": "1rem" }}
            >
              Submit
            </button>
          </form>
          <div class="col-md-8 p-0">
            <h5 id="apiResult"></h5>
            <h5 style={{ color: "red" }} id="apiResultError"></h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestApiCredentials