import React from 'react'
// import Redoc from "redoc";
import { RedocStandalone } from "redoc";
import { swaggerspec } from './Stellantis-Space-Cloud-SDK-swagger';
import { autoBatchEnhancer } from '@reduxjs/toolkit';

const DocsSwagger = () => {
  return (
    <>
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70px",
          background: "#243882",
        }}
      >
        <img
          src="./StellantisDevelopers.png"
          alt="Description of the image"
          style={{ width: "250px" }}
        />
      </div>

      <div>
        <RedocStandalone
          spec={swaggerspec}
          options={{
            nativeScrollbars: false,
            theme: {
              colors: { primary: { main: "blue" } },
              typography: {
                fontSize: "14px",
                code: {
                  fontSize: "13px",
                },
              },
            },
            lazyRendering: true,
            // scrollYOffset: 150,
          }}
        />
        {/* <Redoc
          spec={swaggerspec}
          specURL="https://developers.stellantis.com/Stellantis-Space-Cloud-SDK-swagger.json"
        /> */}
      </div>
    </>
  );

}

export default DocsSwagger;